import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { PropagateLoader } from 'react-spinners'
import AudioPlayer from 'react-h5-audio-player';

const env = require('../../config.env')

const CustomAudioPlayer = ({ url, player_index, handleAudioEnded }) => {
    const [AudioStream, getAudioStream] = useState()
    useEffect(() => {
        if (url) {
            let headers = { audio_url: url }

            axios.get(`${env.SERVER_REQUEST_PATH}getAudio`, {
                responseType: 'blob',
                headers: headers
            })
                .then(response => {
                    var objectURL = URL.createObjectURL(response.data)
                    getAudioStream(objectURL);
                }).catch(err => {
                    // console.log(err)
                })
        }
    },
        [url])
    return (
        <div className='custom-audio-player'>
            {AudioStream &&
                <AudioPlayer
                    src={AudioStream}
                    autoPlay={player_index == 0 ? true : false}
                    onEnded={() => { handleAudioEnded(player_index) }}
                    controls
                />}
            {!AudioStream &&
                <div className='d-flex justify-content-center'>
                    <PropagateLoader color="#B9AFF7" width={250} /></div>
            }
        </div>
    )
}

export default CustomAudioPlayer
