import React from 'react';
import { NavLink } from 'react-router-dom'
import { SlEarphones } from "react-icons/sl";
import { IoMdPlay } from "react-icons/io";
import classNames from 'classnames';
import '../../../css/AudioPlaylist.css'

const helpers = require('../../../../utilities/helpers')
const AudioPlaylistRow = ({ playlistDetails }) => {
    let statusType = "";
    let status = "";
    if (playlistDetails.statusid == 1) {
        statusType = "success";
        status = "Published"
    }
    else if (playlistDetails.statusid == 2) {
        statusType = "secondary";
        status = "Unpublished"

    }
    else if (playlistDetails.statusid == 3) {
        statusType = "danger";
        status = "Draft"
    }
    if (playlistDetails)
        return (
            <div className="row m-0 p-0 item-container">
                <div className="my-2 col-12 col-md-2 pb-3">
                    <div className='p-2 playlist-item'>
                        <div className='d-flex justify-content-center align-items-center mt-4'>
                            <span className='playlist-count'>{playlistDetails.audios_count}
                                <SlEarphones className='ms-2' color='#5644de' size={15} />
                            </span></div>
                        <div className='w-100 d-flex justify-content-end align-items-end playlist-button'>
                            <div className='play-border p-1 border border-1 rounded-circle'>
                                <NavLink to={`/playlist/${playlistDetails.id}`}
                                    state={{ playlist: playlistDetails, is_private: false }}>
                                    <IoMdPlay color='#5644de' size={25} />

                                </NavLink></div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-8 pb-3 title-style">
                    <p className='mt-4'>
                        <NavLink to={`/playlist/${playlistDetails.id}`}
                            state={{ playlist: playlistDetails, is_private: false }}>
                            {playlistDetails.title}</NavLink>
                    </p>
                </div>

                <div className="col-12 col-md-1 pb-3 title-style d-flex justify-content-center align-items-start">
                    <span className={classNames(
                        'bg-size badge',
                        ` bg-${statusType}`
                    )}>{status}</span></div>
            </div>
        )
    else
        return (null)
}

export default AudioPlaylistRow
