import React from 'react'

const TermsConditionsTxt = () => {
    return (
        <div>
            <h5 className='text-center'>Terms and Conditions</h5>
            <span>Welcome to ResearchTube.AI! By signing up and using our website, you agree to abide by the following terms and conditions. Please read them carefully before registering. Last Updated: October 13, 2024 </span>
            <p>
                <ol>
                    <li> Acceptance of Terms. By accessing and using ResearchTube Technologies Inc. (ResearchTube.AI), you agree to be bound by these terms and conditions (referred to as "Terms"). If you do not agree, please refrain from using the site. These Terms may be updated from time to time, and continued use of the site implies acceptance of the changes.</li>
                    <li> Eligibility.You must be at least 18 years of age to register for ResearchTube.AI. By creating an account, you confirm that you meet this age requirement.</li>
                    <li> Account Creation and Security. You are responsible for providing accurate and up-to-date information during the signup process. You are solely responsible for maintaining the confidentiality of your account login details. Any activity on your account is your responsibility. Notify us immediately of any unauthorized use or security breach of your account.</li>
                    <li> Use of the Platform. ResearchTube.AI is designed to provide educational and research-related resources to its users. By using the site, you agree to the following: You will use the website in accordance with all applicable laws and regulations. You will not use ResearchTube.AI for any illegal, harmful, or abusive purposes. You will not attempt to disrupt or compromise the integrity or security of the platform. You acknowledge that the platform contains confidential information and trade secrets of ResearchTube.Ai. You agree not to sell, copy, distribute, sublicense or otherwise make the Software or any portion thereof available to any third party. You may not modify, reverse-engineer, adapt, translate, decompile, or derive the source code of the Software or authorize any third party to do so.</li>
                    <li> Content Ownership and Intellectual Property. ResearchTube.AI Content: All content on ResearchTube.AI, including text, graphics, visuals, audios, logos, and data, is the property of ResearchTube.AI and protected under intellectual property laws. Some visuals and audio materials may be outsourced from third-party providers and are used under appropriate licenses. You may not reproduce, distribute, or create derivative works from this content without express permission. User-Generated Content: If you upload or share content, you affirm that you own or have the necessary permissions to use the content and grant ResearchTube.AI the right to use, modify, and display it as part of the platform. All content on ResearchTube.AI, including text, graphics, logos, and data, is the property of ResearchTube.AI and protected under intellectual property laws. Users are granted limited, non-exclusive, non-transferable access to use the site for personal, educational, and research purposes only. You may not reproduce, distribute, or create derivative works from the content without express permission. If you upload or share content, you affirm that you own or have the necessary permissions to use the content and grant ResearchTube.AI the right to use, modify, and display it as part of the platform.</li>
                    <li> Proprietary Rights. The Customer acknowledges that the platform/software and all copies thereof is the sole property of ResearchTube.Ai. Apart from the rights granted to the user, all applicable rights in copyright, trademark, trade names, trade secrets, patents, and other intellectual property rights in the Software remain with ResearchTube and the Customer will not acquire any such rights in the Software </li>
                    <li> Assignment. The Licence may not be assigned, sublicensed or otherwise transferred by the Customer, without ResearchTube's prior written consent. ResearchTube may sublicense, assign, charge or otherwise transfer any of its rights or obligations under this Agreement.</li>
                    <li> User-Generated Content. ResearchTube.AI may allow users to upload, share, and publish content such as research papers, discussions, and comments. By doing so, you agree that: You will not post any content that is illegal, defamatory, obscene, or infringes on intellectual property rights. You grant ResearchTube.AI a non-exclusive, royalty-free, worldwide license to use, display, and share the content within the platform. We reserve the right to review, edit, or remove any content that violates our terms or is deemed inappropriate.</li>
                    <li> Third-Party Visuals and Audio. ResearchTube.AI may use visuals, images, audio, or video content that is sourced from third-party providers. Such content is used under licenses or agreements with the third parties, and you agree not to reproduce, distribute, or modify this third-party content unless explicitly permitted by ResearchTube.AI or the respective third-party providers.</li>
                    <li> User-Generated Content. ResearchTube.AI may allow users to upload, share, and publish content such as research papers, discussions, and comments. By doing so, you agree that: You will not post any content that is illegal, defamatory, obscene, or infringes on intellectual property rights. You grant ResearchTube.AI a non-exclusive, royalty-free, worldwide license to use, display, and share the content within the platform. We reserve the right to review, edit, or remove any content that violates our terms or is deemed inappropriate.</li>
                    <li> Termination of Access. We reserve the right to suspend or terminate your account and access to ResearchTube.AI at our discretion, without prior notice, if you violate these terms or engage in prohibited activities.</li>
                    <li> Indemnification. By using ResearchTube.AI, you agree to indemnify and hold harmless ResearchTube.AI, its affiliates, employees, and partners from any claims, damages, or expenses (including legal fees) arising from your use of the platform or breach of these terms.</li>
                    <li> Changes to Terms. We may modify these Terms from time to time. Any changes will be effective upon posting to the site. Your continued use of ResearchTube.AI following any updates means that you accept and agree to the new terms.</li>
                    <li> Governing Law. These terms are governed by the laws of Canada, without regard to its conflict of law principles. Any legal disputes arising from these terms, or your use of the platform will be handled in the courts of Canada.</li>
                    <li> Limitation of Liability. EXCEPT FOR ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAWS (INCLUDING WITHOUT LIMITATION CAUSING DEATH OR PERSONAL INJURY THROUGH NEGLIGENCE, LIABILITY FOR FRAUD OR THAT COMPANY HAS GOOD TITLE TO THE SOFTWARE), UNDER NO CIRCUMSTANCES SHALL RESEARCHTUBE.AI OR ITS SUCCESSORS, ASSIGNS, SUBSIDIARIES, AFFILIATES, DIRECTORS, OFFICERS, EMPLOYEES, CONTRACTORS OR AGENTS BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OR LOSSES (INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL, BUSINESS OPPORTUNITY, TURNOVER, ANTICIPATED SAVINGS, DATA OR INFORMATION, BUSINESS INTERRUPTION, AND THE LIKE) ARISING OUT OF THE USE OF OR INABILITY TO USE THE SOFTWARE, EVEN IF RESEARCHTUBE OR ITS AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. RESEARCHTUBE.AI IS UNABLE TO GIVE YOU GUIDANCE REGARDING COPYRIGHT OR WHICH, IF ANY, LIMITATIONS ON COPYRIGHT COULD BE APPLICABLE TO YOUR MATERIAL. NEVERTHELESS, WE STILL HAVE THE ABILITY TO OFFER FEW GENERAL POINTS THAT WE BEILEVE WILL PROVE USEFUL. THE RIGHTS THAT YOU HAVE TO THE MATERIAL WILL GENERAL INFLUENCE THE ABILITY TO POST YOUR RESEARCH ON RESEARCHTUBE.AI AND MIGHT BE GOVERNED BY A CONTRACT YOU MAINTAIN WITH YOUR PUBLISHER.</li>
                    <li> Privacy Policy. Your privacy is important to us. By using ResearchTube.AI, you agree to the collection and use of your data as outlined in our Privacy Policy. This includes the collection of personal information during registration and data regarding your activity on the site.</li>
                    <li> General. This Agreement constitutes the entire agreement between the Customer and ResearchTube, and revokes and supersedes all prior advertising, communications, and agreements, oral or written, relating to the Software. The Agreement shall not be modified or amended except in a written amendment signed by an authorized representative of ResearchTube and specifically referring to this Agreement. If any provision of this Agreement is held void, invalid or unenforceable, the remainder of the Agreement shall continue in full force and effect. No provision of this Agreement shall be deemed waived unless such waiver is contained in a written addendum to this Agreement signed by an authorized representative of ResearchTube.</li>
                    <li> Third-Party Links. ResearchTube.AI may contain links to third-party websites or services. We do not endorse or assume responsibility for the content or practices of these third parties. Any use of these links is at your own risk.</li>
                    <li> Contact Us. If you have any questions regarding these terms or your use of ResearchTube.AI, please contact us at contact@researchtube.ai.</li>
                    <li> By creating an account, you acknowledge that you have read, understood, and agreed to these terms and conditions.</li>

                </ol>
            </p>
        </div>
    )
}

export default TermsConditionsTxt
