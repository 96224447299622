import FormInput from "./FormInput"
import React, { useState } from 'react'
import { FaEye, FaEyeSlash } from "react-icons/fa"

const PasswordInput = (props) => {
    const [passwordType, setPasswordType] = useState('password')
    const [passwordIcon, setPasswordIcon] = useState(<FaEyeSlash />)
    const { onChange, ...inputProps } = props;

    const handleToggle = () => {
        if (passwordType === 'password') {
            setPasswordType('text');
            setPasswordIcon(FaEye);
        }
        else {
            setPasswordType('password');
            setPasswordIcon(FaEyeSlash);
        }
    }
    return (
        <>
            <div className="position-relative">
                <FormInput {...inputProps} type={passwordType}
                    required='required' onChange={onChange} placeholder="Enter your password.."
                />
                <span className="password-icon-span" name={props.name + '-icon'}
                    onClick={handleToggle}>
                    {passwordIcon}
                </span>
            </div>
        </>
    )
}

export default PasswordInput
