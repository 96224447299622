import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { LazyLoadImage } from "react-lazy-load-image-component";
const env = require('../../config.env')

const CachedImage = ({ imageUrl }) => {
  const [imageSrc, setImageSrc] = useState('');

  // useEffect(() => {
  //   let headers = { mediaUrl: imageUrl }
  //   axios.get(`${env.SERVER_REQUEST_PATH}getMedia`, { headers: headers })
  //     .then(response => {
  //       const objectURL = response.data.media//URL.createObjectURL(blob);
  //       setImageSrc(objectURL);
  //     }).catch(err => {
  //       // console.log(err)
  //     })
  // }, [imageUrl]);

  return <LazyLoadImage className='poster-img position-relative'

    src={imageUrl ? env.SERVER_REQUEST_PATH + imageUrl : env.SERVER_MEDIA_PATH + 'default_thumb.png'}
  // src={imageSrc ? `data:image/*;base64,${imageSrc}` : env.SERVER_MEDIA_PATH + 'default_thumb.png'}
  />


};

export default CachedImage;
