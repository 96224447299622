import "../css/Home.css";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Navbar from "../../SharedComponents/js/Navbar";
import FeedsGrid from '../Components/Main/Feeds/FeedsGrid';
import React, { useState, useEffect, useRef } from 'react';
import { useErrorBoundary } from "react-error-boundary";
import { useStateValue } from "../../index";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from "../Components/Header";
import Footer from "../../SharedComponents/js/Footer";
import MiniFooter from "../../SharedComponents/js/Mini-Footer";
import { BsStars } from "react-icons/bs";

const env = require('../../config.env')

function Home() {
    const { id } = useParams()
    const { state } = useLocation()
    const [pageCount, setpageCount] = useState(1)
    const totalCount = useRef()
    const scrolledCount = useRef()
    const [type, set_type] = useState("popular")
    const [feeds,] = useState([
        { text: "Popular", type: "popular" },
        { text: "Recent", type: "recent" },
        // { text: "Recommended", link: "recommended" }
    ])
    const [videos, setVideos] = useState();
    const videosref = useRef();
    const { showBoundary } = useErrorBoundary();
    const [userDetails, dispatch] = useStateValue();

    const navigate = useNavigate()

    const fetchVideos = async (is_scroll = false, type, end = 0) => {
        if (type) {
            let req_path = ''
            req_path = `${env.SERVER_REQUEST_PATH}feeds/${type}?page=${pageCount}`

            if (!is_scroll) {
                videosref.current = null
                setVideos()
            }
            let headers = { page_size: env.SCROLLED_PAGE_SIZE }
            if (state?.is_private)
                headers = { is_private: true, page_size: env.SCROLLED_PAGE_SIZE }
            axios.get(req_path, { headers: headers })
                .then((response) => {
                    let new_vids = response.data.data
                    let vid = videosref.current
                    if (is_scroll) {
                        let all_v = []
                        all_v.push(...vid)
                        all_v.push(...new_vids)
                        videosref.current = all_v
                        scrolledCount.current = all_v.length
                        setVideos(all_v)
                    }
                    else {
                        scrolledCount.current = new_vids.length
                        videosref.current = new_vids;
                        setVideos(new_vids)
                    }
                    totalCount.current = response.data.meta ? response.data.meta.totalCount : 0
                }).catch(err => {
                    showBoundary(err)
                })
        }
    }

    const handleScroll = () => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight
        if (bottom && scrolledCount.current < totalCount.current) {
            setpageCount(page => page + 1)
            fetchVideos(true, type);
        }
    };

    const getMoreVideos = async () => {
        if ((videosref.current.length * pageCount) < totalCount.current) {
            await fetchVideos(true, type, videosref.current.length);
        }
    };

    useEffect(() => {
        fetchVideos(false, type);

        window.addEventListener('scroll', handleScroll, {
            passive: true
        });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [id]);

    return (

        <div className="home"
            style={{
                width: "100%",
                height: "100%"
            }}>

            <Header userdata={userDetails} catchErrors={(err) => { showBoundary(err) }} />
            <div className="page-content d-flex flex-column px-md-5 px-3 py-5">

                <div className="home-banner d-flex flex-column justify-content-center align-items-center px-3">
                    <h3 className="text-center">Generate a video about your research article with AI</h3>
                    <Button className="home-generate-btn mt-3" onClick={() => { navigate('/generate') }}>
                        START GENERATING
                        <BsStars size={15} className='ms-2' />
                    </Button>
                </div>

                <div className="home-title mt-3 mb-2">
                    <h3>Explore researchers</h3>
                    <p className="m-0">Find out what’s new in your research field.</p>
                </div>

                <div className='home-content'>
                    <Navbar loadData={(newType) => {
                        set_type(newType)
                        fetchVideos(false, newType)
                    }} feeds={feeds} currentType={type} />

                    <FeedsGrid cards={videosref.current} type={type} scrollToBottom={getMoreVideos}
                        is_private={state ? state.is_private : false}
                        refreshVideos={() => { fetchVideos(false, type) }}
                        catchErrors={(err) => showBoundary(err)}>
                    </FeedsGrid>

                </div>
            </div>
            <MiniFooter />
        </div>
    )

}

export default Home;
