import React, { useContext, useRef, useEffect, useState } from 'react'
import axios from 'axios'
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';
import StandardSections from '../Components/StandardSections'
// import Figures from '../Components/Figures'
import Summary from '../Components/Summary';
import Publish from '../Components/Publish';
import '../css/Generate.css'
import AddMaterials from './AddMaterials';
// import WebSocketClient from 'websocket';
import AutoGenSteps from './AutoGenSteps';
const env = require('../../config.env')

const AutoGenerate = (props) => {
    const [progress, setprogress] = useState(6)
    const [video_progress, setvideo_progress] = useState(0)
    const [generateSteps, setgenerateSteps] = useState([])
    const [gen_done_msg, setgen_done_msg] = useState()
    const [voice_type, set_voice_type] = useState()

    const [levels, setlevels] = useState([]);
    const [stepCount, setstepCount] = useState(0)
    const [figures_content, setfigures_content] = useState('')
    const [sections_content, setsections_content] = useState('')
    const [disabled_sections, setdisabled_sections] = useState()
    const [publish_content, setpublish_content] = useState('')
    const [material_content, setmaterial_content] = useState('')
    const [generating_content, setgenerating_content] = useState('')
    const [summary_content, setsummary_content] = useState('')

    const [is_author, set_is_author] = useState(false)
    const [is_share, set_is_share] = useState(false)
    const [doc_id, setdoc_id] = useState('')
    const [doc_title, getdoc_title] = useState([])
    const [doc_URL, getdoc_URL] = useState()
    const [VideoData, setVideoData] = useState()
    const [VideoId, setVideoId] = useState()
    const [retry, setRetry] = useState(false);
    const [running, setRunning] = useState(false);
    const [figures, getfigures] = useState([])
    const [section_titles, getsection_titles] = useState([])
    const [selected_contents, setselected_contents] = useState([])
    const [sections, getsections] = useState([])
    const [selected_figures, getselected_figures] = useState([])
    const [summaries, getSummaries] = useState([])
    const [doc_interest, set_interest] = useState()
    const [selectedSections, setselectedSections] = useState([])
    const [stepsTiming, set_stepsTiming] = useState([])
    const [video_genTiming, set_video_genTiming] = useState(0)
    const [steps_gen_counter, setsteps_gen_counter] = useState(0);
    const [progress_rate, setprogress_rate] = useState(1);
    // const [isgenerate, setisgenerate] = useState(false)

    const intervalRef = useRef()

    useEffect(() => {
        if (props.is_done_flages) {
            if (!props.is_done_flages.retry)
                setstepCount((prev) => prev + 1);

            setRetry(props.is_done_flages.retry)
            setRunning(props.is_done_flages.running)
        }
    }, [props.is_done_flages])

    useEffect(() => {
        if (running) {
            intervalRef.current = setInterval(() => {
                set_video_genTiming((prv) => prv + 1000)
                setvideo_progress((prev) => prev + progress_rate);
            }, 1000);
        } else {
            clearInterval(intervalRef.current);
            setvideo_progress(0)
            setsteps_gen_counter(0)
            setgen_done_msg()
            intervalRef.current = null;
        }
    }, [running]);
    useEffect(() => {
        if (running && video_progress === 100) {
            setvideo_progress(99)
        }
        else if (!running && video_progress === 100) {
            setRunning(false);
            setvideo_progress(100)
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    }, [video_progress]);
    useEffect(() => {
        if (stepsTiming.length > 0)
            if (stepsTiming[steps_gen_counter] < video_genTiming && steps_gen_counter < stepsTiming.length - 1)
                setsteps_gen_counter((prev) => prev + 1)

    }, [video_genTiming])
    useEffect(() => {
        setstepCount(2)
        setprogress(17)
        setvideo_progress(0)
        setsections_content("")
        setfigures_content("hidden")
        setgenerating_content("hidden")
        setpublish_content("hidden")
        setsummary_content("hidden")
        setmaterial_content("hidden")
        getsections(props.sections)
        let dis_sections = []
        if (props.sections) {
            props.sections.map((s) => {
                if (s.section_content)
                    dis_sections.push(false)
                else
                    dis_sections.push(true)

            })

            setdisabled_sections(dis_sections)
        }
        getdoc_URL(props.doc_URL)
        setdoc_id(props.doc_id)
        getsection_titles(props.section_titles)

        getdoc_title(props.doc_title)
        set_interest(props.doc_interest)
        set_is_author(props.is_author)
        set_voice_type(props.voice_type)
        set_is_share(props.is_share)

    }, [])
    useEffect(() => {
        if (selectedSections.length > 0 && stepsTiming.length <= 0) {
            let timing = []
            let all_time = 0
            let sections_times = 90000
            let counter = 0;
            selectedSections.map((s) => {
                counter += sections_times
                timing.push(counter)
            })
            let final_msg_time = 30000
            all_time = counter + final_msg_time + 10000
            timing.push(counter + final_msg_time)
            timing.push(counter + final_msg_time + 10000)
            let rounds = all_time / 1000
            let rate = ((1 / rounds) * 100)
            setprogress_rate(parseFloat(rate.toFixed(2)))
            set_stepsTiming(timing)
        }
    }, [selectedSections])
    useEffect(() => {
        if (props.figures)
            getfigures(props.figures)

    }, [props.figures])
    useEffect(() => {
        if (levels.length <= 0) {
            axios.get(env.SERVER_REQUEST_PATH + 'summaryLevels')
                .then((response) => {
                    let data = response.data.data
                    setlevels(data)
                }).catch(err => {
                    console.error(`Error: ${err}`)
                })
        }
    }, [])

    useEffect(() => {
        if (props.videoId)
            setVideoId(props.videoId)
    }, [props.videoId])

    useEffect(() => {
        if (props.videoData)
            setVideoData(props.videoData)
    }, [props.videoData])


    useEffect(() => {
        showStep()
    }, [stepCount])
    const showStep = () => {
        switch (stepCount) {
            case 2:
                setprogress(17)
                setsections_content("")
                setsummary_content("hidden")
                setfigures_content("hidden")
                setgenerating_content("hidden")
                setmaterial_content("hidden")
                setpublish_content("hidden")
                return
            case 3:
                setprogress(33)
                setsections_content("hidden")
                setsummary_content("")
                setfigures_content("hidden")
                setgenerating_content("hidden")
                setmaterial_content("hidden")
                setpublish_content("hidden")
                return
            case 4:
                setprogress(48)
                setsections_content("hidden")
                setsummary_content("hidden")
                setfigures_content("")
                setgenerating_content("hidden")
                setmaterial_content("hidden")
                setpublish_content("hidden")
                return
            case 5:
                setprogress(65)
                setsections_content("hidden")
                setsummary_content("hidden")
                setfigures_content("hidden")
                setgenerating_content("")
                setmaterial_content("hidden")
                setpublish_content("hidden")
                return
            case 6:
                setprogress(84)
                setsections_content("hidden")
                setsummary_content("hidden")
                setfigures_content("hidden")
                setgenerating_content("hidden")
                setmaterial_content("")
                setpublish_content("hidden")
                return

            case 7:
                setprogress(100)
                setsections_content("hidden")
                setsummary_content("hidden")
                setfigures_content("hidden")
                setgenerating_content("hidden")
                setmaterial_content("hidden")
                setpublish_content("")
                return
        }
    }
    const submitSections = (sectionsNames) => {
        setselectedSections(sectionsNames)
        let selectedContent = []
        sectionsNames.map((name) => {
            let result = sections.find((section) =>
                section.section_title.trim().includes(name.trim()
                ))
            if (result)
                selectedContent.push(result)
        })
        setselected_contents(selectedContent)
        submitContent(selectedContent)

    }
    const submitContent = (sections_contents) => {
        props.restErrors()
        props.beginProcess()
        let content_data_req = []
        let data = []
        sections_contents.map((item) => {
            content_data_req.push({
                title: item.section_title, maxLength: levels[0].max_len,
                minLength: levels[0].min_len, content: item.section_content
            })
            data.push({ summ_level: levels[0].id, title: item.section_title })
        })
        let reqdata = { doc_id: doc_id, sectioncontents: content_data_req }
        axios.post(env.SERVER_REQUEST_PATH + 'sendContents', reqdata)
            .then((response) => {
                let status = response.status
                if (status == 200) {
                    let data = response.data
                    let summariesWithlvl = []
                    data.sections.map((sum, index) => {
                        let summary_words = sum.summary.split(' ')
                        let sumWithLvl = {
                            title: sum.title, summary: sum.summary,
                            maxLength: summary_words.length + env.SUMMARY_EDIT_MAX
                        }
                        summariesWithlvl.push(sumWithLvl)
                    })

                    getSummaries({ sections: summariesWithlvl })
                    setstepCount((prev) => prev + 1);
                    props.endProcess()
                }

            }).catch(err => {
                props.handleErrors(err)
                props.set_stop_process(true)
                console.error(`Error: ${err}`)
            })
    }

    const submitFiguers = (selectedfiguers) => {
        getselected_figures(selectedfiguers)
        setstepCount((prev) => prev + 1);
        // submit_all(summaries, selectedfiguers)
        generate(summaries, selectedfiguers)
    }
    const onCancel = (flag) => {
        props.Cancel(flag)
    }
    const publishOrSaveVid = (title, descr, taggedUsers) => {
        props.publishOrSave(VideoId, title, descr, taggedUsers)
    }
    const submitSummary = (changedsumms) => {
        getSummaries(changedsumms)
        props.restErrors()
        setstepCount((prev) => prev + 1);
    }
    const submit_all = (finalSumm, final_figs) => {
        let msgs = []

        if (retry) {
            finalSumm = summaries
            final_figs = selected_figures
            props.restErrors()
        }
        let req_data = []
        finalSumm.sections.map((summ, index) => {
            if (!retry) {
                msgs.push("Generating Section " + summ.title + " Video")
            }
            if (final_figs[index].length > 0) {
                let sub_figs = []
                final_figs[index].map((f) => {
                    sub_figs.push(f.fig_name)
                })
                summ.summary = summ.summary.replace(/"/g, '');
                let row = {
                    title: summ.title, summary: summ.summary,
                    original: summ.original,
                    summ_level: levels[0].id,
                    figures: sub_figs
                }
                req_data.push(row)
            }
        })
        let generateData = {
            interest_id: doc_interest,
            doc_id: doc_id,
            title: doc_title,
            URL: doc_URL,
            voiceType: voice_type,
            api_data: req_data
        }

        setRunning(true)
        if (!retry) {
            msgs.push("Final touch")
            msgs.push("Almost done")
            setgenerateSteps(msgs)
        }
        axios.post(env.SERVER_REQUEST_PATH + 'generate',
            generateData
        )
            .then((response) => {

                //nextstep()
                setstepCount((prev) => prev + 1);
                setVideoData({
                    video_path: response.data.videoPath,
                    thumb_img: response.data.thumb_img,
                    subtitle: response.data.subtitle
                })
                setVideoId(response.data.video_id)
                props.restErrors()
                setRunning(false)
                setRetry(false)
            }).catch(err => {
                setRetry(true)
                setRunning(false)
                props.handleErrors(err)
                props.set_stop_process(true)
                console.error(`Error: ${err}`)
            })
    }
    const submitMaterials = () => {
        setstepCount((prev) => prev + 1);
    }

    const generate = (finalSumm, final_figs) => {
        let msgs = []

        if (retry) {
            finalSumm = summaries
            final_figs = selected_figures
            props.restErrors()
        }
        let req_data = []
        finalSumm.sections.map((summ, index) => {
            if (!retry) {
                msgs.push("Generating Section " + summ.title + " Video")
            }
            if (final_figs[index].length > 0) {
                let sub_figs = []
                final_figs[index].map((f) => {
                    sub_figs.push(f.fig_name)
                })
                summ.summary = summ.summary.replace(/"/g, '');
                let row = {
                    title: summ.title, summary: summ.summary,
                    original: summ.original,
                    summ_level: levels[0].id,
                    figures: sub_figs
                }
                req_data.push(row)
            }
        })
        let generateData = {
            interest_id: doc_interest,
            doc_id: doc_id,
            title: doc_title,
            URL: doc_URL,
            voiceType: voice_type,
            api_data: req_data
        }

        setRunning(true)
        if (!retry) {
            msgs.push("Final touch")
            msgs.push("Almost done")
            setgenerateSteps(msgs)
        }
        axios.post(env.SERVER_REQUEST_PATH + 'asyncgeneratevideo',
            generateData
        )
            .then((response) => {
                props.togglegenerate(true)
                // setisgenerate(true)
            }).catch(err => {
                setRetry(true)
                setRunning(false)
                props.handleErrors(err)
                props.set_stop_process(true)
                console.error(`Error: ${err}`)
            })
    }


    return (
        <div className='top-m mt-3 generate-container auto'>
            <AutoGenSteps stepCount={stepCount} progress={progress} is_author={is_author} />
            <div className="tab-content" id="pills-tabContent">
                <div className="gen-page-wrapper" hidden={sections_content} id="sections-content">
                    <StandardSections sections={section_titles} defaultLevel={levels[0]}
                        isLoading={props.isLoading}
                        stop_process={props.stop_process}
                        disabled_sections={disabled_sections}
                        submitSections={submitSections} Cancel={onCancel} />
                </div>
                <div className="gen-page-wrapper" hidden={summary_content} id="summary-content">
                    <Summary isLoading={props.isLoading} summaries={summaries}
                        mode={"vid"}
                        stop_process={props.stop_process}
                        submitSummary={submitSummary} Cancel={onCancel} />
                </div>
                {/* <div className="gen-page-wrapper" hidden={figures_content} id="figures-content">
                    <Figures //saveDraft={onsaveDraft} 
                        isLoading={props.isLoading}
                        figuers={figures} selected_sections={selectedSections}
                        summaries={summaries}
                        stop_process={props.stop_process}
                        handleErrors={(err, continue_process) => { props.handleErrors(err, continue_process) }}
                        restErrors={() => { props.restErrors() }}
                        submitFiguers={submitFiguers} Cancel={onCancel} />
                </div> */}
                <div className={`${generating_content == 'hidden' ?
                    '' : 'd-flex flex-column align-items-center'}  pt-5 gen-page-wrapper`}
                    hidden={generating_content} id="generating-content">
                    <ProgressBar id="video-progress" label={`${Math.floor(video_progress >= 100 ? 99 : video_progress)}%`} animated
                        className='video-bar' variant="success" now={Math.floor(video_progress >= 100 ? 99 : video_progress)} />
                    {gen_done_msg && <p className='py-5'>{gen_done_msg}</p>}

                    {!gen_done_msg && <p className='py-5'>{
                        generateSteps[steps_gen_counter]
                    }</p>}

                    <div className='mt-5 w-100 d-flex justify-content-end mt-3' hidden={retry ? '' : 'hidden'}>
                        <p className='file-cancel mt-2'
                            onClick={() => {
                                onCancel(true)
                            }}>Cancel</p>
                        <Button disabled={running ? 'disabled' : props.stop_process ? 'disabled' : ''}
                            className='gen-button ms-3' type='submit' variant="primary" onClick={generate}>
                            Generate</Button>

                    </div>
                </div>
                <div className="gen-page-wrapper" hidden={material_content} id="material-content">
                    <AddMaterials submit={submitMaterials}
                        isLoading={props.isLoading} video_id={VideoId}
                        stop_process={props.stop_process}
                        beginProcess={() => { props.beginProcess() }}
                        endProcess={() => props.endProcess()}
                    />
                </div>
                <div className="gen-page-wrapper" hidden={publish_content} id="publish-content">
                    <Publish
                        VideoData={VideoData} is_author={is_author} is_share={is_share} doc_title={doc_title}
                        saveVideo={publishOrSaveVid}
                        stop_process={props.stop_process}
                    />
                </div>
                <div className='gen-btns'>
                </div>
            </div>
        </div>

    )
}

export default AutoGenerate

