import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BarLoader, PropagateLoader } from 'react-spinners'
import AudioPlayer from 'react-h5-audio-player';
import '../css/FixedAudioPlayer.css'
const env = require('../../config.env')

const FixedAudioPlayer = ({ title, url, playerRef, player_index, playSelecedAudio, handleAudioEnded }) => {
    const [AudioStream, getAudioStream] = useState()
    useEffect(() => {
        if (url) {
            let headers = { audio_url: url }

            axios.get(`${env.SERVER_REQUEST_PATH}getAudio`, {
                responseType: 'blob',
                headers: headers
            })
                .then(response => {
                    var objectURL = URL.createObjectURL(response.data)
                    getAudioStream(objectURL);
                }).catch(err => {
                    // console.log(err)
                })
        }
    },
        [url])
    return (
        <div className='fixed-audio'>
            {/* <div className='mb-2'>{title}</div> */}
            <AudioPlayer
                header={<div className='my-2'>{title ? title : <BarLoader className='my-2' color="#B9AFF7" width={250} />}</div>}
                onClickNext={() => { playSelecedAudio(player_index + 1) }}
                onClickPrevious={() => { playSelecedAudio(player_index - 1) }}
                src={AudioStream}
                autoPlay={player_index == 0 ? true : false}
                onEnded={() => { handleAudioEnded(player_index) }}
                ref={playerRef}
                autoPlayAfterSrcChange
                showDownloadProgress={true}
                showSkipControls={true}
                showJumpControls={true}
                showFilledProgress={true}
                showFilledVolume={true}
            />
        </div>
    )
}

export default FixedAudioPlayer
