import { useEffect, useState } from "react";
import axios from 'axios'
import Button from 'react-bootstrap/Button';
import '../css/Sections.css'
import { Alert } from "react-bootstrap";
const env = require('../../config.env')
const helper = require('../../utilities/helpers')
const StandardSections = (props) => {
    const [checkedState, setCheckedState] = useState([]);
    const [names, setSelected] = useState([]);
    const [v_es_duration, setv_es_duration] = useState('0 minutes')
    const [v_es_time, setv_es_time] = useState(0)
    const [v_cal_duration, setv_cal_duration] = useState(0)

    useEffect(() => {
        const intial = new Array(props.sections.length).fill(false)
        setCheckedState(intial)
    }, [props.sections])
    const saveSections = () => {

        props.submitSections(names, v_cal_duration)

    }

    const checkWordsCount = (updatedCheckedState, selected) => {
        let is_reach_max = false
        let no_w_calc = parseInt(v_cal_duration)
        let estimated_time = parseInt(v_es_time)

        if (!updatedCheckedState)
            updatedCheckedState = checkedState

        let selected_count = updatedCheckedState.filter(x => x == true).length

        if (selected_count > env.SECTION_NUM_LIMIT)
            is_reach_max = true
        else {
            if (selected) {
                no_w_calc += props.defaultLevel.max_len
                estimated_time += env.SECTION_NO_SECONDS
            }
            else {
                no_w_calc = no_w_calc > 0 ? no_w_calc - props.defaultLevel.max_len : 0
                estimated_time = estimated_time > 0 ? estimated_time - env.SECTION_NO_SECONDS : 0
            }
        }
        let estimated_str = helper.fancyTimeFormat(estimated_time)

        setv_cal_duration(no_w_calc)
        setv_es_duration(estimated_str)
        setv_es_time(estimated_time)
        return is_reach_max
    }


    const handleOnChange = (e, position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);

        let is_reach_max = checkWordsCount(updatedCheckedState, e.target.checked)
        if (is_reach_max) {
            e.target.checked = false
            setCheckedState(checkedState);
        }


        let selectedSections = [];
        updatedCheckedState.reduce(
            (sum, currentState, index) => {
                if (currentState === true) {
                    return selectedSections.push(sections[index]);
                }
                return selectedSections;
            },
            0
        );

        if (!is_reach_max)
            setSelected(selectedSections);
    };
    let sections = props.sections

    if (sections.length > 0) {

        return (
            <div className="w-100 sections-container mt-2">
                <div className="px-4 row m-0 d-flex justify-content-center">
                    <div className="mt-3">
                        <div className="d-flex justify-content-start align-items-start">
                            <strong className="me-4">Select your sections to summarize</strong>
                            <div className="">
                                <Alert className='space-around' key='info' variant='info'>
                                    <span className="fw-bold">Choose Maximum 2 sections</span>
                                </Alert>
                            </div>
                        </div>
                        <div className="d-flex w-100">
                            <div className='w-100 d-flex justify-content-end'>
                                <span className='fw-bold fs-6'>
                                    Video estimated duration: {v_es_duration}</span>
                            </div>
                        </div>

                    </div>


                    <div className="col-md-6 mb-3">
                        <div className=" list-height sections-list border border-1 rounded">
                            <div className="visible-scrolledParent">
                                {sections.map((item, index) => {
                                    return (

                                        <div className="m-2 " key={index}>

                                            <label
                                                className={`m-2 border border-1 rounded p-2 lbl-container bs-body-font-family ${checkedState[index] ? 'checked' : ''} ${props.disabled_sections?.[index] ? ' disabled-style' : ''}`}
                                                htmlFor={`sec-checkbox-${index}`}>
                                                <input
                                                    type="checkbox"
                                                    id={`sec-checkbox-${index}`}
                                                    name={sections[index]}
                                                    value={sections[index]}
                                                    onChange={(e) => handleOnChange(e, index)}
                                                    className={checkedState[index] ? 'checked' : ''}
                                                    disabled={props.disabled_sections?.[index] ? 'disabled' : ''}
                                                />
                                                <span className={checkedState[index] ? "checked checkmark mr-3" : "checkmark mr-3"}></span>
                                                {sections[index]}
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <ul className="list-height list-group border border-1 rounded">
                            <li className="list-group-item box-header">Selected Sections</li>
                            <div className="visible-scrolledParent">
                                {names.map((section, index) => <li key={"section-" + index} className="box-item list-group-item">{section}</li>)}
                            </div>
                        </ul>
                    </div>
                </div>
                <div className='d-flex justify-content-end pe-4 mt-3'>
                    <p className='file-cancel mt-2'
                        onClick={() => {
                            props.Cancel(true)
                        }}>Cancel</p>
                    <Button disabled={props.isLoading ? 'disabled' : props.stop_process ? 'disabled' : ''}
                        className='gen-button mx-3' type='submit' variant="primary" onClick={saveSections}>
                        Next</Button>
                </div>

            </div>
        )
    }
    else return (<></>)
}

export default StandardSections
