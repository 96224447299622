
import React, { useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import '../css/Pagination.css';
const env = require('../../config.env')
const Pagination = (props) => {
  const currentPage = props.currentPage
  const totalCount = props.totalCount
  const handlePrevClick = () => {
    props.onPrevClick();
  }

  const handleNextClick = () => {
    props.onNextClick();
  }

  const handlePageClick = (e) => {
    props.onPageChange(Number(e.target.id));
  }
  return (
    <nav aria-label="Page navigation">
      <ul className="pagination pagination-sm  justify-content-center mt-4">
        <li className={`page-item first-item ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={handlePrevClick}
        >
          <Link aria-label="Previous" to={{ search: `?page=${currentPage - 1}` }}
            className="page-link">
            <span aria-hidden="true">&laquo;</span>
            <span className="sr-only">Previous</span>
          </Link>

        </li>
        {[...Array(Math.ceil(totalCount / env.PAGE_SIZE))].map((x, i) => {
          return (
            <li pageNum={i + 1} className="page-item">
              <li key={i + 1} className={`page-item number-item ${currentPage === i + 1 ? 'active' : ''}`}
                onClick={handlePageClick}
              >
                <Link to={{ search: `?page=${i + 1}` }}
                  className="page-link">{i + 1}</Link>
              </li>
            </li>
          )
        })}
        <li className={`page-item next-item ${currentPage === (Math.ceil(totalCount / env.PAGE_SIZE)) ? 'disabled' : ''}`}
          onClick={handleNextClick}
        >
          <Link to={{ search: `?page=${currentPage + 1}` }}
            className="page-link" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
            <span className="sr-only">Next</span>
          </Link>
        </li>
      </ul>
    </nav>
  )

}
export default Pagination;
