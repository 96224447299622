import { Button, Modal } from "react-bootstrap"
import "../css/TermCondition.css"
import React, { useState } from "react";
import TermsConditionsTxt from "./TermsConditionsTxt";

const TermCondition = (props) => {
    const { onChange } = props
    const [showTermsModal, setshowTermsModal] = useState(false);

    const handleClose = () => setshowTermsModal(false);
    const handleShow = () => setshowTermsModal(true);
    return (
        <>
            <div className="form-check terms-conditions required my-3">
                <input className="form-check-input" name="terms" type="checkbox" onChange={onChange} id="flexCheckDefault" />
                <label className="form-check-label" for="flexCheckDefault">
                    By checking the box you are agreeing to our <a onClick={() => { handleShow() }} id="termConditionLink" href="#"> Terms and Conditions</a>*
                </label>
            </div>
            <Modal size="lg" show={showTermsModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Terms and Conditions</Modal.Title>
                </Modal.Header>
                <Modal.Body><TermsConditionsTxt /></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default TermCondition