import React from 'react'
import '../css/static_pages.css'
const ComingSoon = () => {

    return (
        <div className='coningsoon-container'>
        </div>
    )
}

export default ComingSoon
