import "../css/Error.css";
const Error = ({ error, resetErrorBoundary }) => {
    return (
        <div className="error-container">
            <img src="/assets/somethingWentWrong.jpg" />
            <p>Something went wrong:</p>
            {/* <pre>{error.message ? error.message : error}</pre> */}
            <button className="btn-reload" onClick={resetErrorBoundary}>Reload Page</button>
        </div>
    );
}
export default Error;