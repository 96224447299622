import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import '../css/Sections.css'
const env = require('../../config.env')
const Sections = (props) => {
  const [checkedState, setCheckedState] = useState([]);
  const [names, setSelected] = useState([]);
  useEffect(() => {
    const intial = new Array(props.sections.length).fill(false)
    setCheckedState(intial)
  }, [props.sections])
  const saveSections = () => {

    props.submitSections(names)

  }

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);

    let selectedSections = [];
    updatedCheckedState.reduce(
      (sum, currentState, index) => {
        if (currentState === true) {
          return selectedSections.push(sections[index]);
        }
        return selectedSections;
      },
      0
    );

    setSelected(selectedSections);
  };
  let sections = props.sections

  if (sections.length > 0) {

    return (
      <div className="w-100 sections-container mt-2">
        <div className="row m-0 d-flex justify-content-center">
          <div className="my-3"><strong className="ms-1">Select your sections to summarize</strong></div>


          <div className="col-md-6 mb-3">

            <div className="list-height sections-list border border-1 rounded">
              <div className="visible-scrolledParent">
                {sections.map((item, index) => {
                  return (

                    <div className="m-2 " key={index}>

                      <label
                        className={`m-2 border border-1 rounded p-2 lbl-container bs-body-font-family ${checkedState[index] ? 'checked' : ''}`}
                        htmlFor={`sec-checkbox-${index}`}>
                        <input
                          type="checkbox"
                          id={`sec-checkbox-${index}`}
                          name={sections[index]}
                          value={sections[index]}
                          onChange={() => handleOnChange(index)}
                          className={checkedState[index] ? 'checked' : ''}
                        />
                        <span className={checkedState[index] ? "checked checkmark mr-3" : "checkmark mr-3"}></span>
                        {sections[index]}
                      </label>
                    </div>
                  );
                })}
              </div>

            </div>
          </div>
          <div className="col-md-6 mb-3">
            <ul className="list-height list-group border border-1 rounded">
              <div className="visible-scrolledParent">

                <li className="list-group-item box-header">Current Sections</li>
                {names.map((section) => <li className="box-item list-group-item">{section}</li>)}
              </div>
            </ul>
          </div>
        </div>
        <div className='d-flex justify-content-end pe-4 mt-3'>
          <p className='file-cancel mt-2'
            onClick={() => {
              props.Cancel(true)
            }}>Cancel</p>
          <Button disabled={props.isLoading ? 'disabled' : ''}
            className='gen-button mx-3' type='submit' variant="primary" onClick={saveSections}>
            Next</Button>
        </div>

      </div>
    )
  }
  else return (<></>)
}

export default Sections
