import React from 'react';
import '../css/TextEditor.css'
import 'draft-js/dist/Draft.css';
import { BiEditAlt, BiSave } from 'react-icons/bi'
import { Editor, EditorState, convertToRaw, ContentState, getDefaultKeyBinding } from 'draft-js';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const helper = require('../../utilities/helpers')
class TextEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createWithContent(ContentState.createFromText(props.Text ? props.Text : '')),
            disable: true,
            btnName: 'Edit',
            Editable: props.Editable,
            id: props.id,
            mode: props.mode,
            MAX_LENGTH: props.Max_Length
        };

        this.onChange = this.onChange.bind(this);
        this.keyBindingFn = this.keyBindingFn.bind(this);
        this.handleBeforeInput = this.handleBeforeInput.bind(this);
        this.toggleEdit = this.toggleEdit.bind(this);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.setState({ id: this.props.id });
        }
        if (prevProps.Max_Length !== this.props.Max_Length) {
            this.setState({ Max_Length: this.props.Max_Length });
        }
        if (prevProps.Editable !== this.props.Editable) {
            this.setState({ Editable: this.props.Editable });
        }
    }
    handleBeforeInput(chars) {
        // Check if the entered character is a newline character
        if (chars === '\n' && this.state.mode == "free") {
            // If it is, prevent its insertion
            return 'handled';
        }
        // Otherwise, allow the character to be inserted
        return 'not-handled';
    }
    keyBindingFn(e) {
        if (e.key === 'Enter' && this.state.mode == "free") {
            // Prevent the default behavior of the Enter key (newline insertion)
            return 'not-handled';
        }
        // Allow all other key bindings
        return getDefaultKeyBinding(e);
    }
    onChange(editorState) {
        const value = editorState.getCurrentContent().getPlainText('\u0001')
        const no_words = value.split(' ').length
        // if (this.state.mode == "free") {
        //     var new_value = value.replace(/\u0001/g, '');
        //     // const newContent = EditorState.createWithContent(ContentState.createFromText(new_value))
        //     editorState = EditorState.createWithContent(ContentState.createFromText(new_value))


        //     // const contentState = editorState.getCurrentContent();
        //     // const rawContentState = convertToRaw(contentState);
        //     // const newContentState = ContentState.createFromText(rawContentState.blocks[0].text.replace(/\u0001/g, ''));

        //     // // editorState = EditorState.createWithContent(newContentState);


        // }
        if (this.state.MAX_LENGTH && no_words > this.state.MAX_LENGTH) {
            const oldvalue = this.state.editorState.getCurrentContent().getPlainText('\u0001')
            const newContent = EditorState.createWithContent(ContentState.createFromText(oldvalue))
            editorState = EditorState.moveFocusToEnd(newContent)
        }
        this.setState({ editorState });
        const cleanedText = helper.filterNonUTF8Characters(value);
        let replaced_changedText = cleanedText.replace(/\u0001/g, '\n');


        if (this.props.handleChange)
            this.props.handleChange(this.state.id, replaced_changedText)

    }
    toggleEdit() {
        if (this.state.mode != "limit") {
            if (this.state.disable)
                this.setState({ btnName: 'Save' })
            else
                this.setState({ btnName: 'Edit' })
            let editState = this.state.disable
            this.setState({ disable: !editState })
        }
    }
    render() {
        return (
            <div className='editor'>
                <div className='editor-header' hidden={this.state.Editable ? '' : 'hidden'}>
                    <OverlayTrigger placement="bottom"
                        overlay={this.state.mode == "limit" ? <Tooltip id="tooltip">
                            Subscribe for Access
                        </Tooltip> : <></>}>

                        <button className='edit-btn' onClick={this.toggleEdit}>{this.state.btnName}
                            {this.state.disable ? <BiEditAlt /> : <BiSave />}</button>
                    </OverlayTrigger>
                </div>
                <div className="editorContainer pt-4">
                    <Editor
                        readOnly={this.state.disable}
                        editorState={this.state.editorState}
                        id={this.props.id}
                        onChange={this.onChange}
                        onBeforeInput={this.handleBeforeInput}
                        keyBindingFn={this.keyBindingFn}
                    />
                </div>
            </div>
        );
    }
}

export default TextEditor;