import '../css/VideoPoster.css'
import React, { Component } from 'react';
import { AiFillPlayCircle } from 'react-icons/ai'
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import CachedImage from '../../SharedComponents/js/CachedImage';
import { BsClock } from 'react-icons/bs';

const VideoPoster = (props) => {
  return (
    <div className="poster d-flex justify-content-center">
      <CachedImage imageUrl={props.thumb} />
      {!props.ispending && <Link to={`/video/${props.video_id}`}
        state={{ is_private: props.is_private }}
        className={classNames(
          'btn btn-light border-1 position-absolute m-auto styling p-0 d-flex justify-content-center'
        )}>
        <AiFillPlayCircle color='#5644de' className='iconStlye m-auto' />
      </Link>}
      {!props.ispending && <div className='d-flex justify-content-end'>
        <span className="d-flex me-1 v_len position-absolute p-1">
          <BsClock className='me-1' size={14} color='#fff' />
          {props.v_len}
        </span>
      </div>}
    </div>
  );
};
export default VideoPoster;