import React from 'react'
import Badge from 'react-bootstrap/Badge';
import dateFormat from 'dateformat';

const DocumentMetadata = ({ doc_title, doc_URL, authors, doctype, docpublicationDate, docjournaltitle,
    docpublisher, docsubjects }) => {
    return (
        <div className='generate-header ms-5'>
            {doc_title &&
                <a className='w-100 d-flex justify-content-center title_doc_style'
                    href={doc_URL} target="_blank"
                >{doc_title}</a>}

            {authors?.length > 0 && <div className='text-center author-badge mt-3'>
                {authors?.length > 0 && authors?.map((author, index) => {
                    return (
                        <Badge key={"author-" + index} className='ms-1' text="dark" >{author}</Badge>
                    )
                })}
            </div>}
            {doctype && <div className='d-flex justify-content-center my-3 align-items-center'>
                <span className='type-doc-style me-2'>{doctype}</span>
                {docpublicationDate && <span className='date-doc-style'>
                    {Date.parse(docpublicationDate) ? dateFormat(docpublicationDate, "mmm dd, yyyy") :
                        dateFormat(new Date(`${docpublicationDate[0] ? docpublicationDate[0] : ''}${docpublicationDate[1] ? '-' + docpublicationDate[1] : ''}${docpublicationDate[2] ? '-' + docpublicationDate[2] : ''}`),
                            "mmm dd, yyyy")}
                </span>}
            </div>}
            <div className='d-flex justify-content-center pub-font-style'>
                {docjournaltitle && <span>
                    {docjournaltitle}</span>}
                {docjournaltitle && docpublisher && <span>,&nbsp; </span>}
                {docpublisher && <span>
                    {docpublisher}</span>}
            </div>
            {docsubjects?.length > 0 && <div className='text-center sub-badge mt-2' >
                {/* <span>subject: </span> */}
                {docsubjects?.length > 0 && docsubjects.map((subject, index) => {
                    return (
                        <Badge key={"subject-" + index} text="dark" className='ms-1' >{subject}</Badge>
                    )
                })}
            </div>}

        </div >
    )
}

export default DocumentMetadata
