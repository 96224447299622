import { useState, useEffect, useRef } from 'react';
import FeedsGrid from '../Components/Main/Feeds/FeedsGrid';
import { useErrorBoundary } from "react-error-boundary";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios, { all } from 'axios'
import Header from '../Components/Header';
import Footer from '../../SharedComponents/js/Footer';
import Navbar from '../../SharedComponents/js/Navbar';
import '../css/FeedsPage.css'
import { useStateValue } from "../../index";
import FingerLeftMenu from '../Components/Main/FingerLeftMenu';
import AudioPlaylistFeedGrid from '../Components/Main/Playlists/AudioPlaylistFeedGrid';
import MiniFooter from '../../SharedComponents/js/Mini-Footer';
const env = require('../../config.env')


const AudioFeeds = () => {
    const { id } = useParams()
    const { state } = useLocation()
    const [pageCount, setpageCount] = useState(1)
    const totalCount = useRef()
    const scrolledCount = useRef()

    const [audios, setAudios] = useState();
    const audiosref = useRef();
    const { showBoundary } = useErrorBoundary();
    const [userDetails, dispatch] = useStateValue();

    const fetchAudios = async (is_scroll = false, end = 0) => {
        // if (id) {
        if (!is_scroll) {
            audiosref.current = null
            setAudios()
        }
        let headers = {}
        if (state?.is_private)
            headers = { is_private: true }
        axios.get(`${env.SERVER_REQUEST_PATH}audioFeedplaylists?page=${pageCount}`,
            { headers: headers })
            .then((response) => {
                let new_audios = response.data.data
                let audio = audiosref.current
                if (is_scroll) {
                    let all_a = []
                    all_a.push(...audio)
                    all_a.push(...new_audios)
                    audiosref.current = all_a
                    scrolledCount.current = all_a.length
                    setAudios(all_a)
                }
                else {
                    scrolledCount.current = new_audios.length
                    audiosref.current = new_audios;
                    setAudios(new_audios)
                }
                totalCount.current = response.data.meta ? response.data.meta.totalCount : 0
            }).catch(err => {
                showBoundary(err)
            })
        // }
    }

    const handleScroll = () => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight
        if (bottom && scrolledCount.current < totalCount.current) {
            setpageCount(page => page + 1)
            fetchAudios(true);
        }
    };

    useEffect(() => {
        fetchAudios();

        window.addEventListener('scroll', handleScroll, {
            passive: true
        });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [id]);

    const getMoreAudios = async () => {
        if ((audiosref.current.length * pageCount) < totalCount.current) {
            await fetchAudios(true, audiosref.current.length);
        }
    };


    return (
        <div className='d-flex flex-column'>
            <Header userdata={userDetails} catchErrors={(err) => { showBoundary(err) }} />
            <div>
                <div className='top-m feeds-content'>
                    {/* <Navbar feeds={feeds} is_private={state ? state.is_private : false}
                        className="px-5 top-m" /> */}
                    <AudioPlaylistFeedGrid
                        playlists={audiosref.current} type={id}
                        scrollToBottom={getMoreAudios}
                        is_private={state ? state.is_private : false}
                        refreshAudios={() => { fetchAudios() }}
                        catchErrors={(err) => showBoundary(err)}>
                    </AudioPlaylistFeedGrid>
                </div>
                <FingerLeftMenu />
                <MiniFooter />
            </div>
        </div>
    );
}

export default AudioFeeds
