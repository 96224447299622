import { React, useState, useEffect, useRef } from 'react'
import '../css/Summary.css'
import Button from 'react-bootstrap/Button';
import TextEditor from '../../SharedComponents/js/TextEditor'
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'react-bootstrap';
const Summary = (props) => {
    const [orginialsumms, setorginialsumms] = useState([]);
    const [changedsumms, setchangedsumms] = useState([]);
    const [firstLoad, setfirstLoad] = useState(false);
    const loadPopup = useRef(null)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        if (props.summaries.sections) {
            let summ = []
            props.summaries.sections.map((item) => {
                summ.push({ title: item.title, summary: item.summary, edited: false, original: '' })
            })
            setchangedsumms({ sections: summ })
            if (!firstLoad)
                setorginialsumms({ sections: summ })
            setfirstLoad(true)
            if (loadPopup.current != null)
                loadPopup.current = loadPopup.current + 1

            if (props.mode == 'vid' && !loadPopup.current) {
                const sessionData = JSON.parse(localStorage.getItem("sessionData"))
                let is_saved = sessionData ? sessionData.saved_summ_popup_opt ? true : false : false
                loadPopup.current = 0
                if (!is_saved) {
                    handleShow()
                }

            }
        }
    }, [props.summaries])
    const setChange = (e) => {
        if (e.target.checked) {
            const sessionData = JSON.parse(localStorage.getItem("sessionData"))
            if (sessionData) {
                sessionData.saved_summ_popup_opt = true
                localStorage.setItem("sessionData", JSON.stringify(sessionData))
            }
            else
                localStorage.setItem("sessionData", JSON.stringify({ saved_summ_popup_opt: true }))
        }

    }
    const onClick_save = () => {
        props.submitSummary(changedsumms)
    }
    const handleSummChange = (id, changedText) => {
        let changed_contents = []
        changedsumms.sections.map((section) => {
            let changedSection = {}
            if (id === section.title) {
                let found_summary = orginialsumms.sections.find((s) => s.title == id)
                changedSection = {
                    title: section.title,
                    original: found_summary.summary,
                    summary: changedText, edited: true
                }
            }
            else { changedSection = section }
            changed_contents.push(changedSection)

        })
        setchangedsumms({ sections: changed_contents })
    }
    if (props.summaries.sections) {
        let summaries = props.summaries.sections
        return (
            <>
                <div className="summ-container mt-2 ms-5">
                    <div className='container-title'>
                        <strong className="my-3">Here is your summary..</strong>
                        {(props.mode == 'vid' || props.mode == 'aud') && <p className='p-text-color'>Edit the written sections by pressing the edit button</p>}
                        {props.mode == 'vid' && <Alert className='space-around' key='info' variant='info'>
                            <span className="fw-bold">Please divide the summary into paragraphs based on the number of figures you want to choose per section: 1 figure per 1 paragraph</span>
                        </Alert>}
                        {props.mode == 'vid' &&
                            <p className='fw-bold'>Reduce your summary size for a shorter video duration.</p>}

                    </div>
                    <div className="d-flex w-100  mt-3">
                        <div className="w-25 titlelist title-list-height border border-1 rounded nav flex-row nav-pills me-3" id="summ-pills-tab"
                            role="tablist" aria-orientation="vertical">
                            <div className="w-100 py-2 mb-2 border-bottom title-list-header">
                                <span className='p-2'>Selected Sections</span></div>
                            <div className='titles-links'>
                                {summaries.map((summ, index) => {
                                    return (
                                        <button className={`mx-2 btn-link nav-link ${index == 0 ? 'active' : ''}`}
                                            id={`title-pills-tab-${index}`}
                                            key={`title-pills-tab-${index}`}
                                            data-bs-toggle="pill"
                                            data-bs-target={`#summ-pills-${index}`}
                                            type="button" role="tab"
                                            aria-controls={`summ-pills-${index}`}
                                            aria-selected="true">{summ.title}</button>
                                    )
                                })}
                            </div>
                        </div>

                        <div className="tab-content" id="summ-pills-tabContent">
                            {summaries.map((summ, index) => {
                                return (
                                    <div className={index == 0 ? "tab-pane fade show active" : "tab-pane fade"}
                                        id={`summ-pills-${index}`}
                                        role="tabpanel"
                                        aria-labelledby={`summ-pills-tab-${index}`}>
                                        {(props.mode == 'vid' || props.mode == 'aud') &&
                                            <p className='fw-bold'>
                                                {/* {'Maximum No. of words: ' + summ.maxLength} */}
                                                {'Please Add up to 10 words only to each summary'}
                                            </p>}
                                        <div className="summ-list summ-height border border-1 rounded">

                                            <div
                                                className="m-1 summ-item"
                                                key={index}>
                                                <div className="ps-3 form-outline">
                                                    <TextEditor id={summ.title}
                                                        Max_Length={summ.maxLength}
                                                        Editable={(props.mode == 'vid' || props.mode == 'aud') ? true : false}
                                                        Text={summ.summary} handleChange={handleSummChange} />
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                )
                            })}
                        </div>

                    </div>

                    <div className={` ${(props.mode == 'vid' || props.mode == 'aud' || props.mode == 'auto') ? 'w-100 d-flex justify-content-end mt-3' : 'd-none'}`}>
                        <p className='file-cancel mt-2'
                            onClick={() => {
                                props.Cancel(true)
                            }}>Cancel</p>
                        <Button disabled={props.isLoading ? 'disabled' : props.stop_process ? 'disabled' : ''}
                            className='gen-button mx-3' type='submit' variant="primary" onClick={onClick_save}>
                            Next</Button>
                    </div>

                </div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Body>
                        <p className='p-4'> Please divide the summary into paragraphs based on the number of figures you want to choose per section: 1 figure per 1 paragraph</p>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="flexCheckDefault" onChange={(e) => setChange(e)} />
                            <label class="form-check-label" for="flexCheckDefault">
                                Don't remind me again
                            </label>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='button-style' variant="primary" onClick={handleClose}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}
export default Summary
