
import React from 'react';
import { NavLink } from 'react-router-dom'
import { SlEarphones } from "react-icons/sl";
import { IoMdPlay } from "react-icons/io";
import classNames from 'classnames';
import '../../../css/AudioPlaylist.css'
import CachedImage from '../../../../SharedComponents/js/CachedImage';
import VPlaylistThumb from '../VPlaylistThumb';

const helpers = require('../../../../utilities/helpers')
const VPlaylistRow = ({ playlistDetails }) => {
    let statusType = "";
    let status = "";
    if (playlistDetails.statusid == 1) {
        statusType = "success";
        status = "Published"
    }
    else if (playlistDetails.statusid == 2) {
        statusType = "secondary";
        status = "Unpublished"

    }
    else if (playlistDetails.statusid == 3) {
        statusType = "danger";
        status = "Draft"
    }
    if (playlistDetails)
        return (
            <div className="row m-0 p-0 item-container">
                <div className="content-playlist my-2 col-12 col-md-2 pb-3">
                    <VPlaylistThumb thumb={playlistDetails.thumb_url} playlist_id={playlistDetails.id}
                        video_id={playlistDetails.firstV_id} is_private={false}
                    />
                    {/* <div className='p-2 playlist-item'>
                        <div className='d-flex flex-column'>
                            <div className='d-flex'>
                                <div className='border border-2'>
                                    <CachedImage imageUrl={playlistDetails.thumb_url?.[0]} />
                                </div>
                                <div className='border border-2'>
                                    <CachedImage imageUrl={playlistDetails.thumb_url?.[1]} />
                                </div>

                            </div>
                            <div className='d-flex'>
                                <div className='border border-2'>
                                    <CachedImage imageUrl={playlistDetails.thumb_url?.[2]} />
                                </div>
                                <div className='border border-2'>
                                    <CachedImage imageUrl={playlistDetails.thumb_url?.[3]} />
                                </div>

                            </div>
                        </div>
                        <div className='w-100 d-flex justify-content-end align-items-end playlist-button'>
                            <div className='play-border p-1 border border-1 rounded-circle'>
                                <NavLink to={`/videos/${playlistDetails.firstV_id}`}
                                    state={{ is_private: false, playlist_id: playlistDetails.id, type: 'p' }}>
                                    <IoMdPlay color='#5644de' size={25} />

                                </NavLink></div>
                        </div>
                    </div> */}
                </div>
                <div className="col-12 col-md-8 pb-3 title-style">
                    <p className='mt-4'>
                        {!playlistDetails.firstV_id &&
                            <NavLink >
                                {playlistDetails.title}</NavLink>
                        }
                        {playlistDetails.firstV_id &&
                            <NavLink to={`/video/${playlistDetails.firstV_id}`}
                                state={{ is_private: false, playlist_id: playlistDetails.id, type: 'p' }}>
                                {playlistDetails.title}</NavLink>
                        }
                    </p>
                </div>

                <div className="col-12 col-md-1 pb-3 title-style d-flex justify-content-center align-items-start">
                    <span className={classNames(
                        'bg-size badge',
                        ` bg-${statusType}`
                    )}>{status}</span></div>
            </div>
        )
    else
        return (null)
}

export default VPlaylistRow
