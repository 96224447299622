import React from 'react'
import EnterEmail from '../Components/EnterEmail'
import { useErrorBoundary } from "react-error-boundary";

const ForgetPassword = () => {
    const { showBoundary } = useErrorBoundary();

    return (
        <EnterEmail catchErrors={(err) => {
            showBoundary(err)
        }} />
    )
}

export default ForgetPassword
