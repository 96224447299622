import React, { useState, useEffect } from 'react'
import '../../../css/RecentVideoList.css'
import RecentVideoRow from './RecentVideoRow'
import RecentVideosLoading from '../../Loaders/RecentVideosLoading'
import axios from 'axios'
import VideoCard from '../Feeds/VideoCard'
import { PropagateLoader } from 'react-spinners'
const env = require('../../../../config.env')

const RecentVideoList = (props) => {
    const [videos, getvideos] = useState('');
    const [height, setHeight] = useState();
    useEffect(() => {
        if (props.Videoid) {
            let req_path = ''
            if (props.playlist_id)
                req_path = `${env.SERVER_REQUEST_PATH}videoplaylistcontent/${props.playlist_id}`
            else
                req_path = `${env.SERVER_REQUEST_PATH}recentVideos/${props.Videoid}`
            let headers = {}
            if (props.is_private)
                headers = { is_private: true }
            axios.get(req_path, { headers: headers })
                .then((response) => {
                    let data = response.data.rows ? response.data.rows : response.data.data
                    getvideos(data)
                }).catch(err => {
                    props.catchErrors(err)
                    console.error(`Error: ${err}`)
                })
        }
    }, [props.Videoid])
    // function setHeightRecentDiv() {
    //     const height1 = document.getElementsByClassName('videoDetails_div')?.[0].offsetHeight;
    //     const height2 = document.getElementsByClassName('videoMaterial_div')?.[0].offsetHeight;
    //     setHeight((height1 + height2) - 10)
    // }
    useEffect(() => {
        // setHeightRecentDiv();

    }, [videos]);

    // if (videos)
    return (
        < div className='video-suggestion-container px-4 pt-4 py-0 w-100'>
            {props.type == 'p' && <h5 className='font-weight-bold mb-0'>Playlist Queue</h5>}
            {props.type != 'p' && <h5 className='font-weight-bold mb-0'>Recent Videos</h5>}
            <div className='v-container'>
                <div className='v-scrolledParent d-flex gap-4 py-4'>
                    {videos &&
                        (videos.map((video) =>
                            // <RecentVideoRow is_private={props.is_private} key={video.id} videoDetails={video} />
                            <VideoCard
                                {...video}
                                is_private={props.is_private}
                                type={props.type}
                                key={video.id}
                            // refreshVideos={() => { props.refreshVideos() }}
                            // catchErrors={(err) => props.catchErrors(err)}
                            />
                        ))}
                    {!videos && <div className='d-flex align-items-center justify-content-center w-100 h-100'>
                        <PropagateLoader className='m-5' color="#B9AFF7" /></div>}
                </div>
            </div>
        </div>
    )
}

export default RecentVideoList
