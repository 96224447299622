import React from 'react'
import '../css/Header.css'
import { useNavigate, NavLink } from 'react-router-dom';
import { Button, Navbar } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
const env = require('../../config.env')

const Header = ({ dark }) => {
    const navigate = useNavigate()
    const navigateHome = () => {
        const token = localStorage.getItem("user-token")
        if (token)
            navigate('/home')
        else
            navigate('/login')
    }
    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        section.scrollIntoView({ behavior: 'smooth' });
    }
    return (

        <header className={`px-3 mb-lg-0 mb-4 mb-md-4 py-2 border border-1 ${dark ? '' : 'header-style-white'} header-style`}>
            <div className="header d-flex justify-content-center w-100">
                <div className="p-0 container-fluid d-flex flex-wrap 
                align-items-center justify-content-center justify-content-lg-start">
                    <Navbar expand="lg" className="d-flex justify-content-between w-100">
                        <Navbar.Brand href="/">
                            <a className="text-center text-lg-start navbar-brand">
                                {dark && <img src={'/assets/pageLogo.png'} alt="logo" className='h-logo-size' />}
                                {/* {dark && <img src={'/assets/whiteLogo.png'} alt="logo" className='h-logo-size' />} */}
                                {!dark && <img src={'/assets/pageLogo.png'} alt="logo" className='h-logo-size' />}
                            </a>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav-landing">
                            <ul className="col-xxl-6 justify-content-center align-items-center nav col-12 col-lg-auto me-lg-auto mb-2 mb-md-0 h-list-style">
                                {/* <li className={`${dark ? 'theme-color-txt' : 'theme-color-txt'} ms-5 fw-bold`} role='button' onClick={() => scrollToSection("howItWorks-container")}>How it Works</li> */}
                                <li className={`${dark ? 'theme-color-txt' : 'theme-color-txt'} ms-5 fw-bold`} role='button' onClick={() => scrollToSection("features-container")}>Features</li>
                                <li className={`${dark ? 'theme-color-txt' : 'theme-color-txt'} ms-5 fw-bold`} role='button' onClick={() => scrollToSection("pricing-container")}>Pricing</li>
                                <li className={`${dark ? 'theme-color-txt' : 'theme-color-txt'} ms-5 fw-bold`} role='button' >Blog</li>
                                {/* <li className="my-0 px-2">
                            <Dropdown >
                                <Dropdown.Toggle variant='Default'>Resources</Dropdown.Toggle>
                                <Dropdown.Menu className='land-drop'>
                                    <Dropdown.Item eventKey={3.1}>Features</Dropdown.Item>
                                    <Dropdown.Item eventKey={3.2}>Vodcast</Dropdown.Item>
                                    <Dropdown.Item eventKey={3.3}>News</Dropdown.Item>
                                    <Dropdown.Item eventKey={3.3}>Vision</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li> */}
                            </ul>
                            <div className='d-flex justify-content-center justify-content-lg-end justify-content-md-end justify-content-xxl-end align-items-center col-md-auto mt-2 ms-md-5 text-end'>
                                <div className='row m-0 p-0'>
                                    <div className='col-md-auto col-7'>
                                        <button onClick={() => {
                                            navigate('/sign-up')
                                        }} className='btn-sign'>Sign Up</button>
                                    </div>
                                    <div className='col-md-auto col-5'>
                                        <button onClick={() => {
                                            navigate('/login')
                                        }} className='mt-lg-0 mt-md-0 ms-md-0 ms-md-5 ms-lg-0 btn-login'>Login</button>
                                    </div>
                                </div>
                            </div>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </div>
        </header>

    )
}

export default Header
