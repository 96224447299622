import React, { useState } from 'react'
import VideoViewer from '../../SharedComponents/js/VideoViewer'
import { Button, Form } from 'react-bootstrap'
import FormInput from '../../SharedComponents/js/FormInput'
import axios from 'axios';
import { FaGift } from 'react-icons/fa';
import { AiOutlineGift } from 'react-icons/ai';

const env = require('../../config.env')

const PayForPublish = (props) => {
    const [total_price, set_totalPrice] = useState(props.VideoData.item_price)
    const [discount, set_discount] = useState(0)
    const [promocode, set_promocode] = useState()
    const [raiseError, setraiseError] = useState(false)
    const [errorMsg, set_errorMsg] = useState('')

    const apply_promocode = (e) => {
        // let headers = {}
        // if (props.is_private)
        //     headers = { is_private: true }
        e.preventDefault()
        axios.post(`${env.SERVER_REQUEST_PATH}validatePromoCode`, { promoCode: promocode })
            .then((response) => {
                let data = response.data
                if (data.dicount > 0) {
                    set_discount(data.dicount)
                    set_totalPrice(props.VideoData.item_price - data.dicount)
                }
                else {
                    set_promocode()
                    setraiseError(true)
                    set_errorMsg(data.message)
                }
            }).catch(err => {
                props.catchErrors(err)
                console.error(`Error: ${err}`)
            })
    }
    const onInputChange = (e) => {
        set_promocode(e.target.value)
        setraiseError(false)
        set_errorMsg('')
    }
    const checkout = async (e) => {
        e.preventDefault()
        let res = await axios.post(`${env.SERVER_REQUEST_PATH}createCheckout`,
            {
                video_id: props.VideoData.video_id,
                username: props.username,
                price: total_price,
                currency: props.VideoData.currency,
                promocode: promocode
            })
        props.close(res.data.url)
    }
    if (props.VideoData)
        return (
            <div className='ms-5 mt-3'>
                {/* <div className='row m-0'>
                    <div className='col-md-12 mb-3'><h4>{props.is_author ? 'Publish your video.' : 'Save your video.'}</h4></div>
                </div> */}
                <div className='pb-3 mx-0 row publish_wrapper'>
                    <div className='col-md-6'>
                        <div className='d-flex flex-column'>
                            <h5><strong>Title</strong></h5>
                            <p>{props.doc_title}</p>
                        </div>
                        <VideoViewer
                            subtitle={props.VideoData.subtitle ? props.VideoData.subtitle : ''}
                            type={`${props.viewer_type == "tokens" ? "play" : "publish"}`}
                            thumb={props.VideoData.thumb_img} link={props.VideoData.video_path} />
                    </div>

                    <div className='col-md-6'>
                        <Form className='d-flex flex-column ' autoComplete="off" onSubmit={checkout}>

                            <h5 className='my-3'><strong>Checkout Details</strong></h5>
                            <div class="alert alert-success alert-dismissible fade show" role="alert">
                                <strong>RTAi24</strong> 100% OFF on Video Generation
                                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                            <div className='mb-3 d-flex align-items-center'>
                                <FormInput
                                    key='PromoCode' name="PromoCode" placeholder='Promo Code'
                                    raiseError={raiseError} errorMsg={errorMsg}
                                    label='' onChange={onInputChange} required=''
                                    className='w-75' />
                                <div className="w-25 d-flex align-items-center" >
                                    <button className="p-2 w-100 reg-button ms-3"
                                        onClick={apply_promocode} >Apply</button>
                                </div>
                            </div>
                            <div className='d-flex flex-column justify-content-end my-4 pb-3'>
                                <div className='my-2 d-flex justify-content-between align-items-center'>
                                    <span className='text-secondary'>Video Price</span>
                                    <span>${props.VideoData.item_price}</span>
                                </div>
                                <div className='my-2 d-flex justify-content-between align-items-center'>
                                    <span className='text-secondary'>Discount</span>
                                    <span>${discount}</span>
                                </div>
                                <div className='my-2 border-top border-1 d-flex justify-content-between align-items-center'>
                                    <strong>Total</strong>
                                    <strong>${total_price}</strong>
                                </div>
                            </div>
                            <div className='mt-5 d-flex align-items-end justify-content-end w-100'>
                                <Button
                                    disabled={props.stop_process ? 'disabled' : ''}
                                    className='w-25 btn btn-gen btn-primary ms-3' type='submit' >
                                    Checkout</Button>
                            </div>
                        </Form>

                    </div>
                </div>
            </div >
        )

}

export default PayForPublish
