import React from 'react';
import { useNavigate } from 'react-router-dom'
import '../../../css/Comment.css'
import { FaUserCircle } from 'react-icons/fa'
const helpers = require('../../../../utilities/helpers')
const env = require('../../../../config.env')

const Comment = ({ commentData }) => {
  const navigate = useNavigate()
  if (commentData)
    return (
      <>
        <div className='comment my-3 px-2 py-2 d-flex align-items-start' id={commentData.comment_id}>
          <div className='d-flex'>
            {commentData.profile_img &&
              <img className='user-com-image'
                src={env.SERVER_REQUEST_PATH + commentData.profile_img} circular="true" />}
            {!commentData.profile_img && <FaUserCircle size={30} color='6e757c' />}
            <div className='d-flex flex-column ms-2'>
              <div className='d-flex justify-content-start align-items-center c-user-details gap-1'>
                <span
                  onClick={() => {
                    navigate(`/profile/${commentData.username}`, { state: commentData.user_id })
                  }}
                  className='link-pointer text-wrap'>
                  {commentData.firstname + ' ' + (commentData.lastname ? commentData.lastname : "")}
                </span>
                <span>•</span>
                <span>{commentData.affiliation}</span>
              </div>

              <span className='c-time'>{helpers.getTimeSinceCreation(commentData.creation_date)}</span>

              <div className='d-flex flex-column w-100'>
                <span className='text-wrap com-text-style'>{commentData.content}</span>
              </div>
            </div>
          </div>

        </div>
      </>
    )
}

export default Comment
