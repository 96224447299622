import React, { useCallback, useEffect, useRef, useState } from 'react'
import axios, { all } from 'axios'
import FormInput from '../../SharedComponents/js/FormInput'
import { Button, Modal } from 'react-bootstrap'
import VideoViewer from '../../SharedComponents/js/VideoViewer'
import { Player } from "react-tuby";
import { useErrorBoundary } from 'react-error-boundary'
import { BsFillPauseBtnFill, BsFillPlayBtnFill, BsSearch } from 'react-icons/bs'
import AudioPlayer from 'react-h5-audio-player';
import { FaCheckSquare } from 'react-icons/fa'
import { AiFillCheckSquare } from 'react-icons/ai'
import { PiMusicNoteFill } from 'react-icons/pi'

const env = require('../../config.env')

const SearchMusicTracks = ({ tracks, showModal, submitSelected, closeModal }) => {
    const [isShow, setisShow] = useState(false)
    // const [musicTracks, set_musicTracks] = useState(tracks)
    const [selected_audio, setSelectedAudio] = useState({ position: 0, row_index: 0 })
    const [played_audio, set_played_audio] = useState()
    const { showBoundary } = useErrorBoundary();
    const is_playing_ref = useRef(false)
    const player_ref = useRef()

    useEffect(() => {
        setisShow(showModal)
    }, [showModal])
    // useEffect(() => {
    //     if (showModal) {
    //         axios.get(env.SERVER_REQUEST_PATH + 'musicTracks')

    //             // axios.post(env.SERVER_REQUEST_PATH + 'summarize_doc', req_sec_data)
    //             .then((response) => {
    //                 let tracks = response.data
    //                 const rows = [];
    //                 for (let i = 0; i < tracks.length; i += 4) {
    //                     const rowNumbers = tracks.slice(i, i + 4);
    //                     const row_figs = []
    //                     rowNumbers.map((r) => {
    //                         let path = r
    //                         let parts = r.split('/')
    //                         let full_name = parts[parts.length - 1]
    //                         let name = full_name.slice(0, -4)
    //                         row_figs.push({
    //                             track_path: env.SERVER_REQUEST_PATH.toString().slice(0, -1) + path,
    //                             track_name: parts[parts.length - 1],
    //                             displayed_name: name
    //                         })

    //                     })
    //                     rows.push(row_figs);
    //                     // intialState.push(row_figs)
    //                 }
    //                 set_musicTracks(rows)
    //             }).catch(err => {
    //                 // handleErrors(err)
    //                 // set_stop_process(true)
    //                 showBoundary(err)
    //             })
    //     }
    // }, [showModal])
    const handlePlay = () => {
        if (player_ref.current) {
            player_ref.current.audio.current.play();
        };
    }
    const handlePause = () => {
        if (player_ref.current) {
            player_ref.current.audio.current.pause();
        }
    }
    const handleClose = () => {
        is_playing_ref.current = false
        handlePause()
        setisShow(false);
        let selected_bg = []

        // checkedState.map((row) => {
        // row.map((card) => {
        // if (checkedState)
        //     selected_bg.push({
        //         fig_id: checkedState.fig_id,
        //         inputResolutionHeight: checkedState.inputResolutionHeight,
        //         inputResolutionWidth: checkedState.inputResolutionWidth,
        //         thumbnail: checkedState.thumbnail,
        //         fig_path: checkedState.fig_path
        //     })
        // // })
        // // })
        closeModal(selected_audio)
        // submitSelected(selected_bg)
        // setcheckedState()
        // setvideos([])
        // videosref.current = null
        // videosRawRef.current = null
        // setsearchword()
    }
    const handleShow = () => setisShow(true);

    let counter = 0
    return (
        <div>
            <Modal className='tracks search-modal' size="lg" show={isShow} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='search-title-container d-flex justify-content-center'>
                        <span className='search-modal-title'>Add music to your video..</span>
                        {/* <span className='f_size_small text-gray-color ms-1'>Up to 3 videos</span> */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={`${isShow ? 'overflow-y-scroll' : 'overflow-y-hidden'} mx-0 mb-0 mt-3 p-0 overflow-x-hidden min-h row row-gap-1 scroll-content`}>
                        {tracks?.map((row, index) => {
                            return (
                                <div className='col-12 row m-0 p-0 row-gap-1 justify-content-center tracks-style' key={index}>
                                    {row.map((card, col_inx) => {
                                        counter++
                                        return (
                                            <div
                                                className={`col-6 col-md-3 px-3 py-3 fig-item`}
                                                key={index + col_inx}
                                                onClick={(e) => {
                                                    setSelectedAudio({
                                                        track_name: card?.track_name,
                                                        track_path: card?.track_path,
                                                        displayed_name: card?.displayed_name,
                                                        row_index: index,
                                                        position: col_inx
                                                    })
                                                }}
                                            >
                                                {/* <div className=''> */}
                                                <div className={`${selected_audio?.row_index == index && selected_audio?.position == col_inx ? "video-checked" : ""} h-auto d-flex justify-content-center size-vid`}
                                                    onClick={(e) => {
                                                        setSelectedAudio({
                                                            track_name: card?.track_name,
                                                            track_path: card?.track_path,
                                                            displayed_name: card.displayed_name,
                                                            row_index: index,
                                                            position: col_inx
                                                        })
                                                    }}>
                                                    <div className='w-100 d-flex flex-column'>
                                                        <div className={`justify-content-end m-1 fig-container ${selected_audio?.row_index == index && selected_audio?.position == col_inx ? 'checked' : 'unchecked'}`}
                                                        >
                                                            <AiFillCheckSquare color='#5644de' size={25} />
                                                        </div>
                                                        <div className="music-icon w-100 d-flex justify-content-center align-items-center p-4">
                                                            <PiMusicNoteFill color='#5644de' /></div>
                                                        <div className='track-details w-100 p-3 d-flex justify-content-start align-items-center'>
                                                            <div> {(!is_playing_ref.current || (is_playing_ref.current &&
                                                                ((played_audio?.row_index == index && played_audio?.position != col_inx)
                                                                    || (played_audio?.row_index != index && played_audio?.position != col_inx)
                                                                    || (played_audio?.row_index != index && played_audio?.position == col_inx)
                                                                )
                                                            ) || !played_audio)
                                                                && <BsFillPlayBtnFill className='me-2' color='#5644de' size={30}
                                                                    onClick={() => {
                                                                        let audio_playing = is_playing_ref.current
                                                                        if (!audio_playing)
                                                                            is_playing_ref.current = !audio_playing
                                                                        set_played_audio({
                                                                            track_path: card.track_path,
                                                                            row_index: index,
                                                                            position: col_inx
                                                                        })
                                                                        handlePlay()
                                                                    }}
                                                                />}
                                                                {is_playing_ref.current && played_audio?.row_index == index
                                                                    && played_audio?.position == col_inx
                                                                    && <BsFillPauseBtnFill className='me-2' color='#5644de' size={30}
                                                                        onClick={() => {
                                                                            let audio_playing = is_playing_ref.current
                                                                            if (audio_playing)
                                                                                is_playing_ref.current = !audio_playing
                                                                            handlePause()
                                                                        }} />}
                                                            </div>
                                                            <span title={card.displayed_name} className='track-title'>{card.displayed_name}</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>)
                        })}
                    </div>
                    <div hidden>
                        <AudioPlayer ref={player_ref}
                            src={played_audio?.track_path}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SearchMusicTracks
