import React from 'react'

const GenerateTerms = () => {
    return (
        <div className='m-2'>

            <h5><strong>General Terms</strong></h5>
            <p>
                By accessing https://www.researchtube.ai, you confirm that you are in agreement with and bound by the terms and services contained in the Terms & Conditions outlined below. These terms apply to the entire website and any email or other type of communication between you and the corporation ResearchTube Technologies Inc. “ResearchTube.Ai”.
                Under no circumstances shall ResearchTube.Ai team be liable for any direct, indirect, special, incidental or consequential damages, including, but not limited to, loss of data or profits arising out of the use, or the inability to use, the materials on this site, even if ResearchTube.Ai team or an authorized representative has been advised of the possibility of such damages. If your use of materials from this site results in the need for servicing, repair or correction of equipment or data, you assume any costs thereof.
                ResearchTube.Ai will not be responsible for any outcome that may occur during the course of usage of our resources. We reserve the rights to change prices and revise the resources usage policy at any moment.
            </p>
            <h5><strong>License</strong></h5>
            <p>
                ResearchTube.Ai grants revocable, non-exclusive, non-transferable, limited license to download, install and use the resources strictly in accordance with the terms of this Agreement.
                These Terms & Conditions are a contract between you and the provider (referred to in these Terms & Conditions as “ResearchTube.Ai”, “us”, “we” or “our”), the provider of the ResearchTube.Ai website and the services accessible from the ResearchTube.Ai website (which is collectively referred to in the Terms & Conditions as the “Third-Party Services”)
                You are agreeing to be bound by these Terms & Conditions. If you do not agree to these Terms & Conditions, please do not use the ResearchTube.Ai Service. In these Terms & Conditions, “you” refers both to you as an individual and to the entity you represent. If you violate any of these Terms & Conditions, we reserve the right to cancel your account or block access to your account without notice.
            </p>
            <h5><strong>Definitions and key terms</strong></h5>
            <p> To help explain things as clearly as possible in this Terms & Conditions, every time any of these terms are referenced, are strictly defined as:

                <ul>
                    <li>Cookie: small amount of data generated by a website and saved by your web browser. It is used to identify your browser, provide analytics, remember information about you such as your language preference or login information</li>
                    <li>Company: when these terms mention “Company, “we”, “us,” or “our,” it refers to ResearchTube.Ai the corporation which is responsible for your information under these Terms & Conditions.</li>
                    <li> Country: The owners/founders of ResearchTube.Ai are based, in this case Canada, Toronto, Ontario.</li>
                    <li> Service: refers to the service provided by ResearchTube.Ai as described in the relative terms (if available) and on this platform.</li>
                    <li>Third-Party service: refers to advertisers, contest sponsors, promotional and marketing partners, and others who provide our content or whose products or services we think may interest you.</li>
                    <li>Website: ResearchTube.Ai’s website, which can be accessed via this URL: https://www.researchtube.ai</li>
                    <li>You: refers to a person or entity that is registered with ResearchTube.Ai and has access to the Services.</li>
                </ul>
            </p>
            <h5><strong>  Restrictions</strong></h5>
            <p> You agree not to, and you will not permit others to:
                <ul>
                    <li>License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose, or otherwise commercially exploit the website https://www.researchtube.ai or make the platform available to any third party.</li>
                    <li>Modify, make derivative works of, disassemble, decrypt, reverse compile or reverse engineer any part of the website https://www.researchtube.ai</li>
                    <li>Remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of or its affiliates, partners, suppliers or the licensors of the website https://www.researchtube.ai</li>
                </ul>
            </p>
            <h5><strong>Payment</strong></h5>
            <p>
                If you register to any of our recurring payment plans, you agree to pay all fees or charges to your account for the Service in accordance with the fees, charges and billing terms in effect at the time that each fee is due and payable. Unless otherwise indicated in an order form, you must provide ResearchTube.Ai with a valid credit card (Visa, MasterCard, or any other issues accepted by us) (“Payment Plan”) as a condition to signing up for the Premium plan. Your Payment Provider agreement governs your use of the designated credit card account, and you must refer to that agreement and not these Terms to determine your rights and liabilities with respect to your Payment Provider. By providing ResearchTube.Ai with your credit card number and associated payment information, you agree that ResearchTube.Ai is authorized to verify information immediately, and subsequently invoice your account for all fees and charges due and payable to ResearchTube.Ai hereunder and that no additional notice or consent is required. You agree to immediately notify
                ResearchTube.Ai of any change in your billing address or the credit card used for payment hereunder.
                ResearchTube.Ai reserves the right at any time to change its prices and billing methods, either immediately upon posting on our Site or by e-mail delivery to your organization’s administrator(s).
                Any attorney fees, court costs, or other costs incurred in collection of delinquent undisputed amounts shall be the responsibility of and paid for by you.
                No contract will exist between you and ResearchTube Technologies Inc. for the Service until “ResearchTube.Ai” accepts you by a confirmatory e-mail. SMS/MMS message, or other appropriate means of communication. You are responsible for any third-party fees that you may incur when using the Service.
            </p>

            <h5><strong> Return and Refund Policy</strong></h5>
            <p>Thanks for shopping at ResearchTube.Ai. We appreciate the fact that you like to buy the stuff we build. We also want to make sure you have a rewarding experience while you’re exploring, evaluating, and purchasing our products.
                As with any shopping experience, there are terms and conditions that apply to transactions at ResearchTube.Ai. We’ll be as brief as our attorneys will allow. The main thing to remember is that by placing an order or making a purchase at ResearchTube.Ai, you agree to the terms along with Research Tube Inc’s Privacy Policy.
                If, for any reason, you are not completely satisfied with any good or service that we provide, don't hesitate to contact us and we will discuss any of the issues you are going through with our product.</p>
            <h5><strong>Your Suggestions</strong></h5>
            <p>Any feedback, comments, ideas, improvements or suggestions (collectively, “Suggestions”) provided by you to ResearchTube.Ai with respect to the Terms & Conditions shall remain the sole and exclusive property of ResearchTube.Ai.
                ResearchTube.Ai shall be free to use, copy, modify, publish, or redistribute the Suggestions for any purpose and in any way without any credit or any compensation to you.</p>
            <h5><strong>Your Consent</strong></h5>
            <p>We’ve updated our Terms & Conditions to provide you with complete transparency into what is being set when you visit our site and how it’s being used. By using our website https://www.researchtube.ai, registering an account, or making a purchase, you hereby consent to our Terms & Conditions.</p>
            <h5><strong>Links to Other Websites</strong></h5>
            <p>These Terms & Conditions apply only to the Services. The Services may contain links to other websites not operated or controlled by ResearchTube.Ai. We are not responsible for the content, accuracy or opinions expressed in such websites, and such websites are not investigated, monitored or checked for accuracy or completeness by us. Please remember that when you use a link to go from the Services to another website, our Terms & Conditions are no longer in effect. Your browsing and interaction on any other website, including those that have a link on our platform, is subject to that website’s own rules and policies. Such third parties may use their own cookies or other methods to collect information about you.</p>
            <h5><strong>Cookies</strong></h5>
            <p>ResearchTube.Ai uses “Cookies” to identify the areas of our website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to enhance the performance and functionality of our website, but it is non-essential. However, without these cookies, certain functionality like videos may become unavailable or you would be required to enter your login details every time you visit the URL: https://www.researchtube.ai as we would not be able to remember that you had logged in previously. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our website correctly or at all. We never place Personally Identifiable Information in Cookies.</p>
            <h5><strong>Changes To Our Terms & Conditions</strong></h5>
            <p>You acknowledge and agree that ResearchTube.Ai may stop (permanently or temporarily) providing the Service (or any features within the Service) to you or to users generally at ResearchTube.Ai’s sole discretion, without prior notice to you. You may stop using the Service at any time. You do not need to specifically inform ResearchTube.Ai when you stop using the Service. You acknowledge and agree that if ResearchTube.Ai disables access to your account, you may be prevented from accessing the Service, your account details or any files or other materials which are contained in your account.
                If we decide to change our Terms & Conditions, we will post those changes on this page, and/or update the Terms & Conditions modification date below.</p>
            <h5><strong>Modifications to Our Website</strong></h5>
            <p>ResearchTube.Ai reserves the right to modify, suspend or discontinue, temporarily or permanently, the website https://www.researchtube.ai or any service to which it connects, with or without notice and without liability to you.</p>

            <h5><strong>Updates to Our Website</strong></h5>
            <p>ResearchTube.Ai may from time to time provide enhancements or improvements to the features/functionality of the website https://www.researchtube.ai, which may include patches, bug fixes, updates, upgrades and other modifications (“Updates”).
                Updates may modify or delete certain features and/or functionalities of the website https://www.researchtube.ai. You agree that ResearchTube.Ai has no obligation to (i) provide and Updates, or (ii) continue to provide or enable any features and/or functionalities of the website https://www.researchtube.ai to you.
                You further agree that all Updates will be (i) deemed to constitute features and/or functionalities of the website https://www.researchtube.ai, and (ii) subject to the terms and conditions of this Agreement</p>
            <h5><strong>Third-Party Services</strong></h5>
            <p>We may display, include or make available third-party content (including data, information, applications and other products services) or provide links to third-party websites or services (“Third-Party Services”).
                You acknowledge and agree that ResearchTube.Ai shall not be responsible for any Third-Party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. ResearchTube.Ai does not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-Party Services.
                Third-Party Services and links thereto are provided solely as a convenience to you, and you access and use them entirely at your own risk and subject to such third parties’ terms and conditions.</p>
            <h5><strong>Term and Termination</strong></h5>
            <p>This Agreement shall remain in effect until terminated by you or ResearchTube.Ai. ResearchTube.Ai may, in its sole discretion, at any time and for any or no reason, suspend or terminate this Agreement with or without prior notice.
                This Agreement will terminate immediately, without prior notice from ResearchTube.Ai, in the event that you fail to comply with any provision of this Agreement. You may also terminate this Agreement by deleting the account you made at https://www.researchtube.ai and all copies thereof from your computer.
                Upon termination of this Agreement, you shall cease all use of the website https://www.researchtube.ai and delete all copies of the website https://www.researchtube.ai from your computer
                Termination of this Agreement will not limit any of ResearchTube.Ai’s rights or remedies at law or in equity in case of breach by you (during the term of this Agreement) of any of your obligations under the present Agreement.</p>

            <h5><strong>  Copyright Infringement Notice</strong></h5>
            <p> If you are a copyright owner or such owner’s agent and believe and material on our website https://www.researchtube.ai constitutes an infringement on your copyright, please contact us setting forth the following information: (a) a physical or electronic signature of the copyright owner or a person authorized to act on his/hers behalf; (b) identification of the material that is claimed to be infringing; (c) your contact information, including address, telephone number, and an email; (d) a statement by you that you have a good faith/belief that use of the material is not authorized by the copyright owners; and (e) a statement that the information in the notification is accurate, and, under penalty of perjury you are authorized to act on behalf of the owner.</p>
            <h5><strong>Indemnification</strong></h5>
            <p> You agree to indemnify and hold ResearchTube.Ai and its parents, subsidiaries, affiliates, officers, employees, agents, partners and licensors (if any) harmless from any claim or demand, including reasonable attorneys’ fees, due to or arising out of your: (a) use of the website https://www.researchtube.ai; (b) violation of this Agreement or any law or regulation; or (c) violation of any right of a third party.</p>
            <h5><strong> No Warranties</strong></h5>
            <p> The website https://www.researchtube.ai is provided to you “AS IS” and “AS AVAILABLE” and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the website ResearchTube.Ai, on its own behalf and on behalf of its affiliates, respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the website https://www.researchtube.ai, including all implied warranties of merchantability, fitness for particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, ResearchTube.Ai provides no warranty or undertaking, and makes no representation of any kind that the website https://www.researchtube.ai will meet your requirements, achieve any intended results, be compatible or work with any other software, websites, systems or services, operate without interruption, meet any performance reliability standards or be error free or that any errors or defects can or will be corrected.
                Without limiting the foregoing, neither ResearchTube.Ai nor any ResearchTube.Ai provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the website https://www.researchtube.ai, or the information, content, and materials or products included thereon; (ii) that the website https://www.researchtube.ai will be uninterrupted and error free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the website https://www.researchtube.ai; or (iv) that the website https://www.researchtube.ai, its servers, the content, or emails sent from or on half of the ResearchTube.Ai team are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.
                Some jurisdictions do not allow the exclusion of or limitations on implied warranties or the limitations on the applicable statutory rights of a consumer, so some or all the above exclusions and limitations may not apply to you.</p>

            <h5><strong> Limitation of Liability</strong></h5>
            <p> To the maximum extent permitted by applicable law, in no event shall ResearchTube.Ai or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, for loss of data or other information, for business interruption, for personal injury, for loss of privacy arising out of or in any way related to the use of or inability to use the website https://www.researchtube.ai, third-party software and/or third-party hardware used with the website https://www.researchtube.ai, or otherwise in connection with any provision of this Agreement), even if https://www.researchtube.ai or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.
                Some provinces/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you.</p>
            <h5><strong>  Severability</strong></h5>
            <p>  If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.
                This Agreement, together with the Privacy Policy and any other legal notices published by ResearchTube.Ai on the Services, shall constitute the entire agreement between you and a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of this Agreement, which shall remain in full force and effect. No waiver of any term of this Agreement shall be deemed a further or continuing waiver of such term or any other term, and ResearchTube.Ai’s failure to assert any right or provision under this Agreement shall not constitute a waiver of such right or provision. YOU AND ResearchTube.Ai AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CLAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY BARRED.</p>
            <h5><strong> Waiver</strong></h5>
            <p> Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Agreement shall not affect a party’s ability to exercise such right or require such
                performance at any time thereafter nor shall the waiver of a breach constitute waiver of any subsequent breach.
                No failure to exercise, and no delay in exercising, on the part of either party, any right or any power under this Agreement shall operate as a waiver of that right or power. Nor shall any single or partial exercise of any right or power under this Agreement preclude further exercise of that or any other right granted herein. In the event of a conflict between this Agreement and any applicable purchase or other terms, the terms of this Agreement shall govern.</p>

            <h5><strong> Amendments to this Agreement</strong></h5>
            <p> ResearchTube.Ai reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material, we will provide at least 30 days’ notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
                By continuing to access or use our website https://www.researchtube.ai after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use https://www.researchtube.ai.</p>
            <h5><strong> Entire Agreement</strong></h5>
            <p> The Agreement constitutes the entire agreement between you and ResearchTube.Ai regarding your use of the website https://www.researchtube.ai and supersedes all prior and contemporaneous written or oral agreements between you and ResearchTube.Ai.
                You may be subject to additional terms and conditions that apply when you use or purchase other services offered by https://www.researchtube.ai services, which ResearchTube.Ai will provide to you at the time of such use or purchase.</p>
            <h5><strong>  Updates to Our Terms:</strong></h5>
            <p> We may change our Service and policies, and we may need to make changes to these Terms so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) before we make changes to these Terms and give you an opportunity to review them before they go into effect. Then, if you continue to use the Service, you will be bound by the updated Terms. If you do not want to agree to these or any updated Terms, you can delete your account.</p>
            <h5><strong> Intellectual Property</strong></h5>
            <p> The website https://www.researchtube.ai and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by ResearchTube.Ai, its licensors or other providers of such material are protected by ResearchTube Technologies Inc “ResearchTube.Ai” and
                international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws. The material may not be copied, modified, reproduced, downloaded or distributed in any way, in whole or in part, without the express prior written permission of ResearchTube.Ai, unless and except as is expressly provided in these Terms & Conditions. Any unauthorized use of material is prohibited.</p>
            <h5><strong> Agreement to Arbitrate</strong></h5>
            <p> This section applies to any dispute EXCEPT IT DOESN'T INCLUDE A DISPUTE RELATING TO CLAIMS FOR INJUNCTIVE OR EQUITABLE RELIEF REGARDING THE ENFORCEMENT OR VALIDITY OF YOUR OR ResearchTube.Ai’s INTELLECTUAL PROPERTY RIGHTS. The term “dispute" means any dispute, action, or other controversy between you and ResearchTube.Ai concerning the Services of this agreement, whether in contact, warranty, tort, statute, regulation, ordinance, or any other legal or equitable basis. “Dispute” will be given the broadest possible meaning allowable under law.</p>
            <h5><strong> Notice of Dispute</strong></h5>
            <p> In the event of a dispute, you or ResearchTube.Ai must give the other entity a Notice of Dispute, which is a written statement that sets forth the name, address, and contact information of the party giving it, the facts giving rise to the dispute, and the relief requested. You must send any Notice of Dispute via email to: ResearchTube.Ai will send any Notice of Dispute to you by mail to your address if we have it, or otherwise to your email address. You and a representative of ResearchTube Technologies Inc. will attempt to resolve any dispute through informal negotiation within sixty (60) days from the date the Notice of Dispute is sent. After sixty (60) days, you or ResearchTube.Ai may commence arbitration.</p>
            <h5><strong>  Binding Arbitration</strong></h5>
            <p> If you and a ResearchTube.Ai representative are unable to resolve any dispute by informal negotiation. Any other to resolve the dispute will be conducted exclusively by binding arbitration as described in this section. You are giving up the right to litigate (or participate in as a party or class member) all disputes in court before a judge or jury. The dispute shall be settled by binding arbitration in accordance with the commercial arbitration rules of the Canadian Arbitration Association. Either party may seek any interim or preliminary injunctive relief from any court of competent jurisdiction, as necessary to protect the party’s rights or property pending the completion of arbitration. Any and all legal, accounting, and other costs, fees, and expenses incurred by the prevailing party shall be borne by the non-prevailing party.</p>
            <h5><strong>  Submissions and Privacy</strong></h5>
            <p> In the event that you submit or post any ideas, creative suggestions, designs, photograph, information, advertisements, data or proposals, including ideas for new or improved services, features, technologies or promotions, you expressly agree that such submission will automatically be treated as non-confidential and non-proprietary and will become the sole property of
                ResearchTube.Ai without any compensation or credit to you whatsoever. ResearchTube.Ai and its affiliates shall have no obligations with respect to such submissions or posts any may use the ideas contained in such submissions or posts for any purposes in any medium in perpetuity, including, but not limited to, developing, manufacturing, and marketing products and services using such ideas.</p>
            <h5><strong>  Promotions</strong></h5>
            <p> ResearchTube.Ai may, from time to time, include contests, promotions, sweepstakes, or other activities (“Promotions”) that require you to submit material or information concerning yourself. Please note that all Promotions may be governed by separate rules that may contain certain eligibility requirements, such as restrictions as to age and geographic location. You are responsible to read all Promotions rules to determine whether or not you are eligible to participate. If you enter any Promotion, you agree to abide by and to comply with all Promotions Rules.
                Additional terms and conditions may apply to purchases of goods or services on or through the Services, which terms and conditions are made a part of this Agreement by this reference.</p>
            <h5><strong>  Typographical Error</strong></h5>
            <p> In the event a product and/or service is listed at an incorrect price or with incorrect information due to typographical error, we shall have the right to refuse or cancel any orders placed for the product and/or service listed at the incorrect price. We shall have the right to refuse or cancel any such order whether or not the order has been confirmed and your credit card charged. If your credit card has already been charged for the purchase and your order is canceled, we shall immediately issue a credit to your credit card account or other payment account in the amount of the charge.</p>
            <h5><strong> Miscellaneous</strong></h5>
            <p> If for any reason a court of competent jurisdiction finds any provision or portion of these Terms & Conditions to be unenforceable, the remainder of these Terms & Conditions will continue in full force and effect. Any waiver of any provision of these Terms & Conditions will be effective only if in writing and signed by an authorized representative of ResearchTube.Ai. ResearchTube.Ai will be entitled to injunctive or other equitable relief (without obligations of posting any bond or surety) in the event of any breach or anticipatory breach by you. ResearchTube.Ai operates control the https://www.researchtube.ai Services from its offices in _____. The Service is not intended for distribution to or use by and person or entity in any province or country where such distribution or use is contrary to law or regulation. Accordingly, those persons who choose to access the https://www.researchtube.ai Service from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable. These Terms & Conditions (which include and incorporate the ResearchTube.Ai Privacy Policy) contains the entire understanding, and supersedes all prior understandings, between you and ResearchTube.Ai concerning its subject matter and cannot be changed or modified by you. The
                section headings used in this Agreement are for convenience only and will not be given any legal import.</p>
            <h5><strong>  Disclaimer</strong></h5>
            <p> ResearchTube.Ai does not provide any warranties or guaranties
                In no event shall ResearchTube.Ai be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, that arise from using https://www.researchtube.ai or accessing the contents of the website. ResearchTube Technologies Inc. reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice.
                <ul><li>"The website https://www.researchtube.ai and its contents are provided 'as is' and 'as available' without any warranty or representations of any kind, whether express or implied. ResearchTube.Ai acts as a distributor and not a publisher of the content supplied by third parties. Therefore, ResearchTube.Ai does not exercise editorial control over such content and makes no warranty or representation regarding the accuracy or reliability of any third-party information, content, service, or merchandise provided through or accessible via the website https://www.researchtube.ai.</li>
                    <li>ResearchTube.Ai specifically disclaims all warranties and representations related to any content transmitted on or in connection with the website https://www.researchtube.ai, including without limitation, warranties of merchantability or non-infringement of third-party rights. Neither oral advice nor written information given by ResearchTube.Ai or any of its affiliates, employees, officers, directors, agents, or similar entities will create a warranty.</li>
                    <li>Price and availability information is subject to change without notice. Additionally, ResearchTube.Ai does not warrant that the website https://www.researchtube.ai will be uninterrupted, uncorrupted, timely, or error-free."</li>
                </ul>
            </p>
            <h5><strong> Contact Us</strong></h5>
            <p> <ul>
                <li>Via Email: contact@researchtube.ai</li>
                <li>Via Mail: 55 St. George Street, Suite 620 Toronto, ON M5S 1A4, Canada</li>
            </ul>
            </p>
            <h5><strong> Copyright</strong></h5>
            <p> <strong> General Principles</strong></p>
            <p>
                Although publication policies differ, there are some assumptions which can be established. Frequently, there are various adaptations of an article, such as:
                <ul>
                    <li>A preprint, which is submitted for publishing or peer review as it is the final version</li>
                    <li>An approved manuscript, which is a post-peer-review version that has been accepted but has not been published</li>
                    <li>Version of Record (VOR) which is often known as the published version
                        Before uploading the completed piece, it is crucial to verify the terms with the appropriate publication because publishers can define these versions quite differently. Numerous publishers forbid the VOR from ever being disclosed to the public. Despite the fact that you wrote the piece, this is still accurate. Preprints and approved papers are occasionally subjected to separate regulations. These can only be shared by authors with selected publications or during a specific time limit. Approved manuscripts, preprints, as well as VORs might occasionally be subject to "embargo" which are restrictions that prevent you from sharing them until a specific time has passed.
                        You might be able to share your work confidentially with other individuals under certain licenses. Your license agreement may clarify these rights, and it frequently specifies that they must be granted to a colleague, another researcher, or group. It's crucial to be aware of your rights before disclosing your entire texts to others, even with private sharing.
                        Respecting copyrights is important. Publishers have the authority to file a report alleging copyright infringement if you publish or distribute content in a way that violates their rights. This report may require that the information be deleted, or it might demand another course of action. ResearchTube.Ai shall abide by the request of a copyright or license owner who files a report that complies with international law. If the infringement occurs repeatedly, ResearchTube.Ai might be compelled to impose limits or terminate your account.</li>
                </ul>
            </p>
            <h5><strong> Copyright Infringement</strong></h5>
            <p> <strong> What should I do if an individual uses my copyright without my permission on ResearchTube.Ai?</strong></p>

            <p> When we get notice from a legitimate copyright owner, we establish a procedure for blocking accessibility to any purportedly infringing information. We need to suggest for you to get in touch with the owner of the copyright and ask them to send in the report of alleged infringement if you are not the copyright owner or someone authorized to take action on behalf of them.
                If you are the owner of a copyright, US, European, as well as other legal requirements specify that the report of copyright infringement must adhere to a particular layout. Filling out our Notice of Claim Copyright Infringement form and then sending it to copyright@researchtube.ai is the most straightforward method to do so. It gives us the necessary signature as well as company details which we'll retain on record. If you prefer not to utilize our form, you may write an email or utilize a form of your own as long as it contains all the information necessary by law.
            </p>
            <p><strong> What should I do if an individual plagiarizes what I've written?</strong></p>
            <p>The word "plagiarism" has several various definitions, but it typically refers to the act of duplicating another person's work without their consent. It can constitute a copyright infringement when an individual has duplicated your work—in whole or in part—without your consent and you possess the rights to it. If you are the owner of the copyright and you think your rights have been violated, you can report the information and ask for ResearchTube.Ai to take it down. In order to do this, you must send
                in a notice of asserted infringement in the manner outlined previously. If a publisher has been assigned your copyright interests, you might require the publisher to notify us of any violations.
                Be aware that although copying concepts and ideas may be immoral or wrong, it typically isn't punishable by law.
            </p>
            <p><strong>
                Without my consent, my co-author published our collaborative work. What should I do? </strong></p>
            <p>We advise that you contact your co-author first to try to fix the situation if you have concerns that they may have published your collaborative work without your consent. Solutions that might be available if you can't sort things out with them rely on the rights to the content.
                Although we are unable to give you legal counsel, national laws frequently permit both owners of a copyright to offer non-exclusive licenses to the content without asking their co-authors' approval. This can imply that the co-author has the authority to post the material to ResearchTube.Ai without your consent according to the regulations that pertain to you and your research. It can be a violation of the publisher's rights if, on the contrary, the writers and a publisher entered into a contract under which the publisher was granted copyrights in the source material.
                You must ascertain what any relevant laws in your situation indicate. You may file a notice of alleged infringement of copyright as previously described if you come to the conclusion that copyright infringement has taken place and are willing to verify in a sworn declaration that you have an honest belief that the publication of the material violates your rights. Please understand, nevertheless, that reporting a copyright violation is a severe concern. You might want to get legal advice if you're not sure if you're entitled to the legal authority to accomplish anything.
            </p>

            <p><strong>  What happens if a copyright violation notice involves my written work?</strong></p>
            <p>Publishers along with other material owners' copyright rights are respected by ResearchTube.Ai. We take down the reported material if the owner of the rights notifies us of alleged copyright violations in compliance with our guidelines and international laws. Failure to do so can subject us as well as you to legal liability. If this occurs, we'll notify you to let you know what we're going to do.
                Additionally, we must follow the law's mandate and suspend persistent violators. That implies that we maintain a record of each time material is removed. Further infractions could end up in additional measures being taken against your account, such as a permanent ban on it.
                Keep in understanding that owners of copyright have the authority to lodge complaints at any point regarding allegedly violating material that you might have already uploaded.This means that even though you haven't posted anything new since your prior violation, you may end up becoming an ongoing violator. It is crucial that you check all material you post to ResearchTube.Ai and make certain you're granted permission to distribute it.
            </p >


            <p><strong> Does ResearchTube.Ai concur with the publisher if ResearchTube.Ai deletes my content in response to a complaint of alleged violations ?</strong></p>
            <p>   No.ResearchTube.Ai is unaware of any intellectual property rights that may be related to your specific work and is also unaware of any licensing arrangements you could have with publishers.As a result, we cannot assess whether notices of suspected violations are accurate.We take down the determined material if an owner of the rights notifies us of alleged copyright violations in compliance with our policies and international regulations.Failing to do so can subject us along with you to legal risk.If this occurs, we'll send an email to you to let you know what we're going to do.</p>


            <p><strong>An infringement warning was sent to me.Do I have to take any sort of action ?</strong></p>
            <p>  Should you get a message informing you that one of the owners of the rights has found your work to be violating their copyright, the material has already been taken down.You don't need to take any immediate measures.
                Nevertheless, we do advise that you examine material that you published and confirm that you are authorized to distribute it.regardless of whether you haven't posted something new following the previous violation, you risk becoming a repeat offender since rights holders may file complaints of alleged copyright violation at any moment. Your account can be immediately terminated if you receive more copyright warnings.
                Prior to uploading any new material, ensure that you are authorized to do so.We advise you to initially speak with your publishers if you are uncertain of what you are permitted to discuss on ResearchTube.Ai.
                Whilst you consider your rights, you might choose to maintain your current information confidential or perhaps delete it entirely.

            </p>
            <p> <strong>An infringement warning was sent to me.What will happen to my account ?</strong></p >
            <p> We maintain a record for every infringement warning you acquire on the account you use.We require this data in order to administer our recurrent offender policy, which is mandated by law.
                Additional violations could lead to measures being taken against your account, which may involve immediately locking it.While you consider your rights, you might choose to keep your current information private or perhaps delete it entirely.
            </p>
            <p> <strong>I don't agree with my infringement warning. What should I do?</strong></p >
            <p> The validity of the statements stated cannot be and is not independently evaluated by ResearchTube.Ai.We are unaware of any legal obligations you might have toward your material, including any license agreements or additional limitations.We and you may be held legally responsible if we fail to delete the information.Nevertheless, we acknowledge that occasionally material reporting by owners of rights can be inaccurate.You need to speak with the party who filed the complaint promptly if you think that accessibility to your material shouldn't have been disabled or that it was mistakenly taken down. In our notification of infringement, we typically give you their email for getting in touch with them. Overall, you can urge the rights holder to email us promptly and withdraw their claim of infringement if they accept that a mistake was made. Then, we can provide you the option for submitting the files again.</p>


            <p> <strong>My account is locked.What should I do?</strong></p >
            <p>      The law dictates that we have to implement a repeat offender policy.As per our policy, accounts may be locked under certain conditions.
                Unless an error has occurred, if your user account has become permanently disabled owing to repeated infringements of copyright, this measure is typically final.We encourage you to get in touch with the owner of the rights who reported your material if you believe that an error was made over a prior deletion of such content.The relevant notice of violation has an email; nevertheless, if you are not able to locate it, kindly inform us.You can request that the notification of infringement be retracted by the rights holder directly via email if they recognize the error they made.In some circumstances, we might be capable of deleting the violation from your account and allow you to upload the material once more.
            </p>

            <p> <strong>Is ResearchTube.Ai a place where I may share or save my research ?</strong></p>
            <p>ResearchTube.Ai is unable to give you guidance regarding copyright or which, if any, limitations on copyright could be applicable to your material.Nevertheless, we still have the ability to offer a few general points that we believe will prove useful.
                The rights that you have to the material will generally influence the ability to post your research on ResearchTube.Ai and might be governed by a contract you maintain with your publishers.
            </p>
            <p> <strong>Who can access the materials I post to ResearchTube.Ai ?</strong></p>
            <p>
                You have the option to keep material privately or openly when you submit it to your ResearchTube.Ai.Anyone can access openly available articles, no matter whether they have a ResearchTube.Ai account or have been signed in to the website.The only individuals who can see your material if you decide to store it privately are you and the other authors.
            </p>

            <p> <strong>Can I make my work available to the public on ResearchTube.Ai ?</strong></p>
            <p> Depending on the rights that you have in the material, you may or may not be able to make it public on ResearchTube.Ai.If you have released your work in a journal or book, a license arrangement between you and the publisher of the work is most likely what governs the rights you have.There are countless journals, and each one has a unique style of license arrangement.ResearchTube.Ai lacks the ability to access this data.Depending on the type of the material you intend to post, you may not have the right to publish it publicly.While many journals limit the publishing of the complete published version, often known as the version of record(VOR), certain journals permit publishing of non - final versions such as drafts, preprints, and approved articles.
                Any contract you may have with the publishers, or any owner of rights should serve as a foundation for determining your choices and rights.To find out which guidelines pertain to your material, go over it.Numerous publications also provide useful instructions regarding their content usage and distribution policies.We advise you to contact ResearchTube.Ai if you have questions regarding what you are permitted to share there.

            </p>
            < p > <strong>Can I distribute my work to other individuals confidentially ?</strong></p >
            <p>
                Members of ResearchTube.Ai can exchange material.Content sharing is not done instantly.Before sharing any particular material with a specific person, every writer needs to individually determine if they are in possession of the required permissions.Certain publishers' policies provide a limited amount of private sharing under predetermined boundaries. To find out the precise limitations that pertain to you and your content, however, you must consult the license agreement or get in touch with your publisher.
            </p>
            <p> <strong>What can I do if I'm uncertain of my rights?</strong></p >
            <p>   You must confirm appropriate license agreements you have entered into with your publisher and regulations they might have posted on their web page if you have any doubts regarding your rights to distribute material on ResearchTube.Ai.We advise you to contact them personally if you have any further queries.
                We advise making the material private or removing it if you've previously made it publicly accessible while you investigate your legal options. You might be permitted to privately maintain a copy on ResearchTube.Ai or distribute another version of the material, such as an approved manuscript. If you have a contract with your publisher, it will also be a factor.

            </p>
            <p> <strong>Is it permitted to post my unreleased research on ResearchTube.Ai ?</strong></p >
            <p>
                You make your research accessible to all ResearchTube.Ai users by submitting and making it publicly visible on the website.This includes both ResearchTube.Ai members who are logged in and site visitors who are not registered.You are not required to give up any rights to ownership in that research in order to use ResearchTube.Ai.ResearchTube.Ai offers you the option to choose a specific license that pertains to certain categories of material.
            </p>
            <p> <strong>Is it possible that if my article is publicly available someplace else online, I can freely distribute it on ResearchTube.Ai ?</strong></p >
            <p>
                The fact that your article is publicly available someplace else on the internet does not necessarily imply that you have the authority to distribute it on ResearchTube.Ai.Prior to sharing the material on ResearchTube.Ai, you must conduct a separate evaluation to determine if you have enough authority for doing so publicly or privately.
            </p>
            <p> <strong>Can I publish a link to another website online where my whole text can be accessed rather than uploading it ?</strong></p >
            <p> A link that directs readers to the journal's or publisher's own website cannot be used on a publishing page.
            </p>
            < h5 > <strong>Rights to and accountability for ResearchTube.Ai content</strong></h5 >


            <p><strong>Who owns the rights to the material published on ResearchTube.Ai ?</strong></p>
            <p> You make your research publicly accessible to all ResearchTube.Ai users by uploading it and rendering it visible to everyone on the website.This includes both ResearchTube.Ai members who are logged in and site visitors who are not registered.You are not required to give up any right of ownership in your work in order to use ResearchTube.Ai Neither do you give us a copyright transfer or assignment of copyright.Whatever material you've uploaded to your ResearchTube.Ai profile may be removed at any moment by you. Copyright regulations in numerous jurisdictions and any license arrangements you might have signed govern who owns the copyright to the material.

            </p>
            < p > <strong>Is ResearchTube.Ai a publisher ?</strong></p >
            <p>   ResearchTube.Ai is unable to consider articles or papers for publishing because it is not a publisher.Instead, if they've obtained the necessary permissions, users can make their released or unreleased research accessible on ResearchTube.Ai, monitor the works they have published, and maintain personal copies of it.
            </p>

            < p > <strong>Can ResearchTube.Ai grant me authorization to reuse material I find there ?</strong></p >
            <p>   No.Any articles on ResearchTube.Ai are not protected by the system's copyright. Therefore, we are unable to provide you authorization for reusing any material you discover in a paper that has been rendered publicly available on ResearchTube.Ai. You must get in contact with the writers of the work, the journal where it appeared, or the appropriate publication company in order to submit a copyright authorization request. Numerous creators have details regarding how their material may be utilized on their websites, along with a contact email for inquiries about using their material.
            </p>

            < p > <strong>Who is accountable for the content that is posted on ResearchTube.Ai ?</strong></p >
            <p>  Any material that you publish to ResearchTube.Ai is your responsibility.That is the reason why we show your full name alongside any material you post.
                We depend on you to be aware of the rights you have and to exercise them appropriately because we are unaware of any license conditions or additional limitations that may be applicable to your material or any rights you may possess.This is why we ask that prior to posting any material on ResearchTube(both privately and publicly), you thoroughly research and ensure that you have all necessary permissions to do so.We advise you to verify your license arrangement and get in touch with the publisher if you're unsure about your rights to submit material.

            </p>
            <h5> <strong>Community</strong></h5>

            <p> <strong>How to block, report, or unfollow a researcher ?</strong></p >
            <p> You may unfollow a researcher should you simply want to view less of the content they share on ResearchTube.Ai.You may block a researcher if you'd like to ensure they aren't included in your followers, aren't able to communicate you, and aren't able to view what you are posting.Utilize our reporting tool to file an issue about a researcher who is disobeying our community's guidelines or terms of service.
                Kindly report anything published or provided by a researcher to us directly from the material

            </p>
            < p > <strong>How do I unfollow someone on ResearchTube.Ai ?</strong></p >
            <p> To view less of a researcher's activity on ResearchTube.Ai, you may unfollow them.
                This is how:
                <ol>
                    <li>
                        Navigate to the researcher's profile that you would like to unfollow.
                    </li>
                    <li>
                        If you want to follow someone, select the following icon located on their profile's right side.
                    </li>
                </ol>
            </p>
            < p > <strong>How may I inform ResearchTube.Ai regarding the passing of a researcher ?</strong></p >
            <p>  Kindly utilize our contact form to get in touch with us if you wish to inform our team that a ResearchTube.Ai user has passed away.Choose "Accounts and profiles" and then "I'm reporting the profile of a deceased researcher." To ensure that we can verify, we'll need some additional details from you:
                Name of the researcher
                Their ResearchTube.Ai profile's URL
                A notice on their institution's page or a hyperlink to their memorial.
                Promptly after we can verify the details, we'll delete the researcher's personal contact details off their profile and stop including them in any subsequent email notifications that you and your coworkers may obtain.You may still observe them showing up in your emails if their work is published after they have passed.
            </p>
        </div >
    )
}

export default GenerateTerms
