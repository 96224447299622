import { Button, Icon, Form, Image, Input } from "semantic-ui-react";
import '../../../css/PostComment.css'
import axios from 'axios'
import React, { useEffect, useState } from "react";
const env = require('../../../../config.env')

const PostComment = (props) => {
  const [commentText, setCommentText] = useState('')
  const [videoId, setvideo_id] = useState('')
  useEffect(() => {
    setvideo_id(props.video_id)
  }, [props.video_id])
  const post = (e) => {
    let headers = {}
    if (props.is_private)
      headers = { is_private: true }
    let data = { content: commentText, video_id: videoId }
    axios.post(env.SERVER_REQUEST_PATH + 'postComment', data, { headers: headers })
      .then((response) => {
        let status = response.status
        if (status == 200) {
          e.target.reset()
          props.refreshComments()
        }

      }).catch(err => {
        props.catchErrors(err)
        console.error(`Error: ${err}`)
      })
  }
  const onchangeText = (e) => {
    setCommentText(e.target.value)
  }
  return (
    <>
      <div className="row my-3">
        <div className='add-comment col-md-8 col-12'>
          <Form autocomplete="off" className="d-flex justify-content-between gap-3" onSubmit={post}>
            <div className="w-100">
              <Form.Input control={Input} autoHeight placeholder='Add your comment here..'
                onKeyDown={(event) => {
                  event.stopPropagation();
                }}
                onChange={onchangeText} /></div>
            <div>
              <Button className='btn btn-style btn-primary'>Post</Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  )
}

export default PostComment
