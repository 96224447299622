import React, { useEffect, useState } from 'react';
import axios from 'axios'
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FaVideo } from 'react-icons/fa';
const env = require('../../config.env')

const CachedVPImage = ({ imageUrl }) => {
    const [imageSrc, setImageSrc] = useState('');

    // useEffect(() => {
    //     let headers = { mediaUrl: imageUrl }
    //     axios.get(`${env.SERVER_REQUEST_PATH}getMedia`, { headers: headers })
    //         .then(response => {
    //             const objectURL = response.data.media//URL.createObjectURL(blob);
    //             setImageSrc(objectURL);
    //         }).catch(err => {
    //             // console.log(err)
    //         })
    // }, [imageUrl]);

    return (
        <LazyLoadImage className='poster-img position-relative'
            width={200} height={100}
            src={imageUrl ? env.SERVER_REQUEST_PATH + imageUrl : env.SERVER_MEDIA_PATH + 'defaultvideoThumb.png'}
        // src={imageSrc ? `data:image/*;base64,${imageSrc}` : env.SERVER_MEDIA_PATH + 'defaultvideoThumb.png'}
        />
        // <>
        //     {imageSrc && <LazyLoadImage className='poster-img position-relative'
        //         width={200} height={100}
        //         src={`data:image/*;base64,${imageSrc}`}
        //     />}
        //     {!imageSrc &&
        //         <div className='d-flex align-items-center justify-content-center poster-empty'>
        //             <FaVideo color='#B9AFF7' size={25} />
        //         </div>
        //     }
        // </>
    )


};

export default CachedVPImage;
