import React from 'react'
import PlaylistThumb from '../AudioPlaylistThumb'
import { NavLink, useNavigate } from 'react-router-dom'
import '../../../css/Playlist.css'
const env = require('../../../../config.env')
const AudioPlaylistCard = (props) => {
    const navigate = useNavigate()
    return (
        <div
            className="playlistCard">
            <div
                className="playlist-thumbnail">
                <PlaylistThumb is_private={props.is_private}
                    playlist={props.playlist} playlist_id={props.playlist.id} thumb={props.playlist.thumb_url} />
            </div>
            <div>
                <div className='d-flex w-100'>
                    <div class="playlistTitle me-1" >
                        <NavLink title={props.playlist.title}
                            to={`/playlist/${props.playlist.id}`}
                            state={{ playlist: props.playlist, is_private: false }}>{props.playlist.title}
                        </NavLink>
                    </div>
                    {/* <p className='mt-3 me-1 save-style' onClick={onSave}>
                        {saved && <BsBookmarkFill color={`${saved ? 'red' : ''}`} />}
                        {!saved && <BsBookmark color='#999999' />}
                    </p> */}

                </div>

                <p className='authorRow mt-2'>
                    <div className='d-flex align-items-center'>
                        <img className='user-image' src={env.SERVER_REQUEST_PATH + props.playlist.profile_img} circular="true" />
                        {'  '} <span className='link-pointer username'
                            onClick={() => {
                                navigate(`/profile/${props.playlist.username}`, { state: props.playlist.user_id })
                            }}
                        >{props.playlist.username}</span>
                        {/* <div className='ms-2'><span className="d-flex align-items-center videoDate">{
                            helper.getTimeSinceCreation(props.creation_date)}
                        </span></div> */}
                    </div>
                    {/* <p className='w-100 mt-2 stats-font d-flex'>
                        <p className='col-md-9 d-flex justify-content-space-between'>
                            <p className="me-3"><BsChat color='#999999' />{helper.kFormatter(props.no_comments)}</p>
                            <p className="me-3"><BiLike color='#999999' />{helper.kFormatter(props.no_likes)}</p>
                        </p>
                        <p className='col-md-3 d-flex justify-content-end'>
                            <p className="me-1"><BsEye color='#999999' />{helper.kFormatter(props.no_views)}</p>
                        </p>
                    </p> */}
                </p>
            </div>

        </div >
    )
}

export default AudioPlaylistCard
