import '../../../css/VideoCard.css'
import { NavLink, useNavigate } from 'react-router-dom'
import { BsBookmark, BsBookmarkFill, BsChat, BsEye } from 'react-icons/bs';
import { BiLike } from 'react-icons/bi';
import React, { useEffect, useState } from 'react';
import axios from 'axios'
import VPlaylistThumb from '../VPlaylistThumb';
const env = require('../../../../config.env')
const helper = require('../../../../utilities/helpers')

function VPlaylistCard(props) {
    const [video_id, setVideo_id] = useState(props.id)
    const navigate = useNavigate()
    const [saved, setsaved] = useState(false)
    useEffect(() => {
        setsaved(props.saved ? true : false)
    }, [])
    const onSave = () => {
        let headers = {}
        if (props.is_private)
            headers = { is_private: true }
        axios.post(`${env.SERVER_REQUEST_PATH}save`, { video_id: video_id, saved: !saved }, { headers: headers })
            .then((response) => {
                setsaved((prev) => !prev)
                props.refreshVideos(props.type)
            }).catch(err => {
                props.catchErrors(err)
                console.error(`Error: ${err}`)
            })

    }
    return (
        <div
            className="vplaylist videoCard">
            <div
                className="thumbnail">
                <VPlaylistThumb is_private={props.is_private} playlist_id={props.id}
                    video_id={props.firstV_id} v_len={props.length} thumb={props.thumb_url} />
            </div>
            <div>
                <div className='mt-2 d-flex w-100'>

                    <div class="videoTitle me-1">
                        <NavLink title={props.VideoTitle}
                            to={`/video/${props.firstV_id}`}
                            state={{ is_private: props.is_private, playlist_id: props.id, type: 'p' }}>
                            {props.title}
                        </NavLink>
                    </div>
                    {/* <p className='mt-3 me-1 save-style' onClick={onSave}>
                        {saved && <BsBookmarkFill color={`${saved ? 'red' : ''}`} />}
                        {!saved && <BsBookmark color='#999999' />}
                    </p> */}

                </div>

                <p className='mt-3 authorRow'>
                    <div className='d-flex align-items-center'>
                        <img className='user-image' src={env.SERVER_REQUEST_PATH + props.profile_img} circular="true" />
                        {'  '} <span className='link-pointer username'
                            onClick={() => {
                                navigate(`/profile/${props.username}`, { state: props.user_id })
                            }}
                        >{props.username}</span>
                        <div className='ms-2'><span className="d-flex align-items-center videoDate">
                            {/* {helper.getTimeSinceCreation(props.creation_date)} */}
                        </span></div>
                    </div>
                    {/* <p className='w-100 mt-2 stats-font d-flex'>
                        <p className='col-md-9 d-flex justify-content-space-between'>
                            <p className="me-2"><BsChat className='me-1' color='#999999' />{helper.kFormatter(props.no_comments)}</p>
                            <p className="me-2"><BiLike className='me-1' color='#999999' />{helper.kFormatter(props.no_likes)}</p>
                            <p className="me-2"><BsEye className='me-1' color='#999999' />{helper.kFormatter(props.no_views)}</p>
                        </p>
                        
                    </p> */}
                </p>
            </div>

        </div >
    );
}

export default VPlaylistCard;