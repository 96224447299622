import '../../../css/UserGrid.css'
import { FaUserCircle } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import { BsCheck } from 'react-icons/bs';
import Button from 'react-bootstrap/Button';
const env = require('../../../../config.env')
const helper = require('../../../../utilities/helpers')

const UserGrid = (props) => {
    const navigate = useNavigate()
    const [isFollowed, setisFollowed] = useState(false)
    const [is_private, set_isPrivate] = useState(false)

    const unfollow = (user_id, username) => {
        const userIndex = props.users.findIndex(user => user.id === user_id)
        props.updateUsers(userIndex, 0)
        let headers = { username: username }
        axios.get(`${env.SERVER_REQUEST_PATH}unfollow/${user_id ? user_id : -1}`, { headers: headers })
            .then((response) => {
                // setrefreshdata(Math.random())
            }).catch(err => {
                // showBoundary(err)
                props.updateUsers(userIndex, 1)
                console.error(`Error: ${err}`)
            })
    }

    const follow = (user_id, username) => {
        const userIndex = props.users.findIndex(user => user.id === user_id)
        props.updateUsers(userIndex, 1)
        let headers = { username: username }
        axios.get(`${env.SERVER_REQUEST_PATH}follow/${user_id ? user_id : -1}`, { headers: headers })
            .then((response) => {
                // setrefreshdata(Math.random())
            }).catch(err => {
                // showBoundary(err)
                props.updateUsers(userIndex, 0)
                console.error(`Error: ${err}`)
            })
    }

    return (
        <div className='users-grid row w-100 row-gap-4 m-0'>
            {props.users?.map((user) => {
                return <div className='col-lg-3 col-md-4 col-12'>
                    <div className='d-flex flex-column search-profile-col px-lg-4 px-md-4 px-3 align-items-center w-100'>
                        <div className='profile-img-container'>
                            {user &&
                                user?.profile_img &&
                                <img src={env.SERVER_REQUEST_PATH + user?.profile_img}
                                    className='profile-pub-img' rounded />
                            }
                            {user && !user?.profile_img && <FaUserCircle size={100} color='6e757c' />}
                            {!user &&
                                <ClipLoader className='me-2' color="#B9AFF7" width={50} height={50} />
                            }
                        </div>

                        <div className='d-flex flex-column align-items-center mt-3'>

                            {user && <span className='pub-name'>
                                {user.firstname + ' '}
                                {user.lastname ? user.lastname : ''}
                            </span>}

                            {user && <p className='my-0 mt-0 font-style fontcolor'>
                                @{user.username}
                            </p>
                            }

                            {user && <p className='my-0 mt-0 font-style fontcolor'>
                                {user.country_name}
                            </p>
                            }

                            {/* <div className='mt-2' style={{ display: 'flex', flexDirection: 'row', gap: '24px' }}>
                                <div className='follow-style'>
                                    <p className='text-center'><span className='follow-num-style'>{user ? helper.kFormatter(user.followers) : 0}</span> Followers</p>
                                </div>
                                <div className='follow-style'>
                                    <p className='text-center'><span className='follow-num-style'>{user ? helper.kFormatter(user.following) : 0}</span> Following</p>
                                </div>
                            </div> */}

                            {user && <p className='pub-aff mt-2 mb-0 text-center'>{user.affiliation}</p>}

                        </div>

                        {/* <div className='d-flex align-items-center justify-content-center gap-3 mt-1'>
                            <div className='d-flex flex-column align-items-center'>
                                <span>-</span>
                                <span className='fontcolor'>Citations</span>
                            </div>
                            <div className='separator'></div>
                            <div className='d-flex flex-column align-items-center'>
                                <span>-</span>
                                <span className='fontcolor'>h-index</span>
                            </div>
                        </div> */}

                        <div className='d-flex justify-content-center align-items-end my-4 gap-2 w-100'>
                            {user.isFollowing == 1 && <Button
                                className='w-100' variant="primary" size="sm"
                                onClick={() => { unfollow(user.id, user.username) }}>Following
                                <BsCheck size={20}></BsCheck>
                            </Button>
                            }

                            {user.isFollowing == 0 && <Button className='w-100' size="sm" variant="outline-primary"
                                onClick={() => { follow(user.id, user.username) }} >Follow
                            </Button>}

                            <Button className='w-100' size="sm" variant="outline-primary" style={{ "text-wrap": "nowrap" }}
                                onClick={() => { navigate("/profile/" + user.username) }} >View profile
                            </Button>
                        </div>

                    </div>
                </div>
            })}
        </div>
    )
}

export default UserGrid
