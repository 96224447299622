import React from 'react'
import { TiDelete } from 'react-icons/ti'
const AccessDenied = () => {
    return (
        <div className='d-flex flex-column justify-content-center align-items-center mt-5 pt-5'>
            <TiDelete size={250} color='red' />
            <h4>Acess Denied</h4>
            <p>You Don’t have a permission to view this page</p>
        </div>
    )
}

export default AccessDenied
