import { Route, Navigate, useNavigate } from 'react-router-dom'
import React from 'react'
import { useStateValue } from "./index";
import axios from 'axios';
const env = require('./config.env')


const helper = require('./utilities/helpers')

const PrivateRoute = ({ children }) => {
    const [userDetails, dispatch] = useStateValue();
    const token = localStorage.getItem("user-token")
    const [permissionsLoaded, set_permissionsLoaded] = React.useState(0)
    const navigate = useNavigate()

    React.useEffect(() => {
        if (!userDetails.user)
            axios.get(`${env.SERVER_REQUEST_PATH}profile`)
                .then((response) => {
                    dispatch({
                        type: "SET_USER",
                        user: response.data
                    })
                    set_permissionsLoaded(Math.random())
                }).catch(err => {
                    console.error(`Error: ${err}`)
                })
    }, []);

    if (userDetails.user) {
        let permissions = userDetails.user.permissions

        let found = helper.checkPermission(permissions)
        if (!found) {
            if (helper.getCurrentPage().toLowerCase() === 'business'
                || helper.getCurrentPage().toLowerCase() === 'academics') {
                return <Navigate to="/login" />
            }
            window.history.replaceState(null, null, "/coming-soon");
            return <Navigate to="/coming-soon" />
        }
        else
            return children

    }
}
export default PrivateRoute
