import React from 'react'
import '../css/static_pages.css'
const PrivateVideo = () => {

    return (
        <div className='prv-container'>
            <div className='prv-img ms-5'>

            </div>
            <p className='prv-text mt-2'>THIS VIDEO IS PRIVATE CONTENT</p>
            <a className='home_btn' role='button' href='/home'>Home</a>
        </div>
    )
}

export default PrivateVideo
