import React, { useCallback, useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import '../css/UploadPDF.css';
import axios from 'axios'
import Switch from '../../SharedComponents/js/Switch'
import UploadFile from '../../SharedComponents/js/UploadFile';
import CustomRadioInput from '../../SharedComponents/js/CustomRadioInput'
import { BarLoader } from 'react-spinners';
import { Modal } from 'react-bootstrap';
import GenerateTerms from './GenerateTerms';
const helper = require('../../utilities/helpers')
const env = require('../../config.env')

const UploadPDF = (props) => {
    const [interests, getinterests] = useState([])
    const [selectedinterest, setselectedinterest] = useState()
    const [extracted_dois, setextracted_dois] = useState([])
    const [isUploaded, set_isUploaded] = useState(false)
    const [error_msg, seterror_msg] = useState('')
    const [showAlert, setshowAlert] = useState("hidden")
    const [selectedFile, setSelectedFile] = useState()
    const [is_by_author, setis_by_author] = useState(false)
    const [is_share, setis_share] = useState(false)
    const [gen_type, setgenType] = useState('free')
    const [voice_type, setvoice_type] = useState('Matthew')
    const [DOI, setDOI] = useState('')
    const [doc_id, setdoc_id] = useState('')
    const [customPermission, setcustomPermission] = useState(false)
    const [is_terms_accepted, settermsAccepted] = useState(false)

    const [showTermsModal, setshowTermsModal] = useState(false);

    const handleClose = () => setshowTermsModal(false);
    const handleShow = () => setshowTermsModal(true);

    useEffect(() => {
        let found = helper.checkPagePermission(props.permissions, "custom")
        setcustomPermission(!found)
        axios.get(env.SERVER_REQUEST_PATH + 'interests')
            .then((response) => {
                let data = response.data.data
                getinterests(data)
                setselectedinterest(data[0].id)
            }).catch(err => {
                props.handleErrors(err)
                console.error(`Error: ${err}`)
            })
    }, [])
    const oncheck = (e) => {
        settermsAccepted(e.target.checked)
    }
    const handleRadioChange = (e) => {
        let input_name = e.target.name
        let input_value = e.target.checked
        if (input_name === "is_author")
            setis_by_author(input_value ? true : false)

        if (input_name === "is_share")
            setis_share(input_value ? true : false)

        if (input_name === "gen_type")
            setgenType(e.target.value == 'a' ? "auto" : e.target.value == 'c' ? "custom" : "free")
        if (input_name === "voice_type")
            setvoice_type(e.target.value)
    }
    const showError = (msg) => {
        seterror_msg(msg)
        setshowAlert("")
        setTimeout(() => {
            hideError();
        }, 3000);
    }
    const hideError = () => {
        seterror_msg('')
        setshowAlert("hidden")
    }
    const handleGenerate = (e) => {
        e.preventDefault()
        if (!selectedFile) {
            showError("Please Upload Your File First")
            return
        }
        props.beginProcess()
        const formData = new FormData();
        formData.append("DOI", DOI)
        formData.append("interest", selectedinterest)
        formData.append("is_by_author", is_by_author)
        formData.append("file", selectedFile)
        try {
            axios.post(env.SERVER_REQUEST_PATH + 'upload', formData, {
                headers: { 'content-type': 'multipart/form-data' }
            }).then((res) => {
                props.submit_Upload(gen_type, voice_type, res.data.doc_id, DOI, selectedinterest, is_by_author, is_share)
            });
        } catch (error) {
            props.handleErrors(error)
            console.error('Failed to submit files.');
        }
    }
    const debounce = (func, delay) => {
        let debounceTimer;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer =
                setTimeout(() => func.apply(context, args), delay);
        }
    }
    const onDOIChange = (event) => {
        setDOI(event.target.value)
    }
    let optimisedHandleChange = useCallback(
        debounce(onDOIChange, 500)
        ,
        []
    );
    const onSelectInterset = (e) => {
        setselectedinterest(parseInt(e.target.value))
    }
    const setUploadedFile = (file) => {
        setSelectedFile(file)
        set_isUploaded(false)
    }
    const saveDoc = (docId) => {
        props.beginProcess()
        if (!docId)
            docId = doc_id
        try {
            axios.post(env.SERVER_REQUEST_PATH + 'saveDoc', {
                doc_id: docId, DOI: DOI, is_by_author: is_by_author, interest: selectedinterest
            }).then((res) => {
                props.submit_Upload(gen_type, voice_type, docId, DOI, selectedinterest, is_by_author, is_share)
            }).catch((error) => {
                props.handleErrors(error)
            })
        }
        catch (error) {
            props.handleErrors(error)
        }

    }
    const getDOI = (docId) => {
        if (extracted_dois.length > 0 && !docId) {
            return
        }
        if (!docId)
            docId = doc_id

        axios.post(env.SERVER_REQUEST_PATH + 'getDOI', { doc_id: docId }).then((res) => {
            setextracted_dois(res.data)
        }).catch((error) => {
            props.handleErrors(error)
            console.error('Failed to get DOI.');
        })

    }
    const upload_doc = (e, next) => {
        props.restErrors()
        if (next === 'gen')
            e.preventDefault()
        if (!selectedFile) {
            showError("Please Upload Your File First")
            return
        }
        if (next === 'gen' && isUploaded) {
            saveDoc()
            return
        }
        if (next === 'doi' && isUploaded) {
            getDOI()
            return
        }
        const formData = new FormData();
        formData.append("file", selectedFile)
        props.beginProcess()
        try {
            axios.post(env.SERVER_REQUEST_PATH + 'upload_doc', formData, {
                headers: { 'content-type': 'multipart/form-data' }
            }).then((res) => {
                set_isUploaded(true)
                props.endProcess()
                setdoc_id(res.data.doc_id)
                if (next === 'doi')
                    getDOI(res.data.doc_id)
                else
                    saveDoc(res.data.doc_id)

            });
        } catch (error) {
            props.handleErrors(error)
            console.error('Failed to submit files.');
        }
    }
    return (
        <form onSubmit={(e) => upload_doc(e, 'gen')}>
            <div className='upload-container'>
                <div className='me-4 upload-width'>
                    {props.mode == "aud" && <div className='mt-1 mb-4'>
                        <span className='text-theme-color'>Audio generation services
                            <br /> Let’s create an audio about your research article!
                        </span></div>}
                    {props.mode == "vid" && <div className='mt-1 mb-4'>
                        <span className='text-theme-color'>Video generation services
                            <br /> Let’s create a video about your research article!
                        </span></div>}
                    <UploadFile uploadFile={setUploadedFile} fileType={['pdf']} />
                    <Alert key="danger" variant="danger" className='mt-2'
                        onAnimationEnd={() => hideError()} hidden={showAlert}>
                        {error_msg}
                    </Alert>
                </div>

                <div className='upload-option mt-2'>
                    <h4 className='text-align-left font-weight-bold'>Upload Options</h4>
                    <div>
                        <label className="mb-2 label-style">Research Area</label>
                        {interests.length <= 0 &&
                            <BarLoader className='interset-style' color="#B9AFF7" width={600} height={30} />
                        }
                        {interests.length > 0 && <Form.Select className='interset-style' onChange={onSelectInterset} aria-label="Document Interest">
                            {interests.map((item) => {
                                return (
                                    <option key={item.id} value={item.id}>{item.title}</option>
                                )
                            })
                            }
                        </Form.Select>}
                    </div>
                    <div className={`doi mt-3 required`}>
                        <label className="mb-2 label-style">DOI</label>
                        <div className='d-flex'>
                            <input
                                type={"text"} name="DOI" placeholder="Paper DOI" value={DOI}
                                className="col-md-6 doi-style input-style" onChange={(e) => setDOI(e.target.value)} required />

                            <Dropdown className='doi-select' onClick={(e) => {
                                upload_doc(e, 'doi')
                            }}>
                                <Dropdown.Toggle className='dropdown-style' id="dropdown-basic">
                                    Autofill
                                </Dropdown.Toggle>
                                <Dropdown.Menu className={`drop-width ${extracted_dois.length > 7 ? 'drop-style' : ''}`}>
                                    {extracted_dois.map((item) => {
                                        return <Dropdown.Item onClick={() => {
                                            if (item != 'Other')
                                                setDOI(item)
                                            else setDOI()
                                        }
                                        }>{item}</Dropdown.Item>

                                    })}
                                    <Dropdown.Item>Other</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>

                    <div className='d-flex flex-column mb-4'>
                        <label className="mb-2 label-style">Video Information</label>

                        <Switch key={'is_author'} label={"Are you the author of the article?"}
                            name={"is_author"}
                            defaultChecked={""}
                            onChange={handleRadioChange}
                        />
                        {props.mode == 'aud' && <Switch
                            key={'is_share'} label={"Share to public?"}
                            name={"is_share"}
                            defaultChecked={""}
                            onChange={handleRadioChange}
                        />}
                        {props.mode != 'summ' && <div className='mt-2'>
                            <CustomRadioInput
                                label={"Voice Over"}
                                options={["Matthew", "Joanna"]} name={"voice_type"}
                                values={["Matthew", "Joanna"]}
                                disabled={[false, false]}
                                defualt_checked={["checked", ""]}
                                onChange={handleRadioChange}
                            /></div>}
                        {props.mode == "vid" &&
                            <div className='d-flex mt-3' hidden={props.mode == 'vid' ? '' : 'hidden'}>
                                <CustomRadioInput
                                    label={"Video Type"}
                                    options={["Video Clip"]} name={"gen_type"}
                                    values={["f"]}
                                    disabled={[false]}
                                    details={['1 min video for lay audience']}
                                    defualt_checked={["checked"]}
                                    onChange={handleRadioChange}
                                /></div>}
                        <div className="mt-2 form-check terms-conditions required">
                            <input className="form-check-input" name="terms" type="checkbox" onChange={oncheck} id="flexCheckDefault" />
                            <label className="form-check-label" for="flexCheckDefault">
                                By checking the box you are agreeing to our <a onClick={() => { handleShow() }} href="#" > Terms and Conditions</a> *
                            </label>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end mt-3'>
                        <p className='file-cancel mt-2'
                            onClick={() => {
                                props.Cancel(false)
                            }}>Cancel</p>
                        <Button disabled={props.isLoading ? 'disabled' : props.stop_process ? 'disabled' :
                            !is_terms_accepted ? 'disabled' : ''}
                            className='w-25 gen-button ms-3' type='submit' variant="primary"
                        >
                            {props.mode != 'summ' ? 'Generate' : 'Summarize'}
                        </Button>
                    </div>
                </div>
                <Modal size="lg" show={showTermsModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Terms and Conditions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><GenerateTerms /></Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div >
        </form >
    );

}

export default UploadPDF
