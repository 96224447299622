import React, { useEffect, useState } from "react";
import axios from 'axios'
import Button from 'react-bootstrap/Button';
import '../css/Sections.css'
import { Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
const env = require('../../config.env')
const helper = require('../../utilities/helpers')
const FreeSections = (props) => {
    const [checkedState, setCheckedState] = useState();
    const [selectedSection, setSelected] = useState();
    const [v_es_duration, setv_es_duration] = useState('0 minutes')
    const [v_es_time, setv_es_time] = useState(0)
    const [v_cal_duration, setv_cal_duration] = useState(0)

    // useEffect(() => {
    //     const intial = new Array(props.sections.length).fill(false)
    //     setCheckedState(intial)
    // }, [props.sections])
    const saveSections = () => {
        props.submitSections(selectedSection, v_cal_duration)
    }

    const handleOnChange = (e, position) => {
        setCheckedState(position);
        setSelected(sections[position]);

        var no_w_calc = props.defaultLevel.max_len
        var estimated_time = env.SECTION_NO_SECONDS
        let estimated_str = helper.fancyTimeFormat(estimated_time)

        setv_cal_duration(no_w_calc)
        setv_es_duration(estimated_str)
        setv_es_time(estimated_time)
    }
    let sections = props.sections

    if (sections.length > 0) {

        return (
            <div className="w-100 sections-container mt-2">
                <div className="px-4 row m-0 d-flex justify-content-center">
                    <div className="mt-3">
                        <div className="d-flex justify-content-start align-items-start">
                            <strong className="me-4">Select only one section to summarize</strong>
                            <div className="">
                                <Alert className='space-around' key='info' variant='info'>
                                    <span className="fw-bold">Subscribe to select multiple sections</span>
                                </Alert>
                            </div>
                        </div>
                        <div className="d-flex w-100">
                            <div className='w-100 d-flex justify-content-end'>
                                <span className='fw-bold fs-6'>
                                    Video estimated duration: {v_es_duration}</span>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6 mb-3">
                        <div className=" list-height sections-list border border-1 rounded">

                            <div className="visible-scrolledParent">
                                {sections.map((item, index) => {
                                    return (

                                        <div className="m-2" key={index}>

                                            <label
                                                className={`m-2 border border-1 rounded p-2 lbl-container bs-body-font-family ${checkedState == index ? 'checked' : ''} ${props.disabled_sections?.[index] ? ' disabled-style' : ''}`}
                                                htmlFor={`sec-checkbox-${index}`}>
                                                <input
                                                    type="checkbox"
                                                    id={`sec-checkbox-${index}`}
                                                    name={sections[index]}
                                                    value={sections[index]}
                                                    onChange={(e) => handleOnChange(e, index)}
                                                    className={checkedState == index ? 'checked' : ''}
                                                    disabled={props.disabled_sections?.[index] ? 'disabled' : ''}
                                                />
                                                <span className={checkedState == index ? "checked checkmark mr-3" : "checkmark mr-3"}></span>
                                                {sections[index]}
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <ul className="list-height list-group border border-1 rounded">
                            <li className="list-group-item box-header">Selected Sections</li>
                            <div className="visible-scrolledParent">
                                {selectedSection && <li key={"section-0"} className="box-item list-group-item border border-1 rounded mt-2 mx-2">
                                    <div className="m-0 p-0">
                                        {props.summ_levels?.map((level, lv_index) => {
                                            return (
                                                <OverlayTrigger placement="bottom"
                                                    overlay={lv_index > 0 ? <Tooltip id="tooltip">
                                                        Subscribe for Access
                                                    </Tooltip> : <></>}>
                                                    <div className={`${lv_index == 0 ? 'px-0 ' : ''} radio-font form-check form-check-inline ms-0 mb-0 me-0 mt-1`}>
                                                        <input className="radio-input" type="radio" name={`lvls-sec-0`}
                                                            id={`inlineRadio-${level.id}`} value={level.id}
                                                            checked={lv_index == 0 ? 'checked' : ''}
                                                            disabled={lv_index > 0 ? 'disabled' : ''}
                                                        />
                                                        <label className="" for={`inlineRadio-${level.id}`}>{level.name}</label>
                                                    </div>
                                                </OverlayTrigger>
                                            )
                                        })}
                                    </div>
                                    {selectedSection}
                                </li>}
                            </div>
                        </ul>
                    </div>
                </div>
                <div className='d-flex justify-content-end pe-4 mt-3'>
                    <p className='file-cancel mt-2'
                        onClick={() => {
                            props.Cancel(true)
                        }}>Cancel</p>
                    <Button disabled={props.isLoading ? 'disabled' : props.stop_process ? 'disabled' : ''}
                        className='gen-button mx-3' type='submit' variant="primary" onClick={saveSections}>
                        Next</Button>
                </div>

            </div>
        )
    }
    else return (<></>)
}

export default FreeSections
