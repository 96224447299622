import React, { useEffect, useState } from "react";
import { MdAddCircle } from 'react-icons/md'
import { Alert } from "react-bootstrap";
import { TiDelete } from 'react-icons/ti'
import '../css/AddMaterials.css'
import { Button } from "react-bootstrap";
import axios from "axios";
const env = require('../../config.env')
const dataset = [{ material_name: "", company: "", company_link: "" }]
const AddMaterials = (props) => {
    const [data, setData] = useState(() => dataset);
    const [error_msg, seterror_msg] = useState('')
    const [showAlert, setshowAlert] = useState("hidden")

    const addEmptyRow = () => {
        let changed_data = []
        data.map((row) => {
            changed_data.push(row)
        })
        changed_data.push({ material_name: "", company: "", company_link: "" })
        setData(changed_data)
    }
    const setvalue = (e, key, index) => {
        let changed_data = []
        data.map((row, changed_index) => {
            let changed_row = row
            if (index == changed_index) {
                if (key == "name")
                    changed_row.material_name = e.target.value
                else if (key == "company")
                    changed_row.company = e.target.value
                else if (key == "link")
                    changed_row.company_link = e.target.value
            }
            changed_data.push(changed_row)
        })
        setData(changed_data)
    }
    const deleteRow = (index) => {
        if (index == 0 && data.length == 1) {
            let changed_data = []
            data.map((row) => {
                let changed_row = row
                changed_row.material_name = ""
                changed_row.company = ""
                changed_row.company_link = ""

                changed_data.push(changed_row)
            })
            setData(changed_data)
            return
        }
        let changed_data = []
        data.map((row, changed_index) => {
            let changed_row = row
            if (index != changed_index)
                changed_data.push(changed_row)
        })
        setData(changed_data)
    }
    const onClick_save = () => {
        if (!dataExists())
            return
        else {
            props.beginProcess()
            let reqdata = { video_id: props.video_id, materials: data }
            axios.post(env.SERVER_REQUEST_PATH + 'savematerials', reqdata)
                .then((response) => {
                    props.endProcess()
                    props.submit()
                }).catch(err => {
                    props.handleErrors(err)
                    console.error(`Error: ${err}`)
                })
        }
    }
    const dataExists = () => {
        let result = true
        data.map((row) => {
            if (row.material_name === '' || row.company === '' || row.company_link === '')
                result = false
        })
        if (!result)
            showError("Please add your materials first")


        return result
    }
    const showError = (msg) => {
        seterror_msg(msg)
        setshowAlert("")
        setTimeout(() => {
            hideError();
        }, 3000);
    }
    const hideError = () => {
        seterror_msg('')
        setshowAlert("hidden")
    }
    return (
        <div className="material d-flex flex-column align-items-center ms-5">
            <table>
                <thead>
                    <tr key="head">
                        <th>Material</th>
                        <th>Company</th>
                        <th>Link</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => {
                        return (<tr key={"row-" + index}>
                            <td><input value={row.material_name}
                                onKeyDown={(event) => {
                                    event.stopPropagation();
                                }}
                                onChange={(e) => setvalue(e, "name", index)} /></td>
                            <td><input value={row.company}
                                onKeyDown={(event) => {
                                    event.stopPropagation();
                                }}
                                onChange={(e) => setvalue(e, "company", index)} /></td>
                            <td><input value={row.company_link}
                                onKeyDown={(event) => {
                                    event.stopPropagation();
                                }}
                                onChange={(e) => setvalue(e, "link", index)} /></td>
                            <td className="d-flex justify-content-center">
                                <Button className="me-1" onClick={addEmptyRow}><MdAddCircle color="green" /></Button>
                                <Button onClick={() => deleteRow(index)}><TiDelete color="red" /></Button>
                            </td>
                        </tr>)
                    })}
                </tbody>
            </table>
            <div className='w-100 d-flex justify-content-end mt-3'>
                <p className='file-cancel mt-2'
                    onClick={() => {
                        // props.nextstep()
                        props.submit()
                    }}>Skip</p>
                <Button disabled={props.isLoading ? 'disabled' : props.stop_process ? 'disabled' : ''}
                    className='gen-button mx-3' type='submit' variant="primary" onClick={onClick_save}>
                    Next</Button>

            </div>
            <div className="w-100 d-flex justify-content-end">
                <Alert key="danger" variant="danger" className='mt-2'
                    onAnimationEnd={() => hideError()} hidden={showAlert}>
                    {error_msg}
                </Alert>
            </div>
        </div >
    );
}
export default AddMaterials


