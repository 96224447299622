import React, { useContext, Component, useRef, useEffect, useState } from 'react'
import '../css/ViewVideos.css'
import ViewVideosContent from '../Components/Main/ViewContent/ViewVideosContent'
import Footer from '../../SharedComponents/js/Footer'
import Header from '../../Main/Components/Header'
import { useErrorBoundary } from "react-error-boundary";
import { useStateValue } from "../../index";
import FingerLeftMenu from '../Components/Main/FingerLeftMenu';
import MiniFooter from '../../SharedComponents/js/Mini-Footer'
const env = require('../../config.env')
const ViewVideos = () => {
  const [userDetails, dispatch] = useStateValue();
  const [DataIsLoaded, setDataIsLoaded] = useState()
  const [datacounter, setdatacounter] = useState(0)

  const { showBoundary } = useErrorBoundary();
  useEffect(() => {
    if (datacounter == 4)
      setDataIsLoaded(true)
  }, [datacounter])
  return (
    <div className='d-flex flex-column ViewVideosContainer' >
      <Header userdata={userDetails} catchErrors={(err) => {
        showBoundary(err)
      }} />

      <div>

        <div className='myvideos-content'>
          <div className='top-m px-5 pb-3'>

            <h4>My Videos</h4>
            <ViewVideosContent count={() => { setdatacounter((prev) => prev + 1) }}
              DataIsLoaded={DataIsLoaded}
              catchErrors={(err) => {
                showBoundary(err)
              }} />
          </div>
        </div>
      </div>
      <FingerLeftMenu />
      <MiniFooter />
    </div>
  )

}
export default ViewVideos
