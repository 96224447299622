import React from 'react'
import { Stack } from 'react-bootstrap';
import '../css/Navbar.css'
import { Link } from 'react-router-dom';

const Navbar = (props) => {
    return (
        <Stack className={`${props.className ? props.className : ''} tab-navbar`} direction="horizontal" gap={3} id="tab">
            {props.feeds.map((feed, index) => {
                return <span className={`tab-item ${feed.type == props.currentType ? 'active' : ''}`} key={index}>
                    <Link id={index}
                        className='tab-item-link'
                        onClick={(e) => {
                            e.preventDefault();
                            props.loadData(feed.type)
                        }}
                    > {feed.text} </Link></span>
            })}
        </Stack >
    )
}

export default Navbar
