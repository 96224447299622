import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useErrorBoundary } from "react-error-boundary";
import { Helmet } from 'react-helmet';

const env = require('../../config.env')

const PreviewLink = () => {
    const [previewData, setPreviewData] = useState(null);
    const { id } = useParams()
    const { showBoundary } = useErrorBoundary();



    useEffect(() => {
        if (id)
            axios.get(`${env.SERVER_REQUEST_PATH}preview/${id}`)
                .then((response) => {

                    const data = response.data.data;
                    setPreviewData(data);
                }).catch(err => {
                    showBoundary(err)
                    console.error(`Error: ${err}`)
                })

    }, [id]);

    return (
        <div>
            {previewData ? (
                <div>
                    <Helmet>
                        <meta property="og:type" content="website"></meta>
                        <meta property="og:title" content={previewData.title} />
                        <meta property="og:description" content={previewData.desc} />
                        <meta property="og:image" content={`data:image/*;base64,${previewData.thumb_url}`}
                        // {`data:image/*;base64,${videoDetails.thumb_url}`}
                        />
                        <meta property="og:url" content={`/videos/${id}`}
                        // {`data:video/mp4;base64,${videoDetails.uri}`}
                        />
                    </Helmet>
                    <h2>{previewData.title}</h2>
                    <p>{previewData.desc}</p>
                    <img src={`data:image/*;base64,${previewData.thumb_url}`} alt="Preview" />
                    {/* Additional preview data rendering */}
                </div>
            ) : (
                <p>Loading preview data...</p>
            )}
        </div>
    );
};

export default PreviewLink;
