import '../../../css/VideoCard.css'
import { NavLink, useNavigate } from 'react-router-dom'
import { BsBookmark, BsBookmarkFill, BsChat, BsCheck, BsEye, BsHeart } from 'react-icons/bs';
import { BiLike } from 'react-icons/bi';
import VideoPoster from '../../VideoPoster';
import { useEffect, useState } from 'react';
import axios from 'axios'
import { FaEllipsisV, FaUserCircle } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import { MdOutlinePublishedWithChanges } from 'react-icons/md';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ClockLoader, PropagateLoader } from 'react-spinners';
import { Badge, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
const env = require('../../../../config.env')
const helper = require('../../../../utilities/helpers')

function VideoCard(props) {
    const [video_id, setVideo_id] = useState(props.id)
    const navigate = useNavigate()
    const [saved, setsaved] = useState(false)
    const [ispending, setIspending] = useState(false)
    const [is_generated, set_is_generated] = useState(false)
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (props.isgenerated)
            set_is_generated(props.isgenerated)
    }, [props.isgenerated])

    const deleteVideo = (video_id) => {
        if (!props.is_privateVideo || !props.is_paid || props.pending_status == "gen")
            return
        if (window.confirm('Are you sure you want to delete this video?')) {

            axios.get(env.SERVER_REQUEST_PATH + `deleteVideo/${video_id}`)
                .then((response) => {
                    if (response.data.is_deleted) {
                        props.removeVideo(video_id)
                    }
                }).catch(err => {
                    props.catchErrors(err)
                })
        }
    }
    // useEffect(() => {
    //     if (ispending && props.pending_status == "gen") {
    //         const interval = setInterval(() => {
    //             setProgress((prev) => {
    //                 if (prev < 80) {
    //                     return prev + 5; // Increment progress  
    //                 } else {
    //                     clearInterval(interval); // Clear interval when max is reached  
    //                     return prev;
    //                 }
    //             });
    //         }, 1000); // Increment every 1 second  

    //         return () => clearInterval(interval);
    //     } // Cleanup on component unmount  
    // }, [ispending]);
    useEffect(() => {
        setsaved(props.saved ? props.saved > 0 ? true : false : false)
    }, [props.saved])

    useEffect(() => {
        setIspending(props.is_pending ? props.is_pending > 0 ? true : false : false)
    }, [props.is_pending])

    const onSave = () => {
        let headers = {}
        if (props.is_private)
            headers = { is_private: true }
        setsaved((prev) => !prev)
        axios.post(`${env.SERVER_REQUEST_PATH}save`, { video_id: video_id, saved: !saved }, { headers: headers })
            .then((response) => {
                // props.refreshVideos(props.type)
            }).catch(err => {
                setsaved((prev) => !prev)
                props.catchErrors(err)
                console.error(`Error: ${err}`)
            })

    }
    return (
        <div className="video-card col-lg-3 col-md-4 col-12">
            <div className="position-relative thumbnail">
                <VideoPoster ispending={ispending} is_private={props.is_private}
                    video_id={props.id} v_len={props.length} thumb={props.thumb_url} />

                {ispending &&
                    <div className='publish-placeholder'>
                        {props.pending_status == "pub" &&
                            <div className='d-flex flex-column'>
                                <span className='pub-text-placeholder'>Ready to publish</span>
                                {props.is_paid == 1 && <Badge className='mt-1' bg="success">Paid</Badge>}
                                {props.is_paid == 0 && <Badge className='mt-1' bg="warning" text="dark">Pending Payment</Badge>}
                            </div>}

                        {props.pending_status == "gen" && props.doc_gen_status.toLowerCase() == "inqueue" &&
                            // <CircularProgressbar strokeWidth={5} value={progress} text={`${progress}%`} />
                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                <ClockLoader color='#FFFFFF' />
                                <span className='mt-3 pub-text-placeholder'>Inqueue</span>
                            </div>
                        }
                        {props.pending_status == "gen" && props.doc_gen_status.toLowerCase() == "generating" &&
                            // <CircularProgressbar strokeWidth={5} value={progress} text={`${progress}%`} />
                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                <ClockLoader color='#FFFFFF' />
                                <span className='mt-3 pub-text-placeholder'>Generating...</span>
                            </div>
                        }
                    </div>
                }
            </div>

            <div className='d-flex flex-column w-100 mt-3'>

                <div className="w-100 video-date d-flex justify-content-between">
                    <span>
                        {helper.getTimeSinceCreation(props.creation_date)}
                    </span>
                    {props.is_privateVideo && props.pending_status != "gen"
                        && props.is_paid != 0 && <div className='delete-menu'>
                            <Dropdown>
                                <Dropdown.Toggle
                                    as={ButtonGroup}
                                    //   id={`dropdown-button-drop-${end}`}
                                    // drop="up-centered"
                                    variant="secondary"
                                    size="sm"
                                    // className='dropdown-menu-right'
                                    role='button'
                                //   title={` Drop ${direction} `}
                                ><FaEllipsisV /> </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item eventKey="1" onClick={() => { deleteVideo(props.id) }}>Delete</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>}
                </div>

                <div class="videoTitle">
                    {props.is_paid != 0 && props.pending_status != "gen" && <NavLink title={props.VideoTitle}
                        to={`/video/${props.id}`}
                        state={{ is_private: props.is_private }}>
                        {props.VideoTitle}
                    </NavLink>}
                    {!props.is_paid && <a role='button' title={props.VideoTitle}>
                        {props.VideoTitle}
                    </a>}
                </div>

                <span className="w-100 video-category">
                    {props.category}
                </span>

                <div className='d-flex gap-2 align-items-center link-pointer user-area'
                    onClick={() => {
                        navigate(`/profile/${props.username}`, { state: props.user_id })
                    }}>
                    {props.profile_img &&
                        <div className='user-image'>
                            <img src={env.SERVER_REQUEST_PATH + props.profile_img} circular="true" />
                        </div>
                    }
                    {!props.profile_img && <FaUserCircle size={30} color='6e757c' />}

                    <div className='d-flex flex-column'>
                        <span className='user-name'>
                            {props.firstname + ' ' + (props.lastname ? props.lastname : "")}
                        </span>
                        <span className='user-university'>{props.affiliation}</span>
                    </div>
                </div>

                <div className='d-flex flex-row stats-font justify-content-between align-items-center w-100 mt-2'>
                    <div className='d-flex align-items-center'>
                        <span className="my-0 me-2"><BsChat className='me-1' color='#999999' />{helper.kFormatter(props.no_comments)}</span>
                        <span className="my-0 me-2"><BiLike className='me-1' color='#999999' />{helper.kFormatter(props.no_likes)}</span>
                        <span className="my-0 me-2"><BsEye className='me-1' color='#999999' />{helper.kFormatter(props.no_views)}</span>
                    </div>

                    <div>

                        {!saved && !ispending &&
                            <Button className='py-1 card-save-btn' variant="outline-primary" size="sm" onClick={onSave}><BsHeart color='#5644DE' /> Save</Button>
                        }
                        {saved && !ispending > 0 &&
                            <Button className='py-1 card-save-btn' variant="primary" size="sm" onClick={onSave}><BsCheck color='#fff' /> Saved</Button>
                        }
                        {ispending && props.pending_status == "pub" &&
                            <Button className='py-1 card-save-btn' variant="outline-primary" size="sm"
                                onClick={() => {
                                    setIspending(true)
                                    props.publish()
                                }}><MdOutlinePublishedWithChanges color='#5644DE' /> Publish</Button>
                        }
                    </div>
                </div>

            </div>
        </div >
    );
}

export default VideoCard;