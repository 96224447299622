import React, { useRef, useState, useEffect } from "react";
import "../../css/ShareVideo.css"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { BiSearch } from 'react-icons/bi';
import Alert from 'react-bootstrap/Alert'
import axios from "axios";
import { MdAddBox } from "react-icons/md";
import FormInput from "../../../SharedComponents/js/FormInput";
const env = require('../../../config.env')

const AddToPlaylist = (props) => {
    const [show, setShow] = useState(props.showModal);
    const [newplaylist, setnewplaylist] = useState(false)
    const [playlistType, setplaylistType] = useState(1)
    const [playlistTitle, setplaylistTitle] = useState('');
    const [playlists, setplaylists] = useState([])
    const [do_refresh, set_do_refresh] = useState()

    useEffect(() => {
        setShow(props.showModal)
    }, [props.showModal])

    const handleClose = () => {
        setShow(false)
        props.closeModal()
    };
    const refresh_list = () => {
        set_do_refresh(Math.random())
    }
    const handleChange = (event) => {
        setplaylistTitle(event.target.value);
    }
    const addTonewPlaylist = () => {
        const url = `${env.SERVER_REQUEST_PATH}createVideoplaylist`;
        const data = {
            playlist_title: playlistTitle, status: playlistType == 1 ? false : true,
            video_id: props.video_id
        }
        axios.post(url, data)
            .then((response) => {
                setnewplaylist(false)
                handleClose()
            })
            .catch(error => {
                // Handle the error
                console.error('Error:', error);
            });
    }
    const AddToPlaylist = (e, playlist_id, title) => {
        if (e.target.checked) {
            const url = `${env.SERVER_REQUEST_PATH}addvideotoplaylist`;
            const data = {
                playlist_id: playlist_id,
                video_id: props.video_id
            }
            axios.post(url, data)
                .then((response) => {
                    props.showInfo(`Video added to ${title} Playlist`)
                })
                .catch(error => {
                    // Handle the error
                    console.error('Error:', error);
                });
        }
        else {
            const url = `${env.SERVER_REQUEST_PATH}removeVideoFromPlaylist`;
            const data = {
                playlist_id: playlist_id,
                video_id: props.video_id
            }
            axios.post(url, data)
                .then((response) => {
                    if (response.data) {
                        props.showInfo(`Video removed from ${title} Playlist`)
                        refresh_list()
                    }
                })
                .catch(error => {
                    // Handle the error
                    console.error('Error:', error);
                });
        }
    }


    useEffect(() => {
        if (show) {
            const url = `${env.SERVER_REQUEST_PATH}videoplaylistsByUserwithContent`;

            axios.get(url, { headers: { video_id: props.video_id } })
                .then((response) => {
                    setplaylists(response.data.data)
                })
                .catch(error => {
                    // Handle the error
                    console.error('Error:', error);
                });
        }
    }, [show, do_refresh]);

    return (
        <div>
            <Modal show={show} size="sm" onHide={handleClose}>
                <Modal.Header className="share-modal border border-0" closeButton>

                </Modal.Header>
                <Modal.Body>
                    <div className="box-outline" >
                        <div className="mb-4">
                            {playlists?.map((playlist) => {
                                return (
                                    <Form.Check
                                        type={'checkbox'}
                                        id={`default-checkbox`}
                                        label={playlist.title}
                                        checked={playlist.found ? true : false}
                                        onChange={(e) => { AddToPlaylist(e, playlist.id, playlist.title) }}
                                    />
                                )

                            })}
                        </div>
                        <span className='fw-bold' role='button' onClick={() => { setnewplaylist(true) }}>
                            <MdAddBox size={25} color='#5644de' /> Create new Playlist</span>
                        {newplaylist && <div className="newPlaylist mt-3">
                            <div className=" d-flex w-50 me-3">
                                <FormInput key='Name' name="Name" placeholder='Name'
                                    onKeyDown={(event) => {
                                        event.stopPropagation();
                                    }}
                                    label='Name' onChange={handleChange} required='required' />
                            </div>
                            <div className="d-flex mb-3 ">
                                <Form.Select className="w-50" aria-label="" onChange={(e) => { setplaylistType(parseInt(e.target.value)) }}>
                                    <option value="1">Private</option>
                                    <option value="2">Public</option>
                                </Form.Select>
                                <Button className="ms-3 btn-color" onClick={addTonewPlaylist}>Add</Button>

                            </div>
                        </div>}


                    </div>
                </Modal.Body>

            </Modal>
        </div >
    );
}

export default AddToPlaylist;
