import "../css/FormInput.css"

const FormInput = (props) => {
    const { label, inputIcon, iconToggle, onChange, raiseError, errorMsg, id, className, required, inputRef, ...inputProps } = props;
    return (
        <div className={`from-style ${raiseError ? 'error-input' : ''} ${required ? required : ''} ${className ? className : ''}`}>
            <div className="label-div d-flex"><label className="form-label input-label">{label}
            </label>
                <span className=" me-2 text-end error-msg" hidden={raiseError ? '' : 'hidden'}>{errorMsg}</span>
            </div>
            <input {...inputProps} ref={inputRef} onChange={onChange}
                className={`form-control input-box ${raiseError ? 'error' : ''}`} required={required ? required : ''} />
        </div>
    )
}

export default FormInput