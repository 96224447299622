import React, { useEffect, useState } from 'react'
import '../css/ConfirmMail.css'
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios'
const env = require('../../config.env')

const ConfirmMail = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { showBoundary } = useErrorBoundary();

    const [responseMsg, setresponseMsg] = useState('')
    const [hasRes, sethasRes] = useState(false)
    const [showLogin, setshowLogin] = useState(false)
    useEffect(() => {

        axios.post(env.SERVER_REQUEST_PATH + 'confirmMail', {
            confirm_token: id
        })
            .then((response) => {
                let status = response.status
                sethasRes(true)
                if (status == 200) {
                    setresponseMsg('Thanks for Mail Confirmation')
                    setshowLogin(true)
                }
                else {
                    setresponseMsg('Link Exprired')
                    setshowLogin(false)
                }

            }).catch(err => {
                // let new_errs = [...errors]
                // new_errs.push(err)
                // seterrors(new_errs);
                handleErrors(err)
                console.error(`Error: ${err}`)
            })
    }, [])

    const handleErrors = (err) => {
        if (err.status == 500)
            showBoundary(err)
        else {
            hasRes(true)
            responseMsg(err.message)
        }
    }
    return (
        <div className='mt-5 d-flex justify-content-center'>
            <div className='mt-5 d-flex flex-column ajustify-content-center align-items-center'
                hidden={hasRes ? '' : 'hidden'}>
                <h4>{responseMsg}</h4>
                <button hidden={showLogin ? '' : 'hidden'}
                    className='btn-login-style' onClick={() => { navigate('/login') }}>Login</button>
            </div>
        </div>
    )
}

export default ConfirmMail
