import React, { useState } from 'react'

import { AiOutlineMenu, AiOutlineHome, AiOutlineUser, AiOutlineFileSearch } from 'react-icons/ai'
import { MdFeaturedVideo, MdBusinessCenter } from 'react-icons/md'
import { BsFillGearFill } from 'react-icons/bs'
import { HiOutlineAcademicCap } from 'react-icons/hi'
import '../../css/FingerLeftMenu.css'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { FaHeadphones } from 'react-icons/fa'
const FingerLeftMenu = () => {
    const navigate = useNavigate()
    const nav_name = window.location.href.split('/').pop().toLowerCase()
    const [activeItem, setActiveItem] = useState('')
    const [activeItemIndex, setActiveItemIndex] = useState(0)
    const tooltip = (
        <Tooltip id="tooltip" className='tooltip-style'>
            {activeItem}
        </Tooltip>
    );
    return (
        <div className='finger-left-style d-flex flex-column'>
            <div className=''>
                {/* <OverlayTrigger onEnter={() => { */}
                <div onMouseEnter={() => {
                    setActiveItem('Home')
                    setActiveItemIndex(1)
                }}
                    onMouseLeave={() => {
                        setActiveItem('')
                        setActiveItemIndex(0)
                    }}

                // placement="right"
                // overlay={tooltip}
                >
                    <Button className={`${activeItemIndex == 1 ? 'menu-item-style-active' : nav_name === 'home' ? 'menu-item-style-clicked' : 'menu-item-style'}`}
                        onClick={() => { navigate('/home') }}
                    ><AiOutlineHome color='#5644de' size={18} />
                        {activeItemIndex == 1 && <span class="mx-1">Home</span>}
                    </Button>
                </div>
                {/* </OverlayTrigger> */}
            </div>
            <div className=''>
                {/* <OverlayTrigger onEnter={() => { */}
                <div onMouseEnter={() => {
                    setActiveItem('To Go')
                    setActiveItemIndex(2)
                }}
                    onMouseLeave={() => {
                        setActiveItem('')
                        setActiveItemIndex(0)
                    }}

                // placement="right"
                // overlay={tooltip}
                >
                    <Button className={`${activeItemIndex == 2 ? 'menu-item-style-active' : nav_name === 'audioplaylists' ? 'menu-item-style-clicked' : 'menu-item-style'}`}
                        onClick={() => { navigate('/audioplaylists') }}
                    ><FaHeadphones color='#5644de' size={18} />
                        {activeItemIndex == 2 && <span class="mx-1">To&nbsp;GO</span>}
                    </Button>
                </div>
                {/* </OverlayTrigger> */}
            </div>
            <div className=''>
                {/* <OverlayTrigger onEnter={() => { */}
                <div onMouseEnter={() => {
                    setActiveItem('My Content')
                    setActiveItemIndex(3)
                }}
                    onMouseLeave={() => {
                        setActiveItem('')
                        setActiveItemIndex(0)
                    }}
                // placement="right"
                //     overlay={tooltip}
                >
                    <Button className={`${activeItemIndex == 3 ? 'menu-item-style-active' : nav_name === 'mycontent' ? 'menu-item-style-clicked' : 'menu-item-style'}`}
                        onClick={() => { navigate('/mycontent') }}
                    ><AiOutlineUser color='#5644de' size={18} />
                        {activeItemIndex == 3 && <span class="mx-1">My&nbsp;Content</span>}
                    </Button>
                </div>
                {/* </OverlayTrigger> */}
            </div>


            <div className=''>
                {/* <OverlayTrigger onEnter={() => { */}
                <div onMouseEnter={() => {

                    setActiveItem('Resources')
                    setActiveItemIndex(6)
                }}
                    onMouseLeave={() => {
                        setActiveItem('')
                        setActiveItemIndex(0)
                    }}
                // placement="right"
                //     overlay={tooltip}
                >
                    <Button className={`${activeItemIndex == 6 ? 'menu-item-style-active' : nav_name === 'resources' ? 'menu-item-style-clicked' : 'menu-item-style'}`}>
                        <AiOutlineFileSearch color='#5644de' size={18} />
                        {activeItemIndex == 6 && <span class="mx-1">Resources</span>}

                    </Button>
                </div>
                {/* </OverlayTrigger> */}
            </div>
            <div className=''>
                {/* <OverlayTrigger onEnter={() => { */}
                <div onMouseEnter={() => {
                    setActiveItem('Generate')
                    setActiveItemIndex(7)
                }}
                    onMouseLeave={() => {
                        setActiveItem('')
                        setActiveItemIndex(0)
                    }}
                // placement="right"
                //     overlay={tooltip}
                >
                    <Button className={`${activeItemIndex == 7 ? 'menu-item-style-active' : nav_name === 'generate' ? 'menu-item-style-clicked' : 'menu-item-style'}`}
                        onClick={() => { navigate('/Generate') }}
                    >
                        <BsFillGearFill color='#5644de' size={18} />
                        {activeItemIndex == 7 && <span class="mx-1">Generate</span>}
                    </Button>
                </div>
                {/* </OverlayTrigger> */}
            </div>
        </div>
    )
}

export default FingerLeftMenu
