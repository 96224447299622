import React, { useState, useEffect, useRef } from 'react'
import Badge from 'react-bootstrap/Badge';
import "../css/ShareVideo.css"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { BiSearch } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai'
import Alert from 'react-bootstrap/Alert'
import axios from "axios";
// import { debounce } from "../uils/debounce";
const env = require('../../config.env')

const TagUsers = (props) => {
    const searchRef = useRef(null);
    const [searchValue, setSearchValue] = useState('');
    const [friends, setFriends] = useState([])
    const [selected_friends, setselected_friends] = useState([])
    const handleChange = (event) => {
        setSearchValue(event.target.value);
    }
    useEffect(() => {
        props.getTaggedUsers(selected_friends)
    }, [selected_friends])
    const removeTag = (tag_index) => {
        const changed_friends = []
        selected_friends.map((f, index) => {
            if (index != tag_index)
                changed_friends.push(f)
        })
        setselected_friends(changed_friends)
    }
    const selectFriend = (friend) => {
        searchRef.current.value = friend.username;
        const changed_friends = []

        if (selected_friends.length < 1)
            changed_friends.push({ user_id: friend.user_id, username: friend.username })
        else {
            selected_friends.map((f) => {

                changed_friends.push(f)
            })
            let found = selected_friends.find(x => x.username == friend.username)
            if (!found)
                changed_friends.push({ user_id: friend.user_id, username: friend.username })
        }
        setselected_friends(changed_friends)

    }
    const debounce = (func, delay = 3000) => {
        let debounceTimer;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer =
                setTimeout(() => func.apply(context, args), delay);
        }
    }
    useEffect(() => {
        if (!searchValue) return;
        const debouncedSave = debounce(() => {
            const url = `${env.SERVER_REQUEST_PATH}searchfortag`; // Replace with your local URL
            const data = {
                username: searchValue
            };
            axios.post(url, data)
                .then((response) => {
                    setFriends(response.data.users)
                })
                .catch(error => {
                    // Handle the error
                    console.error('Error:', error);
                });

        }, 500);
        debouncedSave(searchValue);
    }, [searchValue]);

    return (
        <div className='w-100 mt-5'>
            {selected_friends.length >= 1 && <div className='border border-1 rounded-pill p-2 d-flex mb-3'>
                {
                    selected_friends.map((f, index) => {
                        return <Badge key={"f-" + index} className='ms-1' bg="light" text="dark" >{f.username}
                            <AiOutlineClose className='ms-2' onClick={() => removeTag(index)} />
                        </Badge>
                    })}
            </div>
            }
            <InputGroup className="flex-nowrap search-input-style mb-3">
                <InputGroup.Text id="basic-addon1">
                    <BiSearch />
                </InputGroup.Text>
                <Form.Control
                    autoComplete='off'
                    placeholder="search"
                    aria-label="search"
                    aria-describedby="basic-addon2"
                    ref={searchRef}
                    onChange={handleChange}
                    value={searchValue}
                />
            </InputGroup>
            <div className="users-outline" >
                {friends.map((friend) => {
                    return (
                        <div onClick={() => { selectFriend(friend) }}>
                            <div className="user-result" onClick={() => {

                            }} key={friend.id}>
                                <img src={friend.profile_img ? env.SERVER_REQUEST_PATH + friend.profile_img : ''} className="dot" />
                                <p className='user-name'>
                                    {friend.name}
                                </p>
                                <p className='username-fineText user-tag'>
                                    @{friend.username}
                                </p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default TagUsers
