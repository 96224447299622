import { useEffect, useState, useMemo, useRef } from 'react';
import Pagination from '../../../../SharedComponents/js/Pagination';
import VideoGridRow from "./VideoGridRow"
import axios from 'axios'
import dateFormat from 'dateformat';
import '../../../css/VideoGrid.css'
import ViewVideosLoader from '../../Loaders/ViewVideosLoader';
import { MdOutlinePublishedWithChanges } from 'react-icons/md';
import Publish from '../../../../PDFGeneration/Components/Publish';
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
const helper = require('../../../../utilities/helpers')
const env = require('../../../../config.env')

const VideosPendingGrid = ({ count, catchErrors }) => {
    const [totalCount, setTotalCount] = useState(0)
    const [videos, getVideos] = useState([])
    const selected_video_ref = useRef()
    const [currentPage, setcurrentPage] = useState(0)
    const [refresh_grid, set_refresh_grid] = useState(false)
    const [isShow, set_isShow] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const onPageChange = (pageNumber) => {
        setcurrentPage(pageNumber);
    }
    const handleClose = () => set_isShow(false)
    const publishVideo = (title, descr, taggedUsers) => {
        handleClose()
        let req_data = {
            title: title, descr: descr, is_author: true,
            video_id: selected_video_ref.current.id, taggedUsers: taggedUsers?.length > 0 ? taggedUsers : []
        }
        axios.post(env.SERVER_REQUEST_PATH + 'publishOrSave', req_data)
            .then((response) => {

                let status = response.status
                if (status == 200) {
                    // restErrors()
                    set_refresh_grid(true)
                    // navigate(`/videos/${VideoId}`, { state: { is_private: response.data.is_private } })
                }
                // setIsLoading(false)

            }).catch(err => {
                catchErrors(err)
                // set_stop_process(true)
                console.error(`Error: ${err}`)
            })

    }
    const onPrevClick = () => {
        setcurrentPage(prev => prev - 1);
    }

    const onNextClick = () => {
        setcurrentPage(prev => prev + 1);
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const page = parseInt(params.get('page')) || 1;
        if (refresh_grid || page != currentPage)
            fetchVideos(page)
    }, [currentPage, refresh_grid])
    const fetchVideos = (page) => {
        let pagerlimit = Math.ceil(totalCount / env.PAGE_SIZE)
        // const params = new URLSearchParams(window.location.search);
        // const page = parseInt(params.get('page')) || 1;
        if (page <= pagerlimit || totalCount == 0) {
            // if (page != currentPage || refresh_grid) {
            let req_path = `${env.SERVER_REQUEST_PATH}pendingvideos?page=${page}`
            axios.get(req_path)
                .then((response) => {
                    let vids = response.data.data
                    let currentPage = parseInt(response.data.meta.page)
                    let totalCount = parseInt(response.data.meta.totalCount)
                    getVideos(vids)
                    setDataLoaded(true)
                    if (currentPage)
                        setcurrentPage(currentPage)
                    if (totalCount)
                        setTotalCount(totalCount)
                    set_refresh_grid(false)
                    count()
                }).catch(err => catchErrors(err))
            // }
        }
    }
    return (
        <div className='vdg' >
            {!dataLoaded && [...Array(4)].map((v, index) => <ViewVideosLoader key={index} />)}
            {videos.map((videoDetail) => {
                return <div className="row m-0 p-0 item-container">
                    {videoDetail.pending_status == "pub" &&

                        <div onClick={() => {
                            selected_video_ref.current = videoDetail
                            set_isShow(true)
                        }} className='col-md-1 delete_width' role='button'>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip id={`id-${videoDetail.id}`}>Publish</Tooltip>}>
                                <span role='button' > <MdOutlinePublishedWithChanges color='#5941EB' size={25} /></span>
                            </OverlayTrigger>
                        </div>
                    }
                    {videoDetail.pending_status == "gen" &&

                        <div className='col-md-1 delete_width' >
                        </div>
                    }
                    <div className="col-12 col-md-7 pb-3 title-style">
                        <p className='mt-4'>
                            {videoDetail.VideoTitle}
                        </p>
                    </div>
                    <div className="col-12 col-md-1 pb-3 font-style title-style">
                        <p className=" titledetails-style">
                            <span className="bg-size badge bg-secondary">
                                {videoDetail.pending_status == "pub" ? "Publish" : "Generate"}</span></p>
                    </div>
                    <div className="col-12 col-md-1 pb-3 font-style title-style">
                        {helper.getTimeSinceCreation(videoDetail.creation_date)}

                    </div>
                    <div className="col-12 col-md-1 pb-3 font-style title-style">{videoDetail.category}</div>

                </div>

            })}
            {videos && <Pagination currentPage={currentPage} totalCount={totalCount}
                onPrevClick={onPrevClick}
                onNextClick={onNextClick}
                onPageChange={onPageChange} />
            }
            <div>
                <Modal className='publish-modal' size="lg" show={isShow} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Publish your video</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Publish
                            VideoData={{
                                video_path: selected_video_ref.current?.uri,
                                thumb_img: selected_video_ref.current?.thumb_url,
                                subtitle: selected_video_ref.current?.subtitle
                            }}
                            viewer_type="tokens"
                            is_author={true} is_share={true}
                            doc_title={selected_video_ref.current?.VideoTitle}
                            saveVideo={publishVideo}
                        />
                    </Modal.Body>

                </Modal>
            </div>
        </div>
    )
}
export default VideosPendingGrid
