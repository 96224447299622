import { useState, useEffect, useRef } from 'react';
import FeedsGrid from '../Components/Main/Feeds/FeedsGrid';
import { useErrorBoundary } from "react-error-boundary";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios, { all } from 'axios'
import Header from '../Components/Header';
import Footer from '../../SharedComponents/js/Footer';
import Navbar from '../../SharedComponents/js/Navbar';
import '../css/FeedsPage.css'
import { useStateValue } from "../../index";
import FingerLeftMenu from '../Components/Main/FingerLeftMenu';
import MiniFooter from '../../SharedComponents/js/Mini-Footer';
const env = require('../../config.env')


const FeedPage = () => {
    const { id } = useParams()
    const { state } = useLocation()
    const [pageCount, setpageCount] = useState(1)
    const totalCount = useRef()
    const scrolledCount = useRef()
    const [feeds,] = useState([{ text: "All videos", link: "/Feeds/videos" },
    { text: "Popular", link: "/Feeds/popular" },
    { text: "Saved", link: "/Feeds/saved" }])
    const [videos, setVideos] = useState();
    const videosref = useRef();
    const { showBoundary } = useErrorBoundary();
    const [userDetails, dispatch] = useStateValue();

    const fetchVideos = async (is_scroll = false, end = 0) => {
        if (id) {
            let req_path = ''
            if (state?.interest_id)
                req_path = `${env.SERVER_REQUEST_PATH}getVideosByInterest/${state?.interest_id}?page=${pageCount}`
            else
                req_path = `${env.SERVER_REQUEST_PATH}feeds/${id}?page=${pageCount}`

            if (!is_scroll) {
                videosref.current = null
                setVideos()
            }
            let headers = { page_size: env.SCROLLED_PAGE_SIZE }
            if (state?.is_private)
                headers = { is_private: true, page_size: env.SCROLLED_PAGE_SIZE }
            axios.get(req_path, { headers: headers })
                .then((response) => {
                    let new_vids = response.data.data
                    let vid = videosref.current
                    if (is_scroll) {
                        let all_v = []
                        all_v.push(...vid)
                        all_v.push(...new_vids)
                        videosref.current = all_v
                        scrolledCount.current = all_v.length
                        setVideos(all_v)
                    }
                    else {
                        scrolledCount.current = new_vids.length
                        videosref.current = new_vids;
                        setVideos(new_vids)
                    }
                    totalCount.current = response.data.meta ? response.data.meta.totalCount : 0
                }).catch(err => {
                    showBoundary(err)
                })
        }
    }

    const handleScroll = () => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight
        if (bottom && scrolledCount.current < totalCount.current) {
            setpageCount(page => page + 1)
            fetchVideos(true);
        }
    };

    useEffect(() => {
        fetchVideos();

        window.addEventListener('scroll', handleScroll, {
            passive: true
        });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [id]);

    const getMoreVideos = async () => {
        if ((videosref.current.length * pageCount) < totalCount.current) {
            await fetchVideos(true, videosref.current.length);
        }
    };


    return (
        <div className='d-flex flex-column'>
            <Header userdata={userDetails} catchErrors={(err) => { showBoundary(err) }} />
            <div>
                <div className='feeds-content'>
                    <Navbar feeds={feeds} is_private={state ? state.is_private : false}
                        className="px-5 top-m" />
                    <FeedsGrid videos={videosref.current} type={id} scrollToBottom={getMoreVideos}
                        is_private={state ? state.is_private : false}
                        refreshVideos={() => { fetchVideos() }}
                        catchErrors={(err) => showBoundary(err)}>
                    </FeedsGrid>
                </div>
                <FingerLeftMenu />
                <MiniFooter />
            </div>
        </div>
    );
}

export default FeedPage
