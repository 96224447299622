import '../../css/VideoPoster.css'
import React, { Component } from 'react';
import { AiFillPlayCircle } from 'react-icons/ai'
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import CachedImage from '../../../SharedComponents/js/CachedImage';
import CachedVPImage from '../../../SharedComponents/js/CachedVPImage';

const VPlaylistThumb = (props) => {
    return (
        <div className="poster d-flex justify-content-center">
            <div className='d-flex flex-column'>
                <div className='d-flex'>
                    <div className='border border-2'>
                        <CachedVPImage imageUrl={props.thumb?.[0]} />
                    </div>
                    <div className='border border-2'>
                        <CachedVPImage imageUrl={props.thumb?.[1]} />
                    </div>

                </div>
                <div className='d-flex'>
                    <div className='border border-2'>
                        <CachedVPImage imageUrl={props.thumb?.[2]} />
                    </div>
                    <div className='border border-2'>
                        <CachedVPImage imageUrl={props.thumb?.[3]} />
                    </div>

                </div>
            </div>
            {!props.video_id &&
                <Link className={classNames(
                    'btn btn-light border-1 position-absolute m-auto styling p-0 d-flex justify-content-center'
                )}>
                    <AiFillPlayCircle color='#5644de' className='iconStlye m-auto' />
                </Link>
            }
            {props.video_id && <Link to={`/video/${props.video_id}`}
                state={{ is_private: props.is_private, type: 'p', playlist_id: props.playlist_id }}
                className={classNames(
                    'btn btn-light border-1 position-absolute m-auto styling p-0 d-flex justify-content-center'
                )}>
                <AiFillPlayCircle color='#5644de' className='iconStlye m-auto' />
            </Link>}
            {/* <span className="v_len position-absolute">{props.v_len} </span> */}
        </div>
    );
};
export default VPlaylistThumb;