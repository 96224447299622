import React, { useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card';
import Header from '../../SharedComponents/js/Header'
import '../css/Landing.css'
import Footer from '../../SharedComponents/js/Footer'
import FeedBackScroller from '../Components/FeedBackScroller';
import { useNavigate, NavLink } from 'react-router-dom';
import VideoViewer from '../../SharedComponents/js/VideoViewer';
import WaitList from '../Components/WaitList';
import { PiNumberCircleEight, PiNumberCircleEightFill, PiNumberCircleFive, PiNumberCircleFiveFill, PiNumberCircleFour, PiNumberCircleFourFill, PiNumberCircleOne, PiNumberCircleOneFill, PiNumberCircleOneThin, PiNumberCircleSeven, PiNumberCircleSevenFill, PiNumberCircleSix, PiNumberCircleSixFill, PiNumberCircleThree, PiNumberCircleThreeFill, PiNumberCircleThreeThin, PiNumberCircleTwo, PiNumberCircleTwoFill, PiNumberCircleTwoThin } from 'react-icons/pi';
import { GiArchiveResearch } from 'react-icons/gi';
import { BiSolidInstitution } from 'react-icons/bi';
import { HiAcademicCap } from 'react-icons/hi';
import { VscDebugBreakpointData, VscError } from 'react-icons/vsc';
import { IoIosArrowForward } from 'react-icons/io';
import { FaCheckCircle } from 'react-icons/fa';

const Landing = () => {
    const navigate = useNavigate()
    const [showWaitList, setShow] = useState(false);
    const [darkHeader, set_darkHeader] = useState(true)
    const handleShow = () => setShow(true);
    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);

        return () =>
            window.removeEventListener('scroll', listenScrollEvent);
    }, []);
    const listenScrollEvent = (event) => {
        if (window.scrollY < 73) {
            return set_darkHeader(true)
        } else if (window.scrollY > 70) {
            return set_darkHeader(false)
        }
    }
    return (
        <div>
            <Header dark={darkHeader} />
            <div className='landing-container'>
                <div className='px-5 pt-5 first-sec-container'>
                    {/* <div className='first-sec'> */}
                    <div className='mt-5 mt-md-5 m-lg-0 p-0 row justify-content-center'>
                        <div className='mt-5 py-5 first-sec-items'>
                            <span className='text-center pb-lg-5 pb-md-5 pb-3 pt-lg-5 slogan'>Research Reimagined: AI-generated research videos</span>
                            <p className='text-style'>
                                {/* Be a part of our academic community operated by artificial intelligence.
                         Together, let us distribute our research publications in multimedia format 
                         with millions of researchers from all over the world.
                          */}

                                Transform Your Research Publications into Engaging 1-min Videos with Generateive AI: Upload paper, AI generate video, You Customize, and Share with ResearchTube.Ai community or on social media, your website or conferences. Skyrocket Your Impact!
                            </p>
                            {/* <button
                                onClick={() => {
                                    // handleShow()
                                    navigate('/sign-up')

                                }}
                                className='w-auto mt-4 px-5 py-3 btn-free'>Try for free!</button> */}

                            <img onClick={() => {
                                // handleShow()
                                navigate('/sign-up')

                            }}
                                role='button' className='col-8 col-md-6 col-lg-6 col-xxl-4 mt-4 px-5 pt-3 free-promocode' src='/assets/freePromocode.png' />
                        </div>
                    </div>
                    {/*{/* <img className='mockup-img mt-5 col-12 col-md-9' src='/assets/mockPhoto.png' /> */}
                    {/* <div className='mockup-img mt-5 col-12 col-md-8'>
                        <VideoViewer type='normal' thumb={'/assets/thumb.png'} link={'/assets/Website_video.mp4'} />
                    </div> */}
                    {/* {/* </div> */}


                </div>
                <div className='mokup_sec'>
                    <img className='mockup-img mt-5 col-12 col-md-9' src='/assets/mockPhoto.png' />
                </div>
                {/* <img className='d-none d-md-flex mokup_sec mt-5 col-12 col-md-12' src='/assets/subtract.png' /> */}
                {/* edit mb-lg-4  */}
                <div className='w-100 p-5 second-sec-container'>
                    <div className='row m-0 p-5 align-items-center justify-content-center'>
                        <div className='col-12 col-xxl-5 col-md-6 col-lg-6'>
                            <img className='second-sec-img' src="/assets/res2video.gif" /></div>
                        <div className='second-sec-text col-xxl-5 col-12 mt-md-0 mt-5 col-lg-6 col-md-6'>
                            {/* <div className='line' ></div> */}
                            <span className=' text-start mt-5 second-sec-t'>
                                Revolutionize the Way You Share Knowledge
                            </span>
                            <small className='mt-3 text-justified '>
                                {/* Transform PDF publications into videos effortlessly, 
                            with no need of video making experience. */}
                                Supercharge your research impact with AI-powered videos! Summarize complex findings with captivating visuals that grab attention, boost shareability, and make your work shine in conferences and online platforms.
                            </small>
                            <div className='d-flex mt-lg-5 justify-content-center'>
                                <button
                                    onClick={() => {
                                        // handleShow()
                                        navigate('/sign-up')
                                    }}
                                    className='btn-transform'>Summarize Your Research</button>
                            </div>
                        </div>
                    </div>
                    {/* <div className='mt-5 px-5 py-2 d-flex flex-column flex-lg-row align-items-center'>
                        <div className='p-5 col-12 mt-md-0 mt-5 col-lg-6 col-md-6'>
                            <div className='line mb-5' ></div>
                            <span className='d-flex flex-column justify-content-center align-items-start'>
                                <strong> <h4 className='fw-bold'> Join a Growing Academic Community Powered by AI </h4></strong>
                                <small className='small-color-txt text-start'> Our platform empowers professors, undergraduate and graduate students, postdocs, university staff, and R&D researchers in industry to transform complex research articles into captivating videos using AI. Let's work together to build this innovative community where learning and discovery are brought to life through the power of AI. Together, let's harness the power of AI to create a thriving academic community where knowledge and innovation flourish. Join us on this exciting journey!</small>

                                <div className='d-flex mt-lg-5 justify-content-center'>
                                    <button
                                        onClick={() => {
                                          
                                            navigate('/sign-up')
                                        }}
                                        className='btn-transform'>Join our Community Now!</button>
                                </div>
                </span>
            </div>

            <div className='share-img-container col-md-6 col-12'>
                <img className='share-img' src='/assets/community.png' />
            </div>
        </div> */}
                </div >

                <div className='w-100 why-us-style row m-0 p-5 pt-0'>
                    <div className='pt-5 forth-sec d-flex align-items-center justify-content-center' id="features-container">
                        <div className='forth-sec-t-container col-md-12 d-flex flex-column align-items-center justify-content-center'>
                            <p className='forth-sec-t text-center'>Research Video Revolution: Paper to video - Increase your Academic Impact</p>
                            {/* <p className='w-75 text-center small-color-txt'>
                                    <small>Easily convert complex research publications into visually appealing and easy to understand video summaries using AI. Make your work understandable to a wider audience.
                                    </small></p> */}
                            {/* <p className='mt-5 forth-sce-cont'>
                                     Commend your research breakthroughs with millions 
                                of researchers worldwide, which will raise awareness 
                                about your research publications and establish communication channels.
                                </p>
                                 */}


                            <div className='cards-container'>
                                <div className='px-5 py-3 third-sec-cards flex-lg-row flex-column'>
                                    {/* <Card className='p-2 p-xl-2 p-md-1 col-10 col-md-6 col-lg-3 card-style'>
                                        <Card.Body className='p-2 p-xl-2 p-md-1'>
                                            <Card.Title className='m-0 p-0 card-title-style'>
                                                <p className='border-0 m-0 p-1 card-tag'> */}
                                    {/* <PiNumberCircleOne size={25} color='#5956E9' />  */}
                                    {/* </p>
                                                <p className='m-0 p-0 mt-lg-1 card-heading theme-color-txt'>Simplify Your Research Communication</p>
                                            </Card.Title>
                                            <Card.Text className='m-0 p-0 card-content'>
                                                Easily convert complex research publications into visually appealing and easy to understand video summaries using AI. Make your work understandable to a wider audience.
                                            </Card.Text> */}
                                    {/* <div className='mt-2 border border-bottom border-1'></div>
                                        <div className='card-footer'>
                                            <img className='footer-icon' src="/assets/sumicon.svg" />
                                            <button onClick={() => {
                                                navigate('/SignUp')
                                            }} className='footer-btn' >Get Started</button>
                                        </div> */}
                                    {/* </Card.Body>
                                    </Card> */}
                                    <Card className='p-2 p-xl-2 p-md-1 col-9 col-sm-7 col-md-7 col-md-6 col-lg-3 col-xxl-3 card-style'>
                                        <Card.Body className='p-1 p-xl-2 p-md-1'>
                                            <Card.Title className='m-0 pt-2 pb-3 px-0 card-title-style'>
                                                <p className='border-0 m-0 p-1 card-tag'>
                                                    {/* <PiNumberCircleTwo size={25} color='#5956E9' />  */}
                                                    <img className='footer-icon' src="/assets/customize.svg" />
                                                </p>
                                                <p className='m-0 p-0 mt-lg-1  card-heading theme-color-txt'>AI-Powered Video Creation
                                                </p>
                                            </Card.Title>
                                            <Card.Text className='my-xxl-3 pt-xxl-2 pb-xxl-4 m-0 pt-1 pb-5 card-content'>
                                                Transform complex research into engaging videos effortlessly using generative AI.
                                            </Card.Text>
                                            {/* <div className='mt-2 border border-bottom border-1'></div>
                                        <div className='card-footer'>
                                            <img className='footer-icon' src="/assets/parseIcon.svg" />
                                            <button onClick={() => {
                                                navigate('/SignUp')
                                            }} className='footer-btn' >Get Started</button>
                                        </div> */}
                                        </Card.Body>
                                    </Card>
                                    <Card className='p-2 p-xl-2 p-md-1 col-9 col-sm-7 col-md-7 col-md-6 col-lg-3 col-xxl-3 card-style'>
                                        <Card.Body className='p-1 p-xl-2 p-md-1'>
                                            <Card.Title className='m-0 pt-2 pb-3 px-0 card-title-style'>
                                                <p className='border-0  m-0 p-1 card-tag'>
                                                    {/* <PiNumberCircleThree size={25} color='#5956E9' />  */}
                                                    <img className='footer-icon' src="/assets/customize2.svg" />
                                                </p>
                                                <p className='m-0 p-0 mt-lg-1 card-heading theme-color-txt'>Customization to Match Your Vision
                                                </p>
                                            </Card.Title>
                                            <Card.Text className='my-xxl-3 pt-xxl-1 pb-xxl-4 m-0 pt-2 pb-4 card-content'>
                                                Tailor videos to highlight key aspects of your research, maintaining control over the presentation.
                                            </Card.Text>
                                            {/* <div className='mt-2 border border-bottom border-1'></div>
                                        <div className='card-footer'>
                                            <img className='footer-icon' src="/assets/parseIcon.svg" />
                                            <button onClick={() => {
                                                navigate('/SignUp')
                                            }} className='footer-btn' >Get Started</button>
                                        </div> */}
                                        </Card.Body>
                                    </Card>
                                    <Card className='p-2 p-xl-2 p-md-1 col-9 col-sm-7 col-md-7 col-md-6 col-lg-3 col-xxl-3 card-style'>
                                        <Card.Body className='p-1 p-xl-2 p-md-1'>
                                            <Card.Title className='m-0 pt-2 pb-3 px-0 card-title-style'>
                                                <p className='border-0 m-0 p-1 card-tag'>
                                                    {/* <PiNumberCircleFour size={25} color='#5956E9' />  */}
                                                    <img className='footer-icon' src="/assets/generateIcon.svg" />
                                                </p>
                                                <p className='m-0 p-0 mt-lg-1 card-heading theme-color-txt'>Professional Visual Appeal
                                                </p>
                                            </Card.Title>
                                            <Card.Text className='pt-xxl-2 pb-xxl-3 m-2 p-0 card-content'>
                                                Ensure visually stunning and professionally crafted videos that are ready to share immediately over social media, websites, conferences.

                                            </Card.Text>
                                            {/* <div className='mt-2 border border-bottom border-1'></div>
                                        <div className='card-footer'>
                                            <img className='footer-icon' src="/assets/extractIcon.svg" />
                                            <button onClick={() => {
                                                navigate('/SignUp')
                                            }} className='footer-btn' >Get Started</button>
                                        </div> */}
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='px-5 third-sec-cards flex-lg-row flex-column'>
                                    {/* <Card className='p-2 p-xl-2 p-md-1 col-10 col-md-6 col-lg-3 card-style'>
                                        <Card.Body className='p-2 p-xl-2 p-md-1'>
                                            <Card.Title className='m-0 p-0 px-0 card-title-style'> */}
                                    {/* <p className='border-0 m-0 p-1 card-tag'><PiNumberCircleFive size={25} color='#5956E9' /> </p> */}
                                    {/* <p className='m-0 p-0 mt-lg-1 card-heading theme-color-txt'>Expand Your Reach
                                                </p>
                                            </Card.Title>
                                            <Card.Text className='m-1 p-0 card-content'>
                                                Engage with a growing community of academics, professors, students, and professionals who are interested in cutting-edge research across various fields.

                                            </Card.Text> */}

                                    {/* <div className='mt-2 border border-bottom border-1'></div>
                                        <div className='card-footer'>
                                            <img className='footer-icon' src="/assets/generateIcon.svg" />
                                            <button onClick={() => {
                                                navigate('/SignUp')
                                            }} className='footer-btn' >Get Started</button>
                                        </div> */}
                                    {/* </Card.Body>
                                    </Card>
                                    <Card className='p-2 p-xl-2 p-md-1 col-10 col-md-6 col-lg-3 card-style'>
                                        <Card.Body className='p-2 p-xl-2 p-md-1'>
                                            <Card.Title className='m-0 p-0 px-0 card-title-style'> */}
                                    {/* <p className='border-0 m-0 p-1 card-tag'><PiNumberCircleSix size={25} color='#5956E9' /> </p> */}
                                    {/* <p className='m-0 p-0 mt-lg-1 card-heading theme-color-txt'>Learn from Others </p> */}
                                    {/* </Card.Title>
                                            <Card.Text className='m-1 p-0 card-content'>
                                                Watch and discover videos from researchers across the world, expanding your knowledge and fostering interdisciplinary collaborations.

                                            </Card.Text> */}
                                    {/* <div className='mt-2 border border-bottom border-1'></div>
                                        <div className='card-footer'>
                                            <img className='footer-icon' src="/assets/shareIcon.svg" />
                                            <button onClick={() => {
                                                navigate('/SignUp')
                                            }} className='footer-btn' >Get Started</button>
                                        </div> */}
                                    {/* </Card.Body>
                                    </Card> */}
                                    <Card className='p-2 p-xl-2 p-md-1 col-9 col-sm-7 col-md-7 col-md-6 col-lg-3 col-xxl-3 card-style'>
                                        <Card.Body className='p-1 p-xl-2 p-md-1'>
                                            <Card.Title className='m-0 pt-2 pb-3 px-0 card-title-style'>
                                                <p className='border-0 m-0 p-1 card-tag'>
                                                    {/* <PiNumberCircleSeven size={25} color='#5956E9' />  */}
                                                    <img className='footer-icon' src="/assets/shareIcon.svg" />

                                                </p>
                                                <p className='m-0 p-0 mt-lg-1 card-heading theme-color-txt'>Expand Your Reach

                                                </p>
                                            </Card.Title>
                                            <Card.Text className='m-1 pt-xxl-2 pb-xxl-5 pt-2 pb-4 card-content'>

                                                Connect with a diverse academic community, fostering interdisciplinary collaborations and knowledge exchange.

                                            </Card.Text>
                                            {/* <div className='mt-2 border border-bottom border-1'></div>
                                        <div className='card-footer'>
                                            <img className='footer-icon' src="/assets/parseIcon.svg" />
                                            <button onClick={() => {
                                                navigate('/SignUp')
                                            }} className='footer-btn' >Get Started</button>
                                        </div> */}
                                        </Card.Body>
                                    </Card>
                                    <Card className='p-2 p-xl-2 p-md-1 col-9 col-sm-7 col-md-7 col-md-6 col-lg-3 col-xxl-3 card-style'>
                                        <Card.Body className='p-1 p-xl-2 p-md-1'>
                                            <Card.Title className='m-0 pt-2 pb-3 px-0 card-title-style'>
                                                <p className='border-0 m-0 p-1 card-tag'>
                                                    {/* <PiNumberCircleEight size={25} color='#5956E9' />  */}
                                                    <img className='footer-icon' src="/assets/sumicon.svg" />

                                                </p>
                                                <p className='m-0 p-0 mt-lg-1 card-heading theme-color-txt'>Save Time and Resources
                                                </p>
                                            </Card.Title>
                                            <Card.Text className='pt-xxl-2 pb-xxl-5 m-2 mb-xxl-3 pt-1 pb-4 card-content'>

                                                Upload your paper and receive a ready-to-share video in minutes, saving valuable time and resources.
                                            </Card.Text>
                                            {/* <div className='mt-2 border border-bottom border-1'></div>
                                        <div className='card-footer'>
                                            <img className='footer-icon' src="/assets/parseIcon.svg" />
                                            <button onClick={() => {
                                                navigate('/SignUp')
                                            }} className='footer-btn' >Get Started</button>
                                        </div> */}
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>


                            {/* <div className='mt-3 d-flex flex-lg-row flex-column align-items-start justify-content-start'>
                                <div className='me-lg-3 col-12 col-md-3 d-flex flex-column justify-content-center align-items-center'> <div className='mb-2'><PiNumberCircleOneFill size={40} color='#5956E9' />  </div><span className='d-flex flex-column justify-content-center align-items-center'><strong className='text-center'> Simplify Your Research Communication</strong><p className='smal-txt-size small-color-txt text-center'> Easily convert complex research publications into visually appealing and easy to understand video summaries using AI. Make your work understandable to a wider audience. </p></span></div>
                                <div className='me-lg-3 col-12 col-md-3 d-flex flex-column justify-content-center align-items-center'> <div className='mb-2'><PiNumberCircleTwoFill size={40} color='#5956E9' />  </div><span className='d-flex flex-column justify-content-center align-items-center'><strong className='text-center'>AI-Powered Video Creation</strong><p className='smal-txt-size small-color-txt text-center'>  No technical skills or video editing knowledge required! Our advanced AI system processes complex research and transforms them into visually appealing video summaries in minutes.</p></span></div>
                                <div className='me-lg-3 col-12 col-md-3 d-flex flex-column justify-content-center align-items-center'> <div className='mb-2'><PiNumberCircleThreeFill size={40} color='#5956E9' /> </div><span className='d-flex flex-column justify-content-center align-items-center'><strong className='text-center'>Customization to Match Your Vision</strong><p className='smal-txt-size small-color-txt text-center'>   You’re in control! Customize your video to emphasize — whether it's groundbreaking results, key methodologies, or innovative approaches.</p></span></div>
                                <div className='col-12 col-md-3 d-flex flex-column justify-content-center align-items-center'>         <div className='mb-2'><PiNumberCircleFourFill size={40} color='#5956E9' /> </div><span className='d-flex flex-column justify-content-center align-items-center'><strong className='text-center'>Visually Stunning</strong><p className='smal-txt-size small-color-txt text-center'>   We don’t just condense your research — we elevate it. Our AI ensures that your video looks and sounds professional, so it’s ready to share right away.</p></span></div>
                            </div>
                            <div className='mt-3 d-flex flex-lg-row flex-column align-items-start justify-content-start'>
                                <div className='me-lg-3 col-12 col-md-3 d-flex flex-column align-items-center justify-content-center'><div className='mb-2'><PiNumberCircleFiveFill size={40} color='#5956E9' /> </div><span className='d-flex flex-column justify-content-center align-items-center'><strong className='text-center'>Expand Your Reach</strong><p className='smal-txt-size small-color-txt text-center'> Engage with a growing community of academics, professors, students, and professionals who are interested in cutting-edge research across various fields.</p></span></div>
                                <div className='me-lg-3 col-12 col-md-3 d-flex flex-column align-items-center justify-content-center'><div className='mb-2'><PiNumberCircleSixFill size={40} color='#5956E9' />  </div><span className='d-flex flex-column justify-content-center align-items-center'><strong className='text-center'>Learn from Others</strong><p className='smal-txt-size small-color-txt text-center'>  Watch and discover videos from researchers across the world, expanding your knowledge and fostering interdisciplinary collaborations.</p></span></div>
                                <div className='me-lg-3 col-12 col-md-3 d-flex flex-column align-items-center justify-content-center'><div className='mb-2'><PiNumberCircleSevenFill size={40} color='#5956E9' /></div><span className='d-flex flex-column justify-content-center align-items-center'><strong className='text-center'>Boost Your Impact</strong><p className='smal-txt-size small-color-txt text-center'> Share your videos with the academic community, on social media, and beyond. Make sure your research reaches more eyes and minds.</p></span></div>
                                <div className='col-12 col-md-3 d-flex flex-column align-items-center justify-content-center'>        <div className='mb-2'><PiNumberCircleEightFill size={40} color='#5956E9' /></div><span className='d-flex flex-column justify-content-center align-items-center'><strong className='text-center'>Save Time and Resources</strong><p className='smal-txt-size small-color-txt text-center'> Upload your paper, sit back, and let our AI do the hard work. Your video is ready in just minutes.</p></span></div>
                            </div>
                            <div className='mt-3 d-flex flex-lg-row flex-column align-items-start justify-content-start'>
                            </div> */}
                        </div>

                    </div>
                </div>
                <div className='py-5 share-and-user-types d-flex flex-column align-items-center justify-content-evenly'>
                    {/* <div className='d-flex flex-lg-row flex-column align-items-start justify-content-between px-5'>
                        <span className='mb-lg-0 mb-md-0 mb-5 col-12 col-lg-6 d-flex flex-column justify-content-center align-items-start'>
                            <strong>
                                <h4 className='fw-bold'>  Share and Distribute Anywhere </h4>
                            </strong>
                            <small className='w-75 small-color-txt text-start'> Our videos are optimized for sharing across a range of platforms, making it easy for you to reach your audience wherever they are.</small>

                        </span>
                        <div className='col-12 col-lg-6 '>
                            <div className='item-list-style d-flex align-items-center '>
                                <IoIosArrowForward className='me-3' size={25} color='#FFFFFFF' />
                                <span className='d-flex flex-column'>
                                    <strong>Social media Ready</strong>
                                    <small className=''> Share your video directly to platforms like Twitter, LinkedIn, or YouTube to maximize visibility.</small>
                                </span>
                            </div>
                            <div className='item-list-style mt-2 d-flex justify-content-center align-items-center '>
                                <IoIosArrowForward className='me-3' size={35} color='#FFFFFFF' />
                                <span className='d-flex flex-column'>
                                    <strong>Embed on Websites</strong>
                                    <small className=''>  Use the video to enhance your personal or institutional website, making your research more accessible to a wider audience.
                                    </small>
                                </span>
                            </div>
                            <div className='item-list-style mt-2 d-flex align-items-center '>
                                <IoIosArrowForward className='me-3' size={35} color='#FFFFFFF' />
                                <span className='d-flex flex-column'>
                                    <strong>Presentation-Friendly</strong>
                                    <small className=''>
                                        Ideal for academic presentations, conferences, or seminars, our videos help communicate complex ideas quickly and clearly.
                                    </small>
                                </span>
                            </div>
                        </div>
                    </div> */}
                    <div className='d-flex justify-content-center'>
                        <div className='pt-5 mt-5 fifth-sec'>
                            <h3> <strong className='col-12 text-center col-md-6'>Designed for Researchers, by Researchers</strong></h3>
                            <p className='small-color-txt text-center col-10 col-md-8'>
                                We know what researchers need because we’ve built our platform with your workflow in mind. Whether you're an individual academic or part of a larger institution, ResearchTube.Ai is here to help you communicate your research more effectively.
                            </p>
                            <div className='m-0 py-4 flex-column flex-lg-row flex-md-row align-items-center stat-container'>

                                <div className='m-0 mt-lg-0 mt-md-0 mt-3 d-flex flex-column align-items-start col-md-3 col-lg-3 col-6 col-xxl-3'>
                                    <div className='user-type-style p-3 row m-0 border border-1 rounded d-flex flex-column justify-content-end align-items-start'>
                                        {/* <div className='user-content-style'> */}
                                        <div className='d-flex align-items-center mt-xxl-2 mb-xxl-3 my-3 '>
                                            <GiArchiveResearch color='#5956E9' size={30} />
                                            <strong className='ms-2 text-dark'>For Researchers</strong>
                                        </div>
                                        <div className='d-flex flex-column justify-content-end align-items-start'>
                                            <small className='pt-xxl-2 pb-xxl-3'>Share your work in a way that captivates audiences and encourages engagement.</small>
                                        </div>
                                        {/* </div> */}
                                    </div>
                                </div>
                                <div className='m-0 mt-lg-0 mt-md-0 mt-3 d-flex flex-column align-items-start col-md-3 col-lg-3 col-6 col-xxl-3 ms-0 ms-lg-5 ms-md-5'>
                                    <div className='user-type-style p-3 row m-0 border border-1 rounded d-flex flex-column justify-content-end align-items-start'>
                                        <div className='d-flex align-items-center my-3'>
                                            <HiAcademicCap color='#5956E9' size={30} />
                                            <strong className='ms-2'>For Educators </strong>
                                        </div>
                                        <small className='mb-4'>Use video summaries to help students grasp complex topics more quickly.</small>
                                    </div>
                                </div>
                                <div className='m-0 mt-lg-0 mt-md-0 mt-3 d-flex flex-column align-items-start col-md-3 col-lg-3 col-6 col-xxl-3 ms-0 ms-lg-5 ms-md-5'>
                                    <div className='user-type-style p-3 row m-0 border border-1 rounded d-flex flex-column justify-content-end align-items-start'>
                                        <div className='d-flex align-items-center my-3'>
                                            <BiSolidInstitution color='#5956E9' size={30} />
                                            <strong className='ms-2'>For Institutions</strong>
                                        </div>
                                        <small className='mb-1'>Showcase research breakthroughs with professional video summaries that enhance your institution’s reputation.</small>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                {/* <div className='mt-5 mt-md-0 mt-lg-0 third-sec-container' >
                    <div className='p-5 third-sec d-flex flex-column'>
                        <p className='my-5 third-sec-t'>Features You’ll Love</p>
                        
                    </div>
                </div> */}
                {/* <div className='forth-sec-container d-flex justify-content-center align-items-center' id='howItWorks-container'>
                    <div className='row m-0 p-5 d-flex justify-content-center align-items-center'>
                        <span className='col-md-6'>
                            <div className='mb-lg-0 mb-md-0 mb-4 d-flex flex-column align-items-center'>
                                <h2 className='text-center'>
                                    <strong>How It Works? </strong>
                                </h2>
                                <strong className='text-center'>Simple Steps</strong>
                            </div>
                        </span>
                        <div className='col-md-5 col-12'>
                            <div className='step_no position-relative row m-0'>
                                <div className='col-1 col-md-1'>
                                    <PiNumberCircleOneThin size={30} color='#FFFFFF' />
                                </div>
                                <div className='col-10 ms-2 d-flex flex-column col-md-8'>
                                    <strong>Upload Your Paper</strong>
                                    <small className='mt-1'> Just drag and drop your research article (PDF) in any field, and our AI will automatically process it.</small>
                                </div>
                            </div>
                            <div className='mt-4 position-relative step_no row m-0'>
                                <div className='col-1 col-md-1'>
                                    <PiNumberCircleTwoThin size={30} color='#FFFFFF' /></div>
                                <div className='col-10 ms-2 col-md-8 d-flex flex-column'>
                                    <strong >
                                        AI-Generated Video
                                    </strong>
                                    <small>Watch as AI creates a 1-minute video summarizing your key findings and beautifully crafts a video summarizing your research.</small>
                                </div>
                            </div>
                            <div className='mt-3 row m-0'>
                                <div className='col-1 col-md-1'>
                                    <PiNumberCircleThreeThin size={30} color='#FFFFFF' />
                                </div>
                                <div className='col-10 ms-2 col-md-8 d-flex flex-column'>
                                    <strong>Customize & Share</strong>
                                    <small> Preview the video and make any necessary adjustments, then share it with your audience!</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}


                <div className='fifth-sec-container'>

                    {/* <div className='sixth-sec-container'>
                    <div className='sixth-sec'>
                        <div className='mx-4 pt-3 pb-4'>
                            {/* <div className='mt-3 rate-header'>
                                <div className='col-lg-10'><img className='rate-comma' src='/assets/rateComma.png' /></div>
                                <div className='col-lg-auto'>
                                    <p className='p-0 m-0 rate-name'>Jaydon Bator</p>
                                    <p className='rate-place'>University of Toronto</p>
                                </div>
                            </div>
                            <div className='rate-content'>
                                <p className='sixth-sec-t'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>

                            </div>
                            <div className='rate-footer'>
                                <img className='sixth-sec-img-style' src='/assets/rateimg.png' />
                            </div> 
                            <FeedBackScroller />
                        </div>
                    </div>
                </div> */}

                    {/*----------------Pricing -----------*/}

                    {/* --------------------template 1 */}

                    <div className='p-5 mt-5 d-flex flex-column align-items-center' id="pricing-container">
                        <div className='d-flex flex-column align-items-center mb-5'><h3><strong> Simple, Transparent Pricing </strong></h3>
                            <small className='small-color-txt w-75 text-center'>We offer flexible pricing options that fit your needs, whether you're working on a single paper or planning to create video summaries for multiple publications.</small>
                        </div>
                        <div className='p-3 m-0 d-flex flex-lg-row flex-column justify-content-center align-items-center'>
                            <div className='plan-style my-3 my-lg-0 mx-2 p-5 col-xxl-2 col-11 col-md-6 col-sm-7 col-lg-4 col-xxl-3 border border-2 d-flex flex-column'>
                                <div className='my-1 theme-color-txt text-center'><h4><strong> Free Plan</strong></h4> </div>
                                <div className='my-1 small-color-txt text-center'><small>Great for users exploring the platform or consuming educational content</small></div>
                                <div className='my-1 theme-color-txt text-center'><h1><strong> $0</strong></h1></div>
                                <div className='my-1 theme-color-txt text-center'><span><strong> <h2>RTAi24</h2> Free Video Promocode</strong></span></div>
                                <div className='my-1'>
                                    <div className='my-1 theme-color-txt' ><FaCheckCircle className='me-1' color='#5644de' /><small>Video Viewing</small></div>
                                    <div className='my-1 theme-color-txt'><FaCheckCircle className='me-1' color='#5644de' /><small>Connect with authors</small></div>
                                    <div className='my-1 theme-color-txt d-flex'><FaCheckCircle className='me-1' color='#5644de' />
                                        <span className='d-flex flex-column'> <small>1-min AI-generated Video</small><small> (with watermarks)</small></span>
                                    </div>
                                </div>
                                <button className='mt-2 reg-button py-2 w-100' onClick={() => {
                                    navigate('/sign-up')

                                }}>Start Now</button>
                            </div>
                            <div className='payas-plan-style my-3 my-lg-0 mx-2 p-5 col-xxl-2 col-11 col-sm-7 col-md-6 col-lg-4 col-xxl-3 border border-2 d-flex flex-column'>
                                <div className='my-1 text-center'><h4><strong> Pay-As-You-Go Plan</strong></h4></div>
                                <div className='my-1 text-center'><small>Best suited for users to generate videos without a long-term commitment</small></div>
                                <div className='my-1 text-center'><h1><strong>$50</strong></h1></div>
                                <div>
                                    <div className='my-1'><FaCheckCircle className='me-1' color='#FFFFFF' /><small>Video Viewing</small></div>
                                    <div className='my-1'><FaCheckCircle className='me-1' color='#FFFFFF' /><small>Connect with authors</small></div>
                                    <div className='my-1'><FaCheckCircle className='me-1' color='#FFFFFF' /><small>1-min AI-generated video</small></div>
                                    <div className='my-1'><FaCheckCircle className='me-1' color='#FFFFFF' /><small>AI Summaries</small></div>
                                    <div className='my-1'><FaCheckCircle className='me-1' color='#FFFFFF' /><small>AI Stock Video Matching</small></div>
                                    <div className='my-1'><FaCheckCircle className='me-1' color='#FFFFFF' /><small>Subtitles</small></div>
                                    {/* <div className='my-1'><FaCheckCircle className='me-1' color='#FFFFFF' /><small>Metadata & SEO</small></div> */}
                                    {/* <div className='my-1'><FaCheckCircle className='me-1' color='#FFFFFF' /><small>Custom Branding</small></div> */}
                                </div>
                                <button className='mt-4 reg-button py-2 w-100' onClick={() => {
                                    navigate('/sign-up')

                                }}>Start Now</button>
                            </div>
                            <div className='plan-style my-3 my-lg-0 mx-2 p-5 col-xxl-2 col-11 col-sm-7 col-md-6 col-lg-4 col-xxl-3 border border-2 d-flex flex-column'>
                                <div className='my-1 theme-color-txt text-center'><h4><strong>Enterprise plan</strong></h4></div>
                                <div className='my-1 small-color-txt text-center'><small>Ideal for institutions to generate large-scale branded videos.</small></div>
                                <div className='my-1 theme-color-txt text-center'><h2><strong>let's talk</strong></h2></div>
                                <div className='my-xxl-4 my-2'>
                                    <div className='my-1 theme-color-txt'><FaCheckCircle className='me-1' color='#5644de' /><small>Private community</small></div>
                                    <div className='my-1 theme-color-txt'><FaCheckCircle className='me-1' color='#5644de' /><small>Unlimited AI-generated videos</small></div>
                                    <div className='d-flex my-1 theme-color-txt'><FaCheckCircle className='me-1' color='#5644de' />
                                        <span className='d-flex flex-column'><small>AI Summaries using</small><small> fine-tuned LLMs</small></span></div>
                                    <div className='my-1 theme-color-txt'><FaCheckCircle className='me-1' color='#5644de' /><small>Advanced Metadata & SEO</small></div>
                                    <div className='my-1 theme-color-txt'><FaCheckCircle className='me-1' color='#5644de' /><small>Institutional Branding</small></div>
                                </div>
                                <button className='mt-4 reg-button py-2 w-100' onClick={() => {
                                    navigate('/sign-up')

                                }}>Contact Us</button>
                            </div>
                        </div>
                    </div>

                    {/* --------------------template 2-------------------------------- */}
                    {/* <div className='p-5 mt-5 d-flex flex-column align-items-center' id="pricing-container">
                        <div className='d-flex flex-column align-items-center mb-5'><h3><strong> Simple, Transparent Pricing </strong></h3>
                            <small className='small-color-txt w-75 text-center'>We offer flexible pricing options that fit your needs, whether you're working on a single paper or planning to create video summaries for multiple publications.</small>
                        </div>
                        <div className='d-flex flex-column'>
                            <div className='row m-0 p-0'>
                                <div className='rounded-top-left d-flex flex-wrap justify-content-center align-items-center border-2 border m-0 p-0 col-md-3'>
                                    <p className='text-center theme-color-txt'><strong>Features</strong></p>
                                </div>

                                <div className='m-0 p-3 border-2 border border-start-0 col-md-3'>
                                    <div className='m-0 p-0 d-flex flex-column justify-content-center align-items-center'>
                                        <h5 className='text-center theme-color-txt mx-3 mt-2'>
                                            <strong>Free Plan</strong>
                                        </h5>
                                        <small className='text-center mx-3 small-color-txt'>Great for users exploring the platform or consuming educational content but not involved in content creation.</small>
                                    </div>
                                </div>
                                <div className='pricing-bk-color m-0 p-3 border-2 border border-bottom-0 border-start-0 col-12 col-md-3'>
                                    <div className='m-0 p-0 d-flex flex-column justify-content-center align-items-center'>
                                        <h5 className=' text-light text-center mx-3 mt-2'> <strong>Pay-As-You-Go Plan</strong></h5>
                                        <small className='text-light text-center mx-3'>Best suited for academics who need to generate video content occasionally, offering flexibility without a long-term commitment.</small>
                                    </div>

                                </div>
                                <div className='rounded-top-right m-0 p-0 border-2 border border-start-0 col-12 col-md-3'>
                                    <div className='m-0 p-3 d-flex flex-column justify-content-center align-items-center'>
                                        <h5 className='text-center theme-color-txt mx-3 mt-2'><strong>Enterprise plan</strong></h5>
                                        <small className='text-center mx-3 small-color-txt'>Ideal for institutions needing large-scale video production, with tailored services and premium features to support e-learning, research dissemination, and branded content creation.</small>
                                    </div>

                                </div>
                            </div>
                            <div className='row m-0 p-0'>
                                <div className='border border-top-0 d-flex justify-content-center align-items-center border-2 border-end p-3 col-md-3'>
                                    <p className='text-center theme-color-txt'><strong>Price</strong></p></div>
                                <div className='border border-top-0 border-start-0 d-flex justify-content-center align-items-center border-2 border-end p-3 text-center col-md-3 small-color-txt'>Free</div>
                                <div className='pricing-bk-color d-flex justify-content-center align-items-center border-2 border-end p-3 text-center col-md-3 text-light'>$100 per 1-min video</div>
                                <div className='border border-top-0 border-start-0 border-2 d-flex justify-content-center align-items-center text-center col-md-3 small-color-txt'>Contact for pricing</div>
                            </div>

                            <div className='row m-0 p-0'>
                                <div className='border-2 border border-top-0 p-3 col-md-3'><p className='theme-color-txt text-center'><strong>Video Viewing</strong></p></div>
                                <div className='border-2 border border-top-0 border-start-0 p-3 col-md-3 d-flex justify-content-center align-items-center'><FaCheckCircle className='me-1' color='#5644de' /></div>
                                <div className='pricing-bk-color border-2 border-end p-3 col-md-3 d-flex justify-content-center align-items-center'>
                                    <FaCheckCircle className='me-1' color='#FFFFFF' /></div>
                                <div className='border-2 border border-top-0 border-start-0 col-md-3 p-3 d-flex justify-content-center align-items-center'>
                                    <FaCheckCircle className='me-1' color='#5644de' /></div>

                            </div>
                            <div className='row m-0 p-0'>
                                <div className='border-2 border border-top-0 p-3 col-md-3'><p className='theme-color-txt text-center'><strong>Connect and communicate with authors</strong></p></div>
                                <div className='border-2 border border-top-0 border-start-0 p-3 col-md-3 d-flex justify-content-center align-items-center'><FaCheckCircle className='me-1' color='#5644de' /></div>
                                <div className='pricing-bk-color border-2 border-end p-3 col-md-3 d-flex justify-content-center align-items-center'>
                                    <FaCheckCircle className='me-1' color='#FFFFFF' /></div>
                                <div className='border-2 border border-top-0 border-start-0 col-md-3 p-3 d-flex justify-content-center align-items-center'>
                                    <FaCheckCircle className='me-1' color='#5644de' /></div>

                            </div>
                            <div className='row m-0 p-0'>
                                <div className='border-2 border border-top-0 p-3 col-md-3'><p className='theme-color-txt text-center'><strong>Video Creation</strong></p></div>
                                <div className='text-center border-2 border border-top-0 border-start-0 p-3 col-md-3'>
                                    <div className='d-flex flex-column justify-content-center align-items-center'>
                                        <FaCheckCircle className='me-1' color='#5644de' />
                                        <small className='small-color-txt'>Free Sample Video (10 sec)</small>
                                    </div></div>
                                <div className='pricing-bk-color text-center border-2 border-end p-3 col-md-3'>
                                    <div className='d-flex flex-column justify-content-center align-items-center'>
                                        <FaCheckCircle className='me-1' color='#FFFFFF' />
                                        <small className='text-light'>(1-min AI-generated video)</small>
                                    </div></div>
                                <div className='border-2 border border-top-0 border-start-0 text-center p-3 col-md-3'>
                                    <div className='d-flex flex-column justify-content-center align-items-center'>
                                        <FaCheckCircle className='me-1' color='#5644de' /><small className='small-color-txt'>Unlimited</small>
                                    </div></div>

                            </div>
                            <div className='row m-0 p-0'>
                                <div className='border-2 border border-top-0 p-3 col-md-3'><p className='theme-color-txt text-center'><strong>AI Summaries</strong></p></div>
                                <div className='text-center border-2 border border-top-0 border-start-0 p-3 col-md-3'><VscError color='red' /></div>
                                <div className='pricing-bk-color text-center border-2 border-end p-3 col-md-3'>
                                    <FaCheckCircle className='me-1' color='#FFFFFF' /></div>
                                <div className='border border-top-0 border-start-0 border-2 text-center d-flex flex-column justify-content-center align-items-center p-3 col-md-3'>
                                    <FaCheckCircle className='me-1' color='#5644de' />
                                    <small className='small-color-txt'>(custom & bulk)</small></div>
                            </div>
                            <div className='row m-0 p-0'>
                                <div className='border-2 border border-top-0 p-3 col-md-3'><p className='theme-color-txt text-center'><strong>AI Stock Video Matching</strong></p></div>
                                <div className='text-center border-2 border border-top-0 border-start-0 p-3 col-md-3'><VscError color='red' /></div>
                                <div className='pricing-bk-color text-center border-2 border-end p-3 col-md-3'>
                                    <FaCheckCircle className='me-1' color='#FFFFFF' /></div>
                                <div className='border border-top-0 border-start-0 border-2 d-flex flex-column justify-content-center align-items-center text-center col-md-3'><FaCheckCircle className='me-1' color='#5644de' />
                                    <small className='small-color-txt'>(custom stock footage)</small></div>
                            </div>
                            <div className='row m-0 p-0'>
                                <div className='border-2 border border-top-0 p-3 col-md-3'><p className='theme-color-txt text-center'><strong>Subtitles</strong></p></div>
                                <div className='text-center border-2 border border-top-0 border-start-0 p-3 col-md-3'><VscError color='red' /></div>
                                <div className='pricing-bk-color text-center border-2 border-end p-3 col-md-3'><FaCheckCircle className='me-1' color='#FFFFFF' /></div>
                                <div className='border border-top-0 border-start-0 border-2 text-center col-md-3'><FaCheckCircle className='me-1' color='#5644de' /></div>
                            </div>
                            <div className='row m-0 p-0'>
                                <div className='border-2 border border-top-0 p-3 col-md-3'><p className='theme-color-txt text-center'><strong>Metadata & SEO</strong></p></div>
                                <div className='border-2 border border-top-0 border-start-0 p-3 text-center col-md-3'><VscError color='red' /></div>
                                <div className='pricing-bk-color border-2 border-end p-3 text-center col-md-3'>
                                    <div className='d-flex flex-column justify-content-center align-items-center'>
                                        <FaCheckCircle className='me-1' color='#FFFFFF' />
                                        <small className='text-light'>(AI-generated)
                                        </small>
                                    </div>
                                </div>
                                <div className='border border-top-0 border-start-0 border-2 d-flex flex-column justify-content-center align-items-center text-center col-md-3'><FaCheckCircle className='me-1' color='#5644de' />
                                    <small className='small-color-txt'>(advanced)</small></div>
                            </div>
                            <div className='row m-0 p-0'>
                                <div className='rounded-bottom-left border-2 border border-top-0 p-3 col-md-3'><p className='theme-color-txt text-center'><strong>Custom Branding</strong></p></div>
                                <div className='border-2 border border-top-0 border-start-0 p-3 text-center col-md-3'><VscError color='red' /></div>
                                <div className='pricing-bk-color d-flex flex-column justify-content-center align-items-center border-2 border-end p-3 text-center col-md-3'><FaCheckCircle className='me-1' color='#FFFFFF' /></div>
                                <div className='rounded-bottom-right  border border-top-0 border-start-0 border-2 d-flex flex-column justify-content-center align-items-center text-center col-md-3'><FaCheckCircle className='me-1' color='#5644de' />
                                    <small className='small-color-txt'>(institutional)</small></div>
                            </div>

                        </div>

                    </div> */}
                </div>
                <div className='faq-container'>
                    <div className=' d-flex flex-column justify-content-center align-items-center p-5 py-3'>
                        <h4><strong>Frequently Asked Questions</strong></h4>
                    </div>
                    <div className='d-flex flex-column align-items-center '>
                        <div class="w-75 accordion accordion-flush" id="FAQ-container">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        <strong>  Do I need to pay to create an account, and is an academic email required?</strong>
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#FAQ-container">
                                    <div class="small-color-txt accordion-body">
                                        <small>No, creating an account is completely free! However, to join our academic community and ensure a professional environment, you'll need to sign up using a valid academic email (e.g., .edu, .ac, or equivalent). This helps us maintain a trusted network of researchers.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingTwo">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        <strong>  Do I need to know how to make videos?</strong>
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#FAQ-container">
                                    <div class="small-color-txt accordion-body">
                                        <small>Not at all! The AI does the heavy lifting for you. You don’t need any video-making skills or technical expertise. You can simply upload your research paper, and our AI will generate the video automatically. You can also make small edits if you’d like!
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingThree">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        <strong>  Will the video represent my research accurately?</strong>
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#FAQ-container">
                                    <div class="small-color-txt accordion-body">
                                        <small>Absolutely! Our AI is trained to carefully extract and highlight the most important elements of your research, ensuring accuracy and depth.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingFour">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                        <strong>  Can I make edits to the video?</strong>
                                    </button>
                                </h2>
                                <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#FAQ-container">
                                    <div class="small-color-txt accordion-body">
                                        <small>
                                            Yes! You can customize key sections and even change visual elements to make your video match your vision.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingFive">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                        <strong> How long does it take to generate a video summary?</strong>
                                    </button>
                                </h2>
                                <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#FAQ-container">
                                    <div class="small-color-txt accordion-body">
                                        <small>
                                            It only takes a few minutes for our AI to create a polished video summary of your research. Quick, simple, and ready to share in no time!
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-heading6">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse6" aria-expanded="false" aria-controls="flush-collapse6">
                                        <strong> What file types can I use to generate a video summary?</strong>
                                    </button>
                                </h2>
                                <div id="flush-collapse6" class="accordion-collapse collapse" aria-labelledby="flush-heading6" data-bs-parent="#FAQ-container">
                                    <div class="small-color-txt accordion-body">
                                        <small>
                                            You can upload your research in formats like PDF, and our AI will handle the rest, transforming your paper into an engaging video.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-heading7">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse7" aria-expanded="false" aria-controls="flush-collapse7">
                                        <strong> How do I share my videos with others?</strong>
                                    </button>
                                </h2>
                                <div id="flush-collapse7" class="accordion-collapse collapse" aria-labelledby="flush-heading7" data-bs-parent="#FAQ-container">
                                    <div class="small-color-txt accordion-body">
                                        <small>
                                            After your video is generated, you can share it directly with the community on our platform. You can also download or share it on social media, embed it in your website, or even include it in your presentations!
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-heading8">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse8" aria-expanded="false" aria-controls="flush-collapse8">
                                        <strong> Is there a community aspect?</strong>
                                    </button>
                                </h2>
                                <div id="flush-collapse8" class="accordion-collapse collapse" aria-labelledby="flush-heading8" data-bs-parent="#FAQ-container">
                                    <div class="small-color-txt accordion-body">
                                        <small>
                                            Absolutely! You can explore videos from other researchers, follow specific topics or fields of study, and connect with fellow academics. It's a great way to discover new research and network with like-minded people.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-heading9">
                                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse9" aria-expanded="false" aria-controls="flush-collapse9">
                                        <strong> What kind of research can I upload?</strong>
                                    </button>
                                </h2>
                                <div id="flush-collapse9" class="accordion-collapse collapse" aria-labelledby="flush-heading9" data-bs-parent="#FAQ-container">
                                    <div class="small-color-txt accordion-body">
                                        <small>
                                            You can upload any type of research article, whether it’s from the sciences, humanities, technology, or social sciences. Our platform supports a wide range of disciplines, making it easy to share your work no matter your field.
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footer-cont'>

                    <div className='mt-5 seventh-sec-container'>
                        <div className='p-5 row m-0  seventh-sec'>
                            <div className='col-12 col-md-12 col-lg-6'>
                                <p className='sign-t'>Try ResearchTube.Ai for Free</p>
                                <p className='sign-p text-start'>Be part of our academic community operated by artificial intelligence</p>

                            </div>
                            <div className='col-12 mol-md-12 col-lg-6 mail-box'>
                                <p className='mt-3 text-secondary mail-box-t'>Your academic email</p>
                                <button
                                    onClick={() => {
                                        navigate('/sign-up')
                                        // handleShow()
                                    }}
                                    className='mail-box-btn'>Join</button>
                            </div>
                            {/* <img className='w-50' src='/assets/sec-7Image.png' /> */}
                        </div>
                    </div>
                    <Footer />
                </div>
            </div >

        </div >
    )
}

export default Landing
