import React from 'react';
import '../../../css/VideoGridRow.css'
import VideoPoster from "../../VideoPoster"
import { BsEye, BsFillBookmarkFill, BsLink45Deg } from 'react-icons/bs'
import { FaRegComment } from 'react-icons/fa'
import { AiFillLike } from 'react-icons/ai'
import classNames from 'classnames';
import { NavLink } from 'react-router-dom'
import axios from 'axios'
import { MdDeleteForever } from 'react-icons/md'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
const helpers = require('../../../../utilities/helpers')
const env = require('../../../../config.env')

const VideoGridRow = ({ videoDetails, gridType, catchErrors, refresh_grid }) => {
  let statusType = "";
  if (videoDetails.status == "Published")
    statusType = "success";
  else if (videoDetails.status == "Unpublished")
    statusType = "secondary";
  else if (videoDetails.status == "Draft")
    statusType = "danger";

  const deleteVideo = (video_id) => {
    if (window.confirm('Are you sure you want to delete this video?')) {

      axios.get(env.SERVER_REQUEST_PATH + `deleteVideo/${video_id}`)
        .then((response) => {
          if (response.data.is_deleted) {
            refresh_grid(Math.random())
          }
        }).catch(err => {
          catchErrors(err)
        })
    }
  }
  const unsave = (video_id) => {
    axios.post(`${env.SERVER_REQUEST_PATH}save`, { video_id: video_id, saved: false })
      .then((response) => {
        refresh_grid(Math.random())
      }).catch(err => {
        catchErrors(err)
        console.error(`Error: ${err}`)
      })
  }
  if (videoDetails)
    return (
      <div className="row m-0 p-0 item-container">
        {gridType != "save" && <div onClick={() => { deleteVideo(videoDetails.id) }} className='col-md-1 delete_width' role='button'>
          <OverlayTrigger placement="bottom" overlay={<Tooltip id={`id-${videoDetails.id}`}>Delete</Tooltip>}>
            <span role='button' > <MdDeleteForever color='#c04f48' size={25} /></span>
          </OverlayTrigger>
        </div>}
        {gridType == "save" && <div onClick={() => { unsave(videoDetails.id) }} className='col-md-1 delete_width' role='button'>
          <OverlayTrigger placement="bottom" overlay={<Tooltip id={`id-${videoDetails.id}`}>Unsave</Tooltip>}>
            <span role='button'> <BsFillBookmarkFill color={'#e91e63'} size={20} /></span>
          </OverlayTrigger>
        </div>}
        <div className="col-12 col-md-2 pb-3">
          <VideoPoster thumb={videoDetails.thumb_url} v_len={videoDetails.length} video_id={videoDetails.id} />
        </div>
        <div className="col-12 col-md-4 pb-3 title-style">
          <p className='mt-4'>
            <NavLink to={`/video/${videoDetails.id}`}>
              {videoDetails.VideoTitle}</NavLink>
          </p><p className=" titledetails-style">{videoDetails.desc}</p>
        </div>
        <div className="col-12 col-md-1 pb-3 font-style title-style">{videoDetails.tags}</div>
        <div className="col-12 col-md-1 pb-3 font-style title-style">{videoDetails.length}</div>
        <div className="col-12 col-md-1 pb-3 font-style title-style">{videoDetails.category}</div>
        <div className="col-12 col-md-1 pb-3 font-style title-style">
          <div className='d-flex flex-column justify-content-sm-center font-small'>
            <span className=''>

              {helpers.kFormatter(videoDetails.no_likes)}{' '}   <AiFillLike color='#D9D9D9' size={17} />
            </span>
            <span> {helpers.kFormatter(videoDetails.no_views)}{' '}<BsEye color='#999999' size={10} />
              {' '} {helpers.kFormatter(videoDetails.no_comments)}{' '}<FaRegComment color='#999999' size={10} /></span>
          </div>
        </div>
        <div className="col-12 col-md-1 pb-3 title-style">
          <span className={classNames(
            'bg-size badge',
            ` bg-${statusType}`
          )}>{videoDetails.status}</span></div>
      </div>
    )
  else
    return (null)
}

export default VideoGridRow
