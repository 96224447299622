import { useEffect, useState, useMemo } from 'react';
import Pagination from '../../../../SharedComponents/js/Pagination';
import VideoGridRow from "./VideoGridRow"
import axios from 'axios'
import '../../../css/VideoGrid.css'
import ViewVideosLoader from '../../Loaders/ViewVideosLoader';
import AudioPlaylistRow from './AudioPlaylistRow';
import PlaylistLoader from '../../Loaders/PlaylistLoader';
import VPlaylistRow from './VPlaylistRow';
const env = require('../../../../config.env')

const VPlaylistGrid = ({ count }) => {

    const [totalCount, setTotalCount] = useState(0)
    const [playlists, getPlaylists] = useState('')
    const [currentPage, setcurrentPage] = useState(0)

    const onPageChange = (pageNumber) => {
        setcurrentPage(pageNumber);
    }

    const onPrevClick = () => {
        setcurrentPage(prev => prev - 1);
    }

    const onNextClick = () => {
        setcurrentPage(prev => prev + 1);
    }

    useEffect(() => {
        let pagerlimit = Math.ceil(totalCount / env.PAGE_SIZE)
        const params = new URLSearchParams(window.location.search);
        const page = parseInt(params.get('page')) || 1;
        if (page <= pagerlimit || totalCount == 0) {
            let headers = { page_size: env.PAGE_SIZE }
            axios.get(`${env.SERVER_REQUEST_PATH}getUserVideoplaylists?page=${page}`, { headers: headers })
                .then((response) => {
                    let playlists = response.data.data
                    let currentPage = parseInt(response.data.meta.page)
                    let totalCount = parseInt(response.data.meta.totalCount)
                    getPlaylists(playlists)
                    if (currentPage)
                        setcurrentPage(currentPage)
                    if (totalCount)
                        setTotalCount(totalCount)
                    count()
                }).catch(err => {
                    console.error(`Error: ${err}`)
                })

        }
    }, [currentPage])

    return (
        <div className='vdg' >
            {!playlists && [...Array(4)].map((v, index) =>
                <ViewVideosLoader key={index} />)}
            {playlists && <>{playlists.map((playlistDetail) =>
                <VPlaylistRow key={playlistDetail.id} playlistDetails={playlistDetail} />)}
            </>}
            {playlists && <Pagination currentPage={currentPage} totalCount={totalCount}
                onPrevClick={onPrevClick}
                onNextClick={onNextClick}
                onPageChange={onPageChange} />
            }
        </div>
    )
}

export default VPlaylistGrid
