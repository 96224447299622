import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import Header from '../Components/Header'
import Footer from '../../SharedComponents/js/Footer'
import { useParams, useLocation } from 'react-router-dom';
import { useErrorBoundary } from "react-error-boundary";
import { useStateValue } from "../../index";
import FingerLeftMenu from '../Components/Main/FingerLeftMenu';
import AudioPlaylistContent from '../Components/Main/ViewContent/AudioPlaylistContent';
import DocumentMetadataLoader from '../../PDFGeneration/Components/DocumentMetadataLoader';
import DocumentMetadata from '../../PDFGeneration/Components/DocumentMetadata';
import { ListGroup } from 'react-bootstrap';
import axios from 'axios';
import { AiFillPlayCircle } from 'react-icons/ai';
import FixedAudioPlayer from '../../SharedComponents/js/FixedAudioPlayer';
import { BarLoader, PropagateLoader } from 'react-spinners';
import MiniFooter from '../../SharedComponents/js/Mini-Footer';
const env = require('../../config.env')


const DocumentAudios = () => {
    const [userDetails, dispatch] = useStateValue();
    const { state } = useLocation();
    const player_ref = useRef()
    const [documentData, setDocumentData] = useState()
    const [audios, setaudios] = useState([])
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);

    const { showBoundary } = useErrorBoundary();
    const { id } = useParams()
    useEffect(() => {
        let id = state.playlist.id
        if (id) {
            axios.get(`${env.SERVER_REQUEST_PATH}audioplaylistcontent/${id}`)
                .then((response) => {
                    let audios = response.data.data
                    setaudios(audios)
                }).catch(err => {
                    console.error(`Error: ${err}`)
                })
        }
    }, [])
    useEffect(() => {
        let id = state.playlist.doc_id
        if (id) {
            axios.get(`${env.SERVER_REQUEST_PATH}docs/${id}`)
                .then((response) => {
                    let doc = response.data.data[0]
                    let authors = doc.authors.split(',')
                    doc.authors = authors
                    let subs = doc.docsubjects.split(',')
                    doc.docsubjects = subs
                    setDocumentData(doc)
                }).catch(err => {
                    console.error(`Error: ${err}`)
                }
                )
        }
    }, [])
    const handleAudioEnded = (player_index) => {
        // if (player_ref.current.audio.current.loop) {
        setCurrentTrackIndex((player_index + 1) % audios.length);
        // }
        // else {
        //     if (player_index + 1 >= audios.length) {
        //         setCurrentTrackIndex(player_index)
        //     }
        //     else
        //         setCurrentTrackIndex(player_index + 1);
        // }
    };
    const loadSelectedAudio = (selected_audio) => {
        setCurrentTrackIndex(selected_audio);
    }
    return (
        <div className='d-flex flex-column AudiosContainer'>

            <div className='headercontainer'>
                <Header userdata={userDetails} catchErrors={(err) => {
                    showBoundary(err)
                }} />
            </div>
            <div className='play-content'>

                <div className='top-m px-5 pb-3'>
                    <div>
                        {!documentData && <DocumentMetadataLoader />}
                        {documentData && <DocumentMetadata doc_title={documentData?.title}
                            doc_URL={documentData?.paper_link}
                            authors={documentData?.authors} doctype={documentData?.doctype}
                            docpublicationDate={documentData?.publicationDate}
                            docjournaltitle={documentData?.docjournaltitle}
                            docpublisher={documentData?.docpublisher}
                            docsubjects={documentData?.docsubjects} />}
                        <ListGroup className='mt-5' as="ul">
                            {audios.length <= 0 && <ListGroup>
                                <ListGroup.Item className='mb-3 border border-1 rounded' as="li">
                                    <div className='d-flex align-items-center'>
                                        <div className=''>
                                            <AiFillPlayCircle role='button' size={25} color='#5644de' className=''>
                                                <PropagateLoader color="#B9AFF7" width={5} />
                                            </AiFillPlayCircle>
                                        </div>
                                        <div className='ms-3 w-75'>
                                            <BarLoader color="#B9AFF7" width={250} />
                                        </div>

                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item className='mb-3 border border-1 rounded' as="li">
                                    <div className='d-flex align-items-center'>
                                        <div className=''>
                                            <AiFillPlayCircle role='button' size={25} color='#5644de' className=''>
                                                <PropagateLoader color="#B9AFF7" width={5} />
                                            </AiFillPlayCircle>
                                        </div>
                                        <div className='ms-3 w-75'>
                                            <BarLoader color="#B9AFF7" width={250} />
                                        </div>

                                    </div>
                                </ListGroup.Item>
                            </ListGroup>}

                            {audios?.map((audio, index) => {
                                return (
                                    <ListGroup.Item className='mb-3 border border-1 rounded' as="li">
                                        <div className='d-flex' onClick={() => { loadSelectedAudio(index) }}>
                                            <AiFillPlayCircle size={25} color='#5644de' role='button' />
                                            <div className='ms-3 w-75'>{audio.title}</div>

                                        </div>
                                    </ListGroup.Item>
                                )
                            })}
                        </ListGroup>
                    </div>
                </div>
            </div>

            <FingerLeftMenu />
            <FixedAudioPlayer title={audios[currentTrackIndex]?.title}
                url={audios[currentTrackIndex]?.uri} player_index={currentTrackIndex}
                playerRef={player_ref}
                playSelecedAudio={(selectedIndex) => { loadSelectedAudio(selectedIndex % audios.length) }}
                handleAudioEnded={(player_index) => { handleAudioEnded(player_index) }} />
            <MiniFooter />

        </div>
    );

}

export default DocumentAudios
