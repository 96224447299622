import React, { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios';
import FormInput from '../../SharedComponents/js/FormInput'
import { Button, Modal } from 'react-bootstrap'
import VideoViewer from '../../SharedComponents/js/VideoViewer'
import { Player } from "react-tuby";
import { useErrorBoundary } from 'react-error-boundary'
import { BsCollectionPlay, BsCollectionPlayFill, BsFillCollectionPlayFill, BsSearch } from 'react-icons/bs'
import { FaCheckSquare } from 'react-icons/fa'
import { AiFillCheckSquare } from 'react-icons/ai'
import { PropagateLoader } from 'react-spinners';

const env = require('../../config.env')

const SearchBackground = ({ interest, showModal, submitSelected, closeModal }) => {
    const [isShow, setisShow] = useState(false)
    const [videos, setvideos] = useState([])
    const videosref = useRef();
    const videosRawRef = useRef()
    const { showBoundary } = useErrorBoundary();
    const contentRef = useRef(null);
    const [modal_height, setModalHeight] = useState(0);
    const [searchword, setsearchword] = useState()
    const [checkedState, setcheckedState] = useState()

    const [is_dataLoading, set_isDataLoading] = useState(0)
    const pageCount = useRef(1)
    const totalCount = useRef(48)
    const scrolledCount = useRef()
    const modal_ref = useRef()
    const max_selection = 1

    useEffect(() => {
        if (isShow)
            if (modal_ref.current.dialog) {
                const modalContent = modal_ref.current.dialog.querySelector('.modal-content');
                const maxHeight = window.innerHeight * 0.90; // 100px for margins/padding
                modalContent.style.minHeight = `${maxHeight}px`;
            }
    }, [isShow])
    useEffect(() => {
        setisShow(showModal)
    }, [showModal])

    const getVideosBackgrounds = async (keyword, subject, page, per_page) => {
        try {
            let response = await axios.post(env.SERVER_REQUEST_PATH + 'searchBackgrounds',
                { keyword: keyword, interest: subject, page: page, per_page: per_page })
            totalCount.current = response.data.total_count
            return response.data.frames
        }
        catch (error) {
            return {}
        }

    }
    const fetchVideos = async (is_scroll = false, end = 0) => {
        try {
            if (!is_scroll) {
                videosref.current = null
                setvideos()
            }
            set_isDataLoading(false)

            await getVideosBackgrounds(searchword, interest, pageCount.current, 120).then((search_res) => {

                set_isDataLoading(true)

                let new_vids = search_res
                let vid = videosRawRef.current
                let all_v = []
                if (is_scroll) {
                    if (vid)
                        all_v.push(...vid)
                    if (new_vids)
                        all_v.push(...new_vids)
                }
                else {
                    if (new_vids)
                        all_v.push(...new_vids)

                }
                // const intialState = [];
                const rows = [];
                for (let i = 0; i < all_v.length; i += 4) {
                    const rowNumbers = all_v.slice(i, i + 4);
                    const row_figs = []
                    rowNumbers.map((r) => {
                        row_figs.push({
                            fig_id: r.id,
                            inputResolutionHeight: r.height,
                            inputResolutionWidth: r.width,
                            thumbnail: r.picture,
                            fig_path: r.link, checked: false
                        })

                    })
                    rows.push(rowNumbers);

                }

                setvideos(rows)
                scrolledCount.current = all_v.length
                videosref.current = rows;
                videosRawRef.current = all_v

            })
        }
        catch (error) {
            return
        }

    }
    useEffect(() => {

        window.addEventListener('scroll', handleScroll, {
            passive: true
        });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const handleScroll = () => {
        if (isShow && contentRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = contentRef.current;

            if (Math.ceil(scrollTop + clientHeight) >= scrollHeight && scrolledCount.current < totalCount.current) {
                pageCount.current += 1
                fetchVideos(true);
            }
        }
    };
    const PauseAllVideos = () => {
        const videos = document.querySelectorAll('.popupSearchBK video');

        videos.forEach(video => {
            video.pause();
        });
    }
    const handleClose = () => {
        PauseAllVideos()
        set_isDataLoading(0)
        setisShow(false);
        let selected_bg = []
        // checkedState.map((row) => {
        // row.map((card) => {
        if (checkedState)
            selected_bg.push({
                fig_id: checkedState.fig_id,
                inputResolutionHeight: checkedState.inputResolutionHeight,
                inputResolutionWidth: checkedState.inputResolutionWidth,
                thumbnail: checkedState.thumbnail,
                fig_path: checkedState.fig_path
            })
        // })
        // })
        closeModal()
        submitSelected(selected_bg)
        setcheckedState()
        setvideos([])
        videosref.current = null
        videosRawRef.current = null
        setsearchword()
    }
    const handleShow = () => setisShow(true);

    const debounce = (func, delay) => {
        let debounceTimer;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer =
                setTimeout(() => func.apply(context, args), delay);
        }
    }
    const onInputChange = (event) => {
        let changedValue = event.target.value
        setsearchword(changedValue)
    }
    let optimisedHandleChange = useCallback(
        debounce(onInputChange, 500)
        ,
        []
    );
    const searchForbackgrounds = (e) => {
        e.preventDefault();
        fetchVideos()
    }
    const handleOnChange = (e, position, row_index) => {
        const selectedRow = videosref.current[row_index]
        let item_selected = selectedRow[position]

        let updatedCheckedState = {
            fig_id: item_selected.id,
            inputResolutionHeight: item_selected.height,
            inputResolutionWidth: item_selected.width,
            thumbnail: item_selected.picture,
            fig_path: item_selected.link,
            position: position,
            row_index: row_index
        }
        setcheckedState(updatedCheckedState);
    };
    let counter = 0
    return (
        <div>
            <Modal ref={modal_ref} className='bk search-modal popupSearchBK' size="lg" show={isShow} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='search-title-container d-flex justify-content-center'>
                        <span className='search-modal-title'>Replace video with..</span>
                        {/* <span className='f_size_small text-gray-color ms-1'>Up to 3 videos</span> */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form autoComplete="off" className='w-100 position-relative d-flex justify-content-center' onSubmit={searchForbackgrounds}>
                            <input
                                type={"text"} name="search_keyword" placeholder="e.g. Cell invasion, tissue engineering, ..etc" value={searchword}
                                onKeyDown={(event) => {
                                    event.stopPropagation();
                                }}
                                className="search_keyword" onChange={onInputChange} required />
                            <BsSearch size={20} className='search-keyword-icon' />

                        </form>
                    </div>

                    <div ref={contentRef} onScroll={handleScroll}
                        className={`${isShow ? 'overflow-y-scroll' : 'overflow-y-hidden'} mx-0 mb-0 mt-3 overflow-x-hidden min-h row row-gap-3 scroll-content`}>
                        {!searchword &&
                            <div className='w-100 h-100 d-flex flex-column justify-content-center align-items-center'>
                                <BsFillCollectionPlayFill className='mb-2' color='#CACED3' size={90} />
                                <h4 className='search-placeholder text-center fw-bold mb-2'>Find short videos for your paper</h4>
                                <span className='search-placeholder text-center'>Search in hundreds of videos in shutterStock, and find the perfect match for your paper.</span>
                            </div>
                        }

                        {videosref.current?.map((row, index) => {
                            return (
                                <div className='col-12 row m-0 p-0 row-gap-3' key={index}>
                                    {row.map((card, col_inx) => {
                                        counter++
                                        return (
                                            <div
                                                className={`fig-item col-6 col-md-3 px-3 py-3`}
                                                key={index + col_inx}
                                                onClick={(e) => handleOnChange(e, col_inx, index)}
                                            >
                                                <div className='d-flex'>
                                                    <div className={`h-100 justify-content-end fig-container ${checkedState?.row_index == index && checkedState?.position == col_inx ? 'checked' : 'unchecked'}`}>
                                                        <AiFillCheckSquare color='#5644de' size={25} />
                                                    </div>
                                                    <div className={`${checkedState?.row_index == index && checkedState?.position == col_inx ? "video-checked" : ""} d-flex justify-content-center size-vid`}
                                                        onClick={(e) => handleOnChange(e, col_inx, index)}>
                                                        <VideoViewer
                                                            link={card.link}
                                                            thumb={card.picture}
                                                            type={"normal"}
                                                        />

                                                        {/* </Player> */}

                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>)
                        })}
                        {(is_dataLoading === false || is_dataLoading != 0) &&
                            <div className='d-flex w-100 justify-content-center align-items-center'>
                                <PropagateLoader color="#B9AFF7" />
                            </div>
                        }
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default SearchBackground
