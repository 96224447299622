import { useEffect, useState, useMemo } from 'react';
import Pagination from '../../../../SharedComponents/js/Pagination';
import VideoGridRow from "./VideoGridRow"
import axios from 'axios'
import '../../../css/VideoGrid.css'
import ViewVideosLoader from '../../Loaders/ViewVideosLoader';
const env = require('../../../../config.env')

const VideosGrid = ({ tab_type, count, gridType, catchErrors }) => {
  const [type, setType] = useState(tab_type)
  const [totalCount, setTotalCount] = useState(0)
  const [videos, getVideos] = useState('')
  const [currentPage, setcurrentPage] = useState(0)
  const [refresh_grid, set_refresh_grid] = useState(0)
  const onPageChange = (pageNumber) => {
    setcurrentPage(pageNumber);
  }

  const onPrevClick = () => {
    setcurrentPage(prev => prev - 1);
  }

  const onNextClick = () => {
    setcurrentPage(prev => prev + 1);
  }

  useEffect(() => {
    let pagerlimit = Math.ceil(totalCount / env.PAGE_SIZE)
    let req_path = ''
    const params = new URLSearchParams(window.location.search);
    const page = parseInt(params.get('page')) || 1;
    if (page <= pagerlimit || totalCount == 0) {
      {
        (() => {
          switch (type) {
            case 'AllVideos':
              return req_path = `${env.SERVER_REQUEST_PATH}videos?page=${page}`
            case 'AuthorVideos':
              return req_path = `${env.SERVER_REQUEST_PATH}authorVideos?page=${page}`
            case 'NotAuthorVideos':
              return req_path = `${env.SERVER_REQUEST_PATH}notAuthorVideos?page=${page}`
            case 'SavedVideos':
              return req_path = `${env.SERVER_REQUEST_PATH}savedVideos?page=${page}`
          }
        })()
      }

      axios.get(req_path)
        .then((response) => {
          let vids = response.data.data
          let currentPage = parseInt(response.data.meta.page)
          let totalCount = parseInt(response.data.meta.totalCount)
          getVideos(vids)
          if (currentPage)
            setcurrentPage(currentPage)
          if (totalCount)
            setTotalCount(totalCount)
          count()
        }).catch(err => catchErrors(err))

    }
  }, [currentPage, refresh_grid])

  return (
    <div className='vdg' >
      {!videos && [...Array(4)].map((v, index) => <ViewVideosLoader key={index} />)}
      {videos && <>{videos.map((videoDetail) => <VideoGridRow key={videoDetail.id}
        catchErrors={(error) => { catchErrors(error) }} refresh_grid={(no) => { set_refresh_grid(no) }}
        videoDetails={videoDetail} gridType={gridType} />)}
      </>}
      {videos && <Pagination currentPage={currentPage} totalCount={totalCount}
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
        onPageChange={onPageChange} />
      }
    </div>
  )
}
export default VideosGrid
