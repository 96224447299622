import React, { useEffect, useRef } from "react";
import { Html } from "react-konva-utils";

function getStyle(background_width, height, fontSize, textColor, textFont, textBackground) {
    const isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
    const baseStyle = {
        width: `${background_width}px`,
        height: `${height + 4}px`,
        border: "2px #5345d6 dashed",
        "border-radius": "10px",
        padding: "3px",
        margin: "0px",
        background: "#05050557",// `${textBackground}`,
        outline: "none",
        resize: "none",
        color: textColor,
        fontSize: `${fontSize - 5}px`,
        fontFamily: textFont,
        overflow: "hidden"
    };
    if (isFirefox) {
        return baseStyle;
    }
    return {
        ...baseStyle,
        margintop: "-4px"
    };
}

export function EditableTextInput({
    x,
    y,
    width,
    height,
    fontSize,
    textFont,
    textColor,
    textBackground,
    background_width,
    value,
    onChange,
    onKeyDown
}) {
    const text_editor_ref = useRef()
    useEffect(() => {
        if (text_editor_ref.current) {
            text_editor_ref.current.focus();
            text_editor_ref.current.select();
        }
    }, [])
    const style = getStyle(background_width, height, fontSize, textColor, textFont, textBackground);
    return (
        <Html groupProps={{ x, y }} divProps={{ style: { opacity: 1 } }}>
            <textarea ref={text_editor_ref}
                value={value}
                onChange={onChange}
                onKeyDown={(e) => {
                    e.stopPropagation()
                    onKeyDown(e)
                }}
                style={style}
            />
        </Html>
    );
}
