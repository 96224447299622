import React, { useEffect, useState } from 'react'
import DocumentMetadata from '../../../../PDFGeneration/Components/DocumentMetadata'
import DocumentMetadataLoader from '../../../../PDFGeneration/Components/DocumentMetadataLoader'
import { BarLoader, PropagateLoader } from 'react-spinners'
import axios from 'axios'
import CustomAudioPlayer from '../../../../SharedComponents/js/CustomAudioPlayer'
import { ListGroup } from 'react-bootstrap'
const env = require('../../../../config.env')


const AudioPlaylistContent = (props) => {
    const [documentData, setDocumentData] = useState()
    const [audios, setaudios] = useState([])
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);

    useEffect(() => {
        let id = props.playlist.id
        if (id) {
            axios.get(`${env.SERVER_REQUEST_PATH}audioplaylistcontent/${id}`)
                .then((response) => {
                    let audios = response.data.data
                    setaudios(audios)
                }).catch(err => {
                    console.error(`Error: ${err}`)
                })
        }
    }, [])
    useEffect(() => {
        let id = props.playlist.doc_id
        if (id) {
            axios.get(`${env.SERVER_REQUEST_PATH}docs/${id}`)
                .then((response) => {
                    let doc = response.data.data[0]
                    let authors = doc.authors.split(',')
                    doc.authors = authors
                    let subs = doc.docsubjects.split(',')
                    doc.docsubjects = subs
                    setDocumentData(doc)
                }).catch(err => console.error(`Error: ${err}`))
        }
    }, [])
    const handleAudioEnded = (player_index) => {
        setCurrentTrackIndex((player_index + 1) % audios.length);
    };
    return (
        <div>
            {!documentData && <DocumentMetadataLoader />}
            {documentData && <DocumentMetadata doc_title={documentData?.title}
                doc_URL={documentData?.paper_link}
                authors={documentData?.authors} doctype={documentData?.doctype}
                docpublicationDate={documentData?.publicationDate}
                docjournaltitle={documentData?.docjournaltitle}
                docpublisher={documentData?.docpublisher}
                docsubjects={documentData?.docsubjects} />}
            <ListGroup className='mt-5' as="ul">
                {audios.length <= 0 && <ListGroup>
                    <ListGroup.Item className='mb-3 border border-1 rounded' as="li">
                        <div className='d-flex'>
                            <div className='w-25'>
                                <BarLoader color="#B9AFF7" width={250} />
                            </div>
                            <div className='ms-5 w-75'>
                                <BarLoader className='border border-1 rounded'
                                    color="#B9AFF7" width={670} height={50} />

                            </div>
                        </div>
                    </ListGroup.Item>
                    <ListGroup.Item className='mb-3 border border-1 rounded' as="li">
                        <div className='d-flex'>
                            <div className='w-25'>
                                <BarLoader color="#B9AFF7" width={250} />
                            </div>
                            <div className='ms-5 w-75'>
                                <BarLoader className='border border-1 rounded'
                                    color="#B9AFF7" width={670} height={50} />

                            </div>
                        </div>
                    </ListGroup.Item>
                </ListGroup>}

                {audios?.map((audio, index) => {
                    return (
                        <ListGroup.Item className='mb-3 border border-1 rounded' as="li">
                            <div className='d-flex'>
                                <div className='w-25'>{audio.title}</div>
                                <div className='ms-5 w-75'>
                                    <CustomAudioPlayer player_index={index} url={audio.uri}
                                        handleAudioEnded={(player_index) => { handleAudioEnded(player_index) }}
                                    />
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </div>
    )
}

export default AudioPlaylistContent
