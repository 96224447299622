import React from 'react'
import '../css/CustomRadioInput.css'
import Tooltip from 'react-bootstrap/Tooltip'
import { OverlayTrigger } from 'react-bootstrap'

const CustomRadioInput = (props) => {
    const tooltip = (
        <Tooltip id="tooltip">
            Subscribe for Access
        </Tooltip>
    );
    const no_tooltip = (
        <></>
    );
    const { label, options, name, defualt_checked, values, className, onChange, disabled, details } = props
    return (
        <div>
            <label className="mb-2 label-style">{label}</label>
            <div className={`d-flex align-items-start ${className}`}>
                {options.map((opt, index) => {
                    return (
                        <OverlayTrigger placement="bottom" overlay={disabled[index] ? tooltip : no_tooltip}>
                            <div className={`${index == 1 ? 'w-25' : ''} option-button`} >
                                <input type="radio" id={`a-opt-${index}`} name={name} onChange={onChange}
                                    className={`${disabled[index] ? 'disable-opt' : ''} `}
                                    defaultChecked={defualt_checked ? defualt_checked[index] : ""}
                                    value={values ? values[index] : ""}
                                    role='button'
                                    disabled={disabled[index] ? 'disabled' : ''}

                                />
                                <label className="btn btn-default" htmlFor="a25">{options ? options[index] : ""}</label>
                            </div>
                        </OverlayTrigger>
                    )
                })}
                {details && <small className='mt-2 text-gray-color'>1 min video for lay audience</small>}
                {/* <h6 className='radio_label'>{label}</h6>
            <div className="option-button">
                <input type="radio" id="a25" name={name} onChange={onChange}
                    className={`${disabled[0] ? 'disable-opt' : ''} `}
                    defaultChecked={defualt_checked ? defualt_checked[0] : ""}
                    value={values ? values[0] : ""}
                    disabled={disabled[0] ? 'disabled' : ''}

                />
                <label className="btn btn-default" htmlFor="a25">{options ? options[0] : ""}</label>
            </div>
            <div className="option-button">
                <input type="radio" id="a25" name={name} onChange={onChange}
                    className={`${disabled[0] ? 'disable-opt' : ''} `}
                    defaultChecked={defualt_checked ? defualt_checked[0] : ""}
                    value={values ? values[0] : ""}
                    disabled={disabled[0] ? 'disabled' : ''}

                />
                <label className="btn btn-default" htmlFor="a25">{options ? options[0] : ""}</label>
            </div>
            <OverlayTrigger placement="bottom" overlay={disabled[1] ? tooltip : no_tooltip}>
                <div className="option-button">
                    <input type="radio" id="a50" name={name}
                        className={`${disabled[1] ? 'disable-opt' : ''} `}
                        onChange={onChange}
                        value={values ? values[1] : ""}
                        defaultChecked={defualt_checked ? defualt_checked[1] : ""}
                        disabled={disabled[1] ? 'disabled' : ''}

                    />

                    <label className="btn btn-default" htmlFor="a50">{options ? options[1] : ""}</label>
                </div>
            </OverlayTrigger> */}

            </div>
        </div>
    )
}

export default CustomRadioInput
