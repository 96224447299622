import React, { useState, Component } from "react";
import '../css/WaitList.css'
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery'
const env = require('../../config.env')
const WaitList = (props) => {
    const [hide, setHide] = useState(false);
    const handleHide = () => {
        setHide(true);
    };
    // const handleShow = () => setShow(true);
    const [email, setEmail] = useState("");
    const [msg, setmsg] = useState("");
    //const navigate = useNavigate();
    const reload = () => { setHide(false) };
    const saveUser = async (e) => {
        e.preventDefault();

        try {
            await axios.post(`${env.SERVER_REQUEST_PATH}addtowaitinglist`, {
                email
            });
            handleHide();

        } catch (error) {
            setmsg(error.response.data.msg);
            $(".failure").fadeIn(300).delay(1500).fadeOut(400);
            console.log(error);
        }
    };

    return (
        <div>
            <Modal className="modal-style" show={props.show} onHide={props.close} onExit={reload}>
                <Modal.Header className="border border-0" closeButton>
                </Modal.Header>
                <Modal.Body className="mt-1">
                    <div className={!hide ? 'hidden' : 'show-ThankU'} ><h4 className="text-center">Thanks for Signing Up</h4> </div>
                    <div id="sign-poup" className={hide ? 'hidden' : undefined} >
                        <h4 className="mb-3 mx-4 text-bold">Join our Beta Testers</h4>
                        <div className="sign-form mb-3 mx-4">
                            <form autoComplete="off" onSubmit={saveUser} >
                                <div className="form-group required mb-3">
                                    <label for="email" className="form-label label-font-style">Email address</label>
                                    <input type="email" className="form-control bg-text-color" id="email" aria-describedby="emailHelp"
                                        value={email} placeholder='Your Email'
                                        onChange={(e) => setEmail(e.target.value)} required />
                                </div>
                                <button type="submit" class="btn btn-primary w-100">Sign Up</button>
                            </form>
                            <div class="mt-3 alert-box failure py-1"> {msg}</div>

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border border-0">

                </Modal.Footer>
            </Modal>

        </div >
    )
}

export default WaitList