import React, { useState, useEffect } from 'react'
import Loading from '../../SharedComponents/js/Loading'
import Header from '../../Main/Components/Header'
import DocumentMetadata from '../Components/DocumentMetadata'
import UploadPDF from '../Components/UploadPDF'
import { useStateValue } from "../../index";
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { useErrorBoundary } from "react-error-boundary";
import StandardSections from '../Components/StandardSections'
import Summary from '../Components/Summary'
import FingerLeftMenu from '../../Main/Components/Main/FingerLeftMenu'
import Footer from '../../SharedComponents/js/Footer'
import DocAudios from '../Components/DocAudios'
import Sections from '../Components/Sections'
import AudioSteps from '../Components/AudioSteps'
import MiniFooter from '../../SharedComponents/js/Mini-Footer'

const env = require('../../config.env')


const GenerateAudio = () => {
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false);
    const { showBoundary } = useErrorBoundary();
    const [levels, setlevels] = useState([]);

    const [userdata, dispatch] = useStateValue();
    const [progress, setprogress] = useState(6)
    const [stop_process, set_stop_process] = useState(false)
    const [disabled_sections, setdisabled_sections] = useState()
    const [is_public, set_is_public] = useState(false)
    const [voiceType, set_voice_type] = useState()

    const [stepCount, setstepCount] = useState(0)
    const [upload_content, setupload_content] = useState('')
    const [sections_content, setsections_content] = useState('')
    const [secContent_content, setsecContent_content] = useState('')
    const [audio_content, setaudio_content] = useState('')
    const [summary_content, setsummary_content] = useState('')
    const [hide_metadata, sethide_metadata] = useState(true)
    const [doc_interest, set_interest] = useState()
    const [is_author, set_is_author] = useState(false)
    const [doc_id, setdoc_id] = useState('')
    const [sections, getsections] = useState([])
    const [section_titles, getsection_titles] = useState([])
    const [docpublisher, getdocpublisher] = useState()
    const [docsubjects, getdocsubjects] = useState([])
    const [doctype, getdoctype] = useState()
    const [docjournaltitle, getdocjournaltitle] = useState()
    const [docpublicationDate, getdocpublicationDate] = useState()
    const [doc_title, getdoc_title] = useState([])
    const [doc_URL, getdoc_URL] = useState()
    const [authors, getauthors] = useState([])
    const [summaries, getSummaries] = useState([])
    const [beginAudioGen, setbeginAudioGen] = useState(false)
    const [selected_contents, setselected_contents] = useState([])
    const [selectedSections, setselectedSections] = useState([])
    const [hasError, setHasError] = useState(false)
    const [errMsg, setErrMsg] = useState()
    useEffect(() => {
        setstepCount(1)
        setupload_content("")
        setsections_content("hidden")
        setsecContent_content("hidden")
        setsummary_content("hidden")
        setaudio_content("hidden")
        sethide_metadata(true)
        let dis_sections = []
        if (sections) {
            sections.map((s) => {
                if (s.section_content)
                    dis_sections.push(false)
                else
                    dis_sections.push(true)

            })

            setdisabled_sections(dis_sections)
        }

    }, [])
    useEffect(() => {
        if (levels.length <= 0) {
            axios.get(env.SERVER_REQUEST_PATH + 'summaryLevels')
                .then((response) => {
                    let data = response.data.data
                    setlevels(data)
                }).catch(err => {
                    console.error(`Error: ${err}`)
                })
        }
    }, [])
    const restErrors = () => {
        setHasError(false)
        setErrMsg('')
    }
    const showErrors = (err) => {
        setHasError(true)
        setErrMsg(err)
    }
    const handleErrors = (err) => {
        setIsLoading(false)
        if (err.status == 500)
            showBoundary(err)
        else {
            showErrors(err.message)
        }
    }
    useEffect(() => {
        showStep()
    }, [stepCount])
    const showStep = () => {
        switch (stepCount) {
            case 2:
                setprogress(33)
                setupload_content("hidden")
                setsections_content("")
                setsecContent_content("hidden")
                setsummary_content("hidden")
                return

            case 3:
                setprogress(65)
                setupload_content("hidden")
                setsections_content("hidden")
                setsecContent_content("hidden")
                setsummary_content("")
                return
            case 4:
                setprogress(100)
                setupload_content("hidden")
                setsections_content("hidden")
                setsecContent_content("hidden")
                setsummary_content("hidden")
                setaudio_content("")
                setbeginAudioGen(true)
                return
        }

    }
    const onCancel = (flag) => {
        if (flag) {
            setIsLoading(true)

            axios.post(env.SERVER_REQUEST_PATH + 'cancel', { doc_id: doc_id })
                .then((response) => {
                    restErrors()
                    setIsLoading(false)

                }).catch(err => {
                    handleErrors(err)
                    console.error(`Error: ${err}`)
                })
        }
        navigate('/home')
    }
    const submitSummary = (changedsumms) => {
        let reqdata = { doc_id: doc_id, summaries: changedsumms }
        getSummaries(changedsumms)

        restErrors()
        setstepCount((prev) => prev + 1);
    }
    const submitSections = (sectionsNames) => {
        setselectedSections(sectionsNames)
        let selectedContent = []
        sectionsNames.map((name) => {
            let result = sections.find((section) =>
                section.section_title.trim().includes(name.trim()
                ))
            if (result)
                selectedContent.push(result)
        })
        setselected_contents(selectedContent)
        submitContent(selectedContent)

    }
    const submitContent = (sections_contents) => {
        restErrors()
        setIsLoading(true)
        let content_data_req = []
        let data = []
        sections_contents.map((item) => {
            content_data_req.push({
                title: item.section_title,
                maxLength: levels[0].max_len,
                minLength: levels[0].min_len, content: item.section_content
            })
            data.push({ summ_level: levels[0].id, title: item.section_title })
        })
        let reqdata = { doc_id: doc_id, sectioncontents: content_data_req }
        axios.post(env.SERVER_REQUEST_PATH + 'sendContents', reqdata)
            .then((response) => {
                let status = response.status
                if (status == 200) {
                    let data = response.data
                    let summariesWithlvl = []
                    data.sections.map((sum, index) => {
                        let summary_words = sum.summary.split(' ')
                        let sumWithLvl = {
                            title: sum.title, summary: sum.summary,
                            maxLength: summary_words.length + env.SUMMARY_EDIT_MAX//levels[0].max_len
                        }
                        summariesWithlvl.push(sumWithLvl)
                    })

                    getSummaries({ sections: summariesWithlvl })
                    setstepCount((prev) => prev + 1);
                    setIsLoading(false)
                }

            }).catch(err => {
                handleErrors(err)
                set_stop_process(true)
                console.error(`Error: ${err}`)
            })
    }

    const submit_Upload = (gen_type, voice_type, docId, DOI, selectedinterest, is_by_author, is_public_share) => {
        setdoc_id(docId)
        set_interest(selectedinterest)
        set_is_author(is_by_author)
        set_is_public(is_public_share)
        set_voice_type(voice_type)

        // if (gen_type === "custom") {
        let req_sec_data = { doc_id: docId, DOI: DOI }
        axios.post(env.SERVER_REQUEST_PATH + 'sections', req_sec_data)
            .then((response) => {
                let data = response.data
                getsection_titles(data.sections_titles)
                getsections(data.sections)
                getdoc_title(data.metadata.title[0])
                getdoctype(data.metadata.type)
                getdocsubjects(data.metadata.subject ? data.metadata.subject : [])
                getdocpublisher(data.metadata.publisher)
                getdocjournaltitle(data.metadata.journalTitle?.[0])
                getdocpublicationDate(data.metadata.publicationDate)
                getauthors(data.metadata.authors)
                getdoc_URL(data.metadata.URL)

                setupload_content(false)
                setIsLoading(false)
                sethide_metadata(false)

                restErrors()
                setstepCount((prev) => prev + 1);

            }).catch(err => {
                handleErrors(err)
                console.error(`Error: ${err}`)
            })
    }
    return (
        <div className=' ms-3 d-flex flex-column'>
            {isLoading && <Loading />}
            <Header userdata={userdata} />
            <div className={`generate-content`}>
                <div className={`top-m g-audio-container ${isLoading ? 'loading' : ''}`}>
                    {!hide_metadata &&
                        <DocumentMetadata
                            doc_title={doc_title}
                            authors={authors} doctype={doctype} doc_URL={doc_URL}
                            docpublicationDate={docpublicationDate} docjournaltitle={docjournaltitle}
                            docpublisher={docpublisher} docsubjects={docsubjects}

                        />
                    }
                    {upload_content &&
                        <div><label className='mt-4 ms-4 fw-bold fw-size-3'>Follow the steps to create your Audio</label>
                            <AudioSteps stepCount={stepCount} progress={progress} />
                        </div>

                    }
                    <div className={`${!upload_content ? 'd-none' : 'd-flex'}  w-100  justify-content-center mb-2`}>
                        {/* <GenerateAudioSteps stepCount={stepCount} progress={progress} /> */}

                    </div>
                    <div className='d-flex'>
                        <div className="tab-content w-100" id="pills-tabContent">
                            <div className="active" hidden={upload_content} id="upload-content">
                                <UploadPDF
                                    // nextstep={nextstep}
                                    // LoadPage={() => { setDataIsLoaded(true) }}
                                    mode={"aud"} isLoading={isLoading} beginProcess={() => { setIsLoading(true) }}
                                    endProcess={() => setIsLoading(false)}
                                    handleErrors={handleErrors}
                                    restErrors={restErrors}
                                    submit_Upload={submit_Upload} Cancel={onCancel} />
                            </div>
                            <div className="gen-page-wrapper" hidden={sections_content} id="sections-content">
                                <Sections sections={section_titles} isLoading={isLoading}
                                    submitSections={submitSections} Cancel={onCancel} />
                                {/* <StandardSections sections={section_titles} defaultLevel={levels[0]}
                                    isLoading={isLoading}
                                    stop_process={stop_process}
                                    disabled_sections={disabled_sections}
                                    submitSections={submitSections} Cancel={onCancel} /> */}
                            </div>
                            <div className="gen-page-wrapper" hidden={summary_content} id="summary-content">
                                <Summary isLoading={isLoading} summaries={summaries}
                                    mode={"aud"}
                                    submitSummary={submitSummary} Cancel={onCancel} />
                            </div>
                            <div className="gen-page-wrapper" hidden={audio_content} id="audio-content">
                                <DocAudios isLoading={isLoading}
                                    sections={summaries?.sections}
                                    voiceType={voiceType}
                                    doc_id={doc_id}
                                    is_public={is_public}
                                    beginAudioGen={beginAudioGen}
                                    interest_id={doc_interest}
                                    handleErrors={handleErrors}
                                    Cancel={onCancel} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-end'><p className='error-msg pe-5' hidden={hasError ? '' : 'hidden'}>{errMsg}</p></div>
            </div>
            <FingerLeftMenu />
            <MiniFooter />
        </div >
    )
}

export default GenerateAudio
