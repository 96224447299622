import '../../css/VideoCard.css'
import { BarLoader, ClipLoader } from 'react-spinners';
const VideoCardLoader = () => {
    return (
        <div className="video-card col-lg-3 col-md-4 col-12">
            <div
                className="d-flex justify-content-start p-1">
                <BarLoader className='card-loader border border-1 rounded' color="#eeeeee" width={290} height={150} />
            </div>
            <div className='ps-2'>
                <div className='d-flex w-100 mt-4'>
                    <BarLoader color="#eeeeee" width={190} />
                </div>

                <p className='mt-3 authorRow'>
                    <div className='d-flex align-items-center'>
                        {/* <button className="authorImage"> */}
                        {/* <ClipLoader className='me-2' color="#eeeeee" width={4} height={4} /> */}

                        {/* </button> */}
                        {'  '} <BarLoader color="#eeeeee" width={220} />
                    </div>
                    {/* <div className='d-flex justify-content-space-between'>
                        
                    </div> */}
                    <p className='w-100 mt-3 stats-font d-flex'>
                        <BarLoader color="#eeeeee" width={250} />
                    </p>
                </p>
            </div>

        </div >
    );
}

export default VideoCardLoader;