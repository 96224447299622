import React, { useContext, useRef, useEffect, useState } from 'react'
import axios from 'axios'
import UploadPDF from '../Components/UploadPDF'
import Header from '../../Main/Components/Header'
import Footer from '../../SharedComponents/js/Footer'
import { NavLink, useNavigate } from 'react-router-dom';
import '../css/Generate.css'
import Loading from '../../SharedComponents/js/Loading';
import { useErrorBoundary } from "react-error-boundary";
import { useStateValue } from "../../index";
import AutoGenerate from '../Components/AutoGenerate';
import FingerLeftMenu from '../../Main/Components/Main/FingerLeftMenu';
import DocumentMetadata from '../Components/DocumentMetadata';
import FreeGenerate from '../Components/FreeGenerate'
import MiniFooter from '../../SharedComponents/js/Mini-Footer'


const env = require('../../config.env')

const GenerateVideo = () => {
    const navigate = useNavigate()
    const [stop_process, set_stop_process] = useState(false)
    const { showBoundary } = useErrorBoundary();

    const [isgenerate, setisgenerate] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [generateSocket, setgenerateSocket] = useState(null)
    const [voiceType, setvoice_type] = useState()

    const [upload_content, setupload_content] = useState(false)
    const [custom_content, setcustom_content] = useState(false)
    const [free_content, setfree_content] = useState(false)
    const [auto_content, setauto_content] = useState(false)
    const [hide_metadata, sethide_metadata] = useState(true)
    const [userdata, dispatch] = useStateValue();

    const [figures, getfigures] = useState()
    const [sections, getsections] = useState([])
    const [section_titles, getsection_titles] = useState([])

    const [doc_interest, set_interest] = useState()
    const [is_author, set_is_author] = useState(false)
    const [is_share, set_is_share] = useState(false)
    const [doc_id, setdoc_id] = useState('')
    const [docpublisher, getdocpublisher] = useState()
    const [docsubjects, getdocsubjects] = useState([])
    const [doctype, getdoctype] = useState()
    const [docjournaltitle, getdocjournaltitle] = useState()
    const [docpublicationDate, getdocpublicationDate] = useState()
    const [doc_title, getdoc_title] = useState([])
    const [doc_URL, getdoc_URL] = useState()
    const [authors, getauthors] = useState([])
    const [hasError, setHasError] = useState(false)
    const [errMsg, setErrMsg] = useState()


    const [videoData, setVideoData] = useState()
    const [videoId, setVideoId] = useState()
    const [is_generate_done, set_is_done_flages] = useState()

    useEffect(() => {
        setupload_content(true)
        setcustom_content(false)
        setfree_content(false)
        setauto_content(false)
        sethide_metadata(true)
    }, [])


    useEffect(() => {
        // Create a new WebSocket connection
        if (isgenerate) {
            const socket = new WebSocket(env.SERVER_SOCKET_PATH);

            // Event listener for when the connection is opened
            socket.onopen = () => {
                // console.log('WebSocket connection established');

                // Send some data to the server
                const data = {
                    type: 'identification',
                    clientId: doc_id
                };
                socket.send(JSON.stringify(data));
            };

            // Event listener for when a message is received from the server
            socket.onmessage = (event) => {
                // console.log('Received message from server:', event.data);
                let data = JSON.parse(event.data)
                setVideoData({
                    video_path: data.videoPath,
                    thumb_img: data.thumb_img,
                    subtitle: data.subtitle
                })
                setVideoId(data.video_id)
                restErrors()
                set_is_done_flages({ retry: false, running: false })
                setisgenerate(false)

                // Handle the received message
            };

            // Event listener for when the connection is closed
            socket.onclose = () => {
                // console.log('WebSocket connection closed');
                // if (is_generate_done != undefined)
                //     isdoneGenerate(is_generate_done)
            };

            // Event listener for errors
            socket.onerror = (error) => {
                console.error('WebSocket error:', error);
                handleErrors(error)
                set_stop_process(true)
                set_is_done_flages({ retry: true, running: false })
                // console.error(`Error: ${error}`)
            };
            setgenerateSocket(socket)
        }
        else {
            if (generateSocket)
                generateSocket.close();
        }

    }, [isgenerate]);

    const restErrors = () => {
        setHasError(false)
        setErrMsg('')
    }
    const showErrors = (err) => {
        setHasError(true)
        setErrMsg(err)
    }
    const handleErrors = (err, continue_process) => {
        setIsLoading(false)
        if (continue_process)
            showErrors(err.message)
        else
            showErrors("Something went wrong with this document, We're working on it and we will get back to you")
        // if (err.status == 500)
        //     showBoundary(err)
        // else {
        //     showErrors(err.message)
        // }
    }

    const onCancel = (flag) => {
        if (flag) {
            setIsLoading(true)

            axios.post(env.SERVER_REQUEST_PATH + 'cancel', { doc_id: doc_id })
                .then((response) => {
                    restErrors()
                    setIsLoading(false)

                }).catch(err => {
                    handleErrors(err)
                    set_stop_process(true)
                    console.error(`Error: ${err}`)
                })
        }
        navigate('/home')
    }
    const publishOrSaveVid = (VideoId, title, descr, taggedUsers) => {
        setIsLoading(true)
        let req_data = {
            title: title, descr: descr, is_author: is_author,
            video_id: VideoId, taggedUsers: taggedUsers
        }
        axios.post(env.SERVER_REQUEST_PATH + 'publishOrSave', req_data)
            .then((response) => {

                let status = response.status
                if (status == 200) {
                    restErrors()
                    navigate(`/video/${VideoId}`, { state: { is_private: response.data.is_private } })
                }
                setIsLoading(false)

            }).catch(err => {
                handleErrors(err)
                set_stop_process(true)
                console.error(`Error: ${err}`)
            })
    }
    const submit_Upload = (gen_type, voice_type, docId, DOI, selectedinterest, is_by_author, is_share) => {
        setdoc_id(docId)
        set_interest(selectedinterest)
        set_is_author(is_by_author)
        set_is_share(is_share)
        setvoice_type(voice_type)
        let req_sec_data = { doc_id: docId, DOI: DOI }
        axios.post(env.SERVER_REQUEST_PATH + 'sections', req_sec_data)
            .then((response) => {
                let data = response.data
                getsection_titles(data.sections_titles)
                getsections(data.sections)
                getdoc_title(data.metadata.title[0])
                getdoctype(data.metadata.type)
                getdocsubjects(data.metadata.subject ? data.metadata.subject : [])
                getdocpublisher(data.metadata.publisher)
                getdocjournaltitle(data.metadata.journalTitle?.[0])
                getdocpublicationDate(data.metadata.publicationDate)
                getauthors(data.metadata.authors)
                getdoc_URL(data.metadata.URL)
                restErrors()
                if (gen_type === "custom") {
                    setcustom_content(true)
                }
                else if (gen_type === "free") {
                    setfree_content(true)
                }
                else {
                    setauto_content(true)
                }
                setupload_content(false)
                setIsLoading(false)
                sethide_metadata(false)
            }).catch(err => {
                handleErrors(err)
                set_stop_process(true)
                console.error(`Error: ${err}`)
            })
        // SendFiguresRequest(docId)
    }
    const SendFiguresRequest = async (docId) => {
        let req_fig_data = { doc_id: docId }
        await axios.post(env.SERVER_REQUEST_PATH + 'figures', req_fig_data)
            .then((response) => {

                let data = response.data
                getfigures(data)
                restErrors()

            }).catch(err => {
                handleErrors(err)
                set_stop_process(true)
                console.error(`Error: ${err}`)
            })

    }
    return (
        <div className='d-flex flex-column'>
            {isLoading && <Loading />}
            <Header userdata={userdata} />
            <div className='generate-content'>
                <div className={`top-m generate-container ${isLoading ? 'loading' : ''}`}>
                    {!hide_metadata &&
                        <DocumentMetadata
                            doc_title={doc_title}
                            authors={authors} doctype={doctype} doc_URL={doc_URL}
                            docpublicationDate={docpublicationDate} docjournaltitle={docjournaltitle}
                            docpublisher={docpublisher} docsubjects={docsubjects}

                        />
                    }
                    {!upload_content && <label className='mt-4 ms-5 fw-bold fw-size-3'>Follow the steps to create your video</label>}
                    {upload_content &&
                        <UploadPDF key={"upload"}
                            mode={"vid"}
                            isLoading={isLoading} beginProcess={() => { setIsLoading(true) }}
                            endProcess={() => setIsLoading(false)}
                            handleErrors={handleErrors}
                            restErrors={restErrors} permissions={userdata.user.permissions}
                            stop_process={stop_process} set_stop_process={() => { set_stop_process(true) }}
                            submit_Upload={submit_Upload} Cancel={onCancel} />}

                    {auto_content &&
                        <AutoGenerate key={"s-generate"}
                            doc_id={doc_id} doc_title={doc_title} doc_interest={doc_interest}
                            figures={figures} section_titles={section_titles} sections={sections}
                            voice_type={voiceType}
                            is_author={is_author} is_share={is_share}
                            doc_URL={doc_URL}
                            beginProcess={() => { setIsLoading(true) }}
                            restErrors={restErrors}
                            endProcess={() => setIsLoading(false)}
                            isLoading={isLoading} handleErrors={handleErrors}
                            togglegenerate={(value) => { setisgenerate(value) }}
                            videoId={videoId}
                            videoData={videoData}
                            is_done_flages={is_generate_done}
                            stop_process={stop_process} set_stop_process={() => { set_stop_process(true) }}
                            Cancel={onCancel} publishOrSave={publishOrSaveVid} />}
                    {free_content &&
                        <FreeGenerate key={"s-generate"}
                            doc_id={doc_id} doc_title={doc_title} doc_interest={doc_interest}
                            figures={figures} section_titles={section_titles} sections={sections}
                            voice_type={voiceType}
                            is_author={is_author} is_share={is_share}
                            doc_URL={doc_URL}
                            beginProcess={() => { setIsLoading(true) }}
                            restErrors={restErrors}
                            endProcess={() => setIsLoading(false)}
                            isLoading={isLoading} handleErrors={handleErrors}
                            togglegenerate={(value) => { setisgenerate(value) }}
                            videoId={videoId}
                            videoData={videoData}
                            is_done_flages={is_generate_done}
                            stop_process={stop_process} set_stop_process={() => { set_stop_process(true) }}
                            Cancel={onCancel} publishOrSave={publishOrSaveVid} />}
                </div>
                <div className='d-flex justify-content-end'><p className='error-msg pe-5' hidden={hasError ? '' : 'hidden'}>{errMsg}</p></div>
            </div>
            <FingerLeftMenu />
            <MiniFooter />
        </div >
    )
}

export default GenerateVideo
