
import VideoCardLoader from './VideoCardLoader';
const FeedsLoader = ({ size }) => {
    return (
        <div class="videosGrid">
            {
                [...Array(size)].map((row, rowIndex) => {
                    return (
                        <div class="row w-100 row-gap-2 m-0" key={rowIndex}>
                            {[...Array(size)].map((video) => (
                                <VideoCardLoader />
                            ))}
                        </div>
                    )
                })
            }
        </div>
    );

}
export default FeedsLoader;