import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { PropagateLoader } from 'react-spinners'
import FormInput from '../../SharedComponents/js/FormInput';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../css/DocAudios.css'

const env = require('../../config.env')

const DocAudios = (props) => {
    const navigate = useNavigate()
    const [audios_done, setAudioDone] = useState(false)
    const [playlist_title, setplaylist_title] = useState()
    const sectionAudio = useRef([])
    const [SectionAudio, setSectionAudio] = useState([])
    // const SectionStatus = useRef([])
    useEffect(() => {
        let done = true
        if (sectionAudio.current) {
            let i = 0
            while (i < sectionAudio.current.length) {
                let s = sectionAudio.current[i].audioStream
                if (!s.length) {
                    done = false
                    break;
                }
                i++;
            }
            setAudioDone(done)
        }
    }, [sectionAudio.current])
    useEffect(() => {
        if (props.sections && !props.beginAudioGen) {
            // let sec_status = []
            let sec_aud = []
            props.sections.map((sec) => {
                // sec_status.push(false)
                sec_aud.push({ audioStream: {}, audio_id: {} })
            })
            // SectionStatus.current = sec_status

            sectionAudio.current = sec_aud
            setSectionAudio(sec_aud)
        }
        if (props.beginAudioGen) {
            getAudios()
        }
    }, [props.sections, props.beginAudioGen])
    const getAudios = () => {
        props.sections.map((sec, index) => {
            let generateData = {
                doc_id: props.doc_id,
                interest_id: props.interest_id,
                section: sec,
                voiceType: props.voiceType,
                audioPath: "section" + index + "_audio"
            }
            axios.post(env.SERVER_REQUEST_PATH + 'generateAudio',
                generateData
            ).then((response) => {
                // let sec_aud = [...sectionAudio.current]
                // let updated_sec_audio = sec_aud[index]
                // updated_sec_audio.audioStream = response.data.audioStream
                // updated_sec_audio.audio_id = response.data.audio_id
                // sectionAudio.current = sec_aud
                let sec_aud = []
                sectionAudio.current.map((sa, i) => {
                    if (index == i)
                        sec_aud.push({ audio_id: response.data.audio_id, audioStream: response.data.audioStream })
                    else
                        sec_aud.push(sa)
                })
                sectionAudio.current = sec_aud
                setSectionAudio(sec_aud)
                // let sec_stat = []
                // SectionStatus.current.map((stat, i) => {
                //     if (index == i || stat) {
                //         sec_stat.push(true)

                //     }
                //     else if (index != i && !stat)
                //         sec_stat.push(false)
                // })
                // SectionStatus.current = sec_stat

            }).catch(err => {
                props.handleErrors(err)
                console.error(`Error: ${err}`)
            })

        })
    }
    const onClick_save = (e) => {
        e.preventDefault()
        let ids_list = []
        sectionAudio.current.map((s) => {
            ids_list.push(s.audio_id)
        })
        let saveData = {
            doc_id: props.doc_id,
            status: props.is_public,
            playlist_title: playlist_title,
            audios: ids_list
        }
        axios.post(env.SERVER_REQUEST_PATH + 'addaudiotoplaylist', saveData
        ).then((response) => {
            let status = response.status
            if (status == 200) {
                navigate(`/mycontent`)
            }
        })
    }
    return (
        <div className='doc-audios mx-5'>
            <div className='my-4'>
                <form autoComplete="off" className='d-flex align-items-center'><FormInput key='Playlist_title' name="Playlist_title"
                    placeholder='Enter Playlist title'
                    onChange={(e) => { setplaylist_title(e.target.value) }}
                    label='Playlist title'
                    required='required' />
                    <Button disabled={audios_done ? '' : 'disabled'}
                        className='gen-button mx-3' type='submit' variant="primary" onClick={onClick_save}>
                        Save to Playlist</Button>
                </form>
            </div>
            <ListGroup as="ul">
                {props.sections?.map((sec, index) => {
                    return (
                        <ListGroup.Item className='mb-3 border border-1 rounded' as="li">
                            <div className='d-flex'>
                                <div className='w-25'>{sec.title}</div>
                                <div className='doc_audios-player ms-5 w-75'>
                                    {SectionAudio?.[index]?.audioStream.length &&
                                        <AudioPlayer
                                            key={SectionAudio?.[index].audio_id}
                                            src={`data:audio/mp3;base64,${SectionAudio?.[index]?.audioStream}`}
                                        />}
                                    {!SectionAudio?.[index]?.audioStream.length &&
                                        <div className='d-flex justify-content-center'><PropagateLoader color="#B9AFF7" width={250} /></div>
                                    }
                                </div>
                            </div>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </div>
    )
}

export default DocAudios
