import React, { useContext, useState } from 'react'
import { Button } from 'react-bootstrap'
import FormInput from '../../SharedComponents/js/FormInput'
import axios from 'axios'
import { BsCheck2Circle } from 'react-icons/bs'
// import { ErrorContext } from '../../SharedComponents/js/ErrorContext';
// import ErrorAlert from '../../SharedComponents/js/ErrorAlert';
const env = require('../../config.env')

const EnterEmail = (props) => {
    const [confirm, setConfirm] = useState(false)

    const [raiseError, setraiseError] = useState(false)
    const [errorMsg, seterrorMsg] = useState('')
    // const { errors, seterrors } = useContext(ErrorContext);

    const onsubmit = (e) => {
        e.preventDefault()
        axios.post(env.SERVER_REQUEST_PATH + 'checkEmail', { email: e.target.elements.Email.value })
            .then((response) => {
                let status = response.status
                if (status == 200) {
                    if (response.data.IsUnique) {
                        setraiseError(true)
                        seterrorMsg("This Email does not Exists")
                    }
                    else {
                        setraiseError(false)
                        seterrorMsg("")
                        axios.post(env.SERVER_REQUEST_PATH + 'sendEmail', { email: e.target.elements.Email.value })
                            .then((response) => {
                                let status = response.status
                                if (status == 200) {
                                    setConfirm(true)

                                }

                            }).catch(err => {
                                // let new_errs = [...errors]
                                // new_errs.push(err)
                                // seterrors(new_errs);
                                props.catchErrors(err)
                                console.error(`Error: ${err}`)
                            })
                    }

                }

            }).catch(err => {
                // let new_errs = [...errors]
                // new_errs.push(err)
                // seterrors(new_errs);
                props.catchErrors(err)
                console.error(`Error: ${err}`)
            })
    }
    // const handleDismiss = (err) => {
    //     var array = errors
    //     var index = array.indexOf(err)
    //     if (index !== -1) {
    //         array.splice(index, 1);
    //         seterrors(array)
    //     }
    // }

    return (
        <div className='row w-100 h-100 m-0 position-absolute sign-up-container'>
            <div className='col-12 col-md-6 px-4 py-4 auth-container'>
                <div className="tab-content justify-content-between d-flex flex-column h-100 w-100 gap-3" id="pills-tabContent">
                    <a href="/" className='d-flex align-items-center logo-t-style'>
                        <img src=
                            {env.SERVER_MEDIA_PATH + 'pageLogo.png'}
                            className='auth-logo' />
                    </a>
                    {!confirm &&
                        <div className="auth-form-container mx-auto" id="loginformContainer">
                            <h1 className="auth-form-title text-center" id="step1HeaderTitle">Reset your password</h1>

                            <div className="auth-form-details mt-5" id="formDetails">
                                <form onSubmit={onsubmit}>
                                    <FormInput key='Email' name="Email" placeholder='Enter your email'
                                        label='Email' raiseError={raiseError} errorMsg={errorMsg}
                                        required='required' />
                                    <Button type="submit" size='medium' className={"w-100 mt-4 mb-3"}>Reset Password</Button>
                                </form>
                            </div>
                        </div>
                    }
                    {confirm &&
                        <div className='d-flex flex-column gap-3 justify-content-center align-items-center'>
                            <BsCheck2Circle size={60} color='lightgreen' />
                            <h5 className='text-center'> We have sent you a password recovery email</h5>
                            <p className='text-center'>Please check your email, you may find it in spam</p>
                        </div>
                    }
                    <div></div>
                </div>
            </div>
            <div className="col-6 p-4 h-100 d-none d-md-flex">
                <div className='auth-img-container d-flex align-items-center justify-content-center h-100 w-100'>
                    <div className='content w-75'>
                        <h2>Built for researchers, by researchers.</h2>
                        <p>Be part of our academic community operated by artificial intelligence</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EnterEmail
