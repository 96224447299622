import React from 'react'
import '../css/static_pages.css'
const NotFound = () => {

    return (
        <div className='notfound-container'>
            <div className='notfound-center'>
            </div>
            <div className='home_container mt-2 me-3 mb-4'><a className='home_btn' role='button' href='/home'>Home</a></div>
        </div>
    )
}

export default NotFound
