import React, { useContext, useRef, useEffect, useState } from 'react'
import { FiUpload, FiEdit } from 'react-icons/fi';
import { FaCheckCircle } from 'react-icons/fa'
import {
    PiNumberCircleTwoFill, PiNumberCircleThreeFill,
    PiNumberCircleFourFill
} from 'react-icons/pi'
import { BiSelectMultiple } from 'react-icons/bi';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { SlEarphones } from 'react-icons/sl';

const AudioSteps = ({ stepCount, progress }) => {
    return (
        <div className='d-flex w-100 justify-content-center mb-5'>
            <div className="mt-3 stepprogress" id="pills-tab">
                <div className='stepprogress-row stepper-panel' >
                    <div className="col-md-3 mx-3 stepprogress-step" role="upload">
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <FiUpload className='mb-2 icon-bg' color={'#B9AFF7'} size={20} />
                            <div className='p-1 d-flex flex-column justify-content-center align-items-center w-100'>
                                <FaCheckCircle className='icon-bg' color={'#B9AFF7'} size={25} />
                                <a className={`w-50 text-center m-0 btn btn-primary step-text finished-step`} id="upload" type="button">Upload Document</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mx-3 stepprogress-step" role="sections">
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <BiSelectMultiple className='mb-2 icon-bg' color={stepCount == 2 ? '#5644de' : '#B9AFF7'} size={20} />
                            <div className='p-1 d-flex flex-column justify-content-center align-items-center w-100'>
                                {stepCount <= 2 && <PiNumberCircleTwoFill className='icon-bg' color={'#5644de'} size={30} />}
                                {stepCount > 2 && <FaCheckCircle className='icon-bg' color={'#B9AFF7'} size={25} />}
                                <a className={`w-50 text-center btn btn-primary step-text ${stepCount == 2 ? 'active-step' : 'finished-step'}`} id="sections" type="button">Select Sections</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mx-3 stepprogress-step" role="summary">
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <FiEdit className='mb-2 mx-2 icon-bg' color={stepCount == 3 ? '#5644de' : stepCount > 3 ? '#B9AFF7' : '#cecece'} size={20} />
                            <div className='p-1 d-flex flex-column justify-content-center align-items-center w-100'>
                                {stepCount <= 3 && <PiNumberCircleThreeFill className='icon-bg' color={stepCount >= 3 ? '#5644de' : '#cecece'} size={30} />}
                                {stepCount > 3 && <FaCheckCircle className='icon-bg' color={'#B9AFF7'} size={25} />}

                                <a className={`w-50 text-center btn btn-primary step-text ${stepCount == 3 ? 'active-step' : stepCount > 3 ? 'finished-step' : 'non-active-step'}`} id="summary" type="button">
                                    Edit Summary</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 mx-3 stepprogress-step" role="audio">
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <SlEarphones className='mb-2 icon-bg' color={stepCount == 4 ? '#5644de' : stepCount > 4 ? '#B9AFF7' : '#cecece'} size={20} />
                            <div className='p-1 d-flex flex-column justify-content-center align-items-center w-100'>
                                {stepCount <= 4 && <PiNumberCircleFourFill className='icon-bg' color={stepCount >= 4 ? '#5644de' : '#cecece'} size={30} />}
                                {stepCount > 4 && <FaCheckCircle className='icon-bg' color={'#B9AFF7'} size={25} />}

                                <a className={`w-50 text-center btn btn-primary step-text ${stepCount == 4 ? 'active-step' : stepCount > 4 ? 'finished-step' : 'non-active-step'}`} id="audio" type="button">
                                    Your Audios</a>
                            </div>
                        </div>
                    </div>
                </div>
                <ProgressBar id="steps-progress" variant="success" now={progress} />
            </div>
        </div>
    )
}


export default AudioSteps