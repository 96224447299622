import Error from "./Error";
import { ErrorBoundary } from "react-error-boundary";

export default function AppErrorBoundary(props) {
    return (
        <ErrorBoundary
            FallbackComponent={Error}
            onError={(error, errorInfo) => {
                // log the error
                // console.log("Error caught!");
                // console.error(error);
                // console.error(errorInfo);
            }}
            onReset={() => {
                console.log("reloading the page...");
                window.location.reload();

            }}
        >
            {props.children}
        </ErrorBoundary>
    );
}