import React from 'react';
import VideoCard from './VideoCard'
import "../../../css/FeedsGrid.css";
import FeedsLoader from '../../Loaders/FeedsLoader';
import VPlaylistCard from './VPlaylistCard';

const FeedsGrid = (props) => {
    let size = props.grid_size ? props.grid_size : 5
    if (window.innerWidth > 1500 && window.innerWidth < 2300)
        size = props.grid_size ? 4 : 6
    else if (window.innerWidth > 2500)
        size = props.grid_size ? 6 : 8

    const rows = [];
    for (let i = 0; i < props.cards?.length; i += size) {
        const rowNumbers = props.cards.slice(i, i + size);
        rows.push(rowNumbers);
    }
    if (props.cards)
        return (
            <div class="row w-100 row-gap-2 m-0">
                {
                    rows.map((row, rowIndex) => {
                        return (
                            row.map((card, index) => (
                                props.playlist ?
                                    <VPlaylistCard {...card} key={index} is_private={props.is_private}
                                        type={props.type} refreshVideos={() => { props.refreshVideos() }}
                                        catchErrors={(err) => props.catchErrors(err)}
                                    />
                                    :
                                    <VideoCard
                                        {...card}
                                        is_private={props.is_private}
                                        is_privateVideo={props.is_privateVideo}
                                        refreshVideos={() => { props.refreshVideos() }}
                                        type={props.type}
                                        key={index}
                                        isgenerated={props.isgenerated}
                                        publish={() => {
                                            props.publishvideo(card, card.is_paid)
                                        }}
                                        removeVideo={(id) => {
                                            props.removeVideo(id)
                                        }}
                                        catchErrors={(err) => props.catchErrors(err)}
                                    />

                            ))
                        )
                    })
                }
            </div>
        );
    else
        return (<FeedsLoader size={props.grid_size ? props.grid_size : 4} />)
};

export default FeedsGrid;