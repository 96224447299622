import React, { useState } from 'react'
import '../../../css/CommentBox.css'
import CommentList from './CommentList'
import PostComment from './PostComment'
const CommentsBox = (props) => {
  const [refresh_trigger, setrefresh_trigger] = useState(false)
  const refreshComments = () => {
    setrefresh_trigger(Math.random())
  }
  return (
    < div className='comments-container p-4'>
      <h5 className='font-weight-bold'>Comments</h5>
      <PostComment is_private={props.is_private} video_id={props.video_id} refreshComments={refreshComments}
        catchErrors={(err) => {
          props.catchErrors(err)
        }} />
      <CommentList is_private={props.is_private} video_id={props.video_id} refresh={refresh_trigger}
        catchErrors={(err) => {
          props.catchErrors(err)
        }} />
    </div>
  )
}

export default CommentsBox
