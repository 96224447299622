import Comment from './Comment'
import { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import '../../../css/CommentList.css'
import { ClipLoader } from 'react-spinners';
const env = require('../../../../config.env')

const CommentList = (props) => {
  const [comments, getcomments] = useState('');
  const [pageCount, setPageCount] = useState(1)
  const [is_loading, set_is_loading] = useState(false)
  const totalCount = useRef()
  const loadedCount = useRef()

  useEffect(() => {
    fetchComments()
  }, [props.video_id, props.refresh])

  useEffect(() => {
    fetchComments(true)
  }, [pageCount])
  const fetchComments = (is_scroll) => {
    const id = props.video_id
    if (id) {
      let headers = { page_size: 5 }
      if (props.is_private)
        headers = { ...headers, is_private: true }
      set_is_loading(true)
      axios.get(`${env.SERVER_REQUEST_PATH}comments/${id}?page=${pageCount}`, { headers: headers })
        .then((response) => {
          let data = response.data.data
          let old_comments = comments
          if (is_scroll) {
            let all_comments = []
            all_comments.push(...old_comments)
            all_comments.push(...data)
            loadedCount.current = all_comments.length
            getcomments(all_comments)
          }
          else {
            loadedCount.current = data.length
            getcomments(data)
          }
          totalCount.current = response.data.meta ? response.data.meta.totalCount : 0
          set_is_loading(false)
        }).catch(err => {
          props.catchErrors(err)
          console.error(`Error: ${err}`)
        })
    }
  }
  if (comments)
    return (
      <div className='all-comments-container m-0'>
        <div className='scrolledParent'>
          {comments.map((comment) => <Comment key={comment.comment_id} commentData={comment} />)}
        </div>
        {(loadedCount.current < totalCount.current) &&
          <div className='w-100 d-flex justify-content-center align-items-center'>
            <span className='show_link' role='button'
              onClick={() => setPageCount(page => page + 1)}>load more comments
            </span>
            {is_loading && <ClipLoader className='ms-2' color='#5644de' size={15} />}
          </div>
        }
      </div>
    )
  else return (<></>)
}

export default CommentList
