import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { FaUserCircle } from 'react-icons/fa'
import VideoPoster from '../../VideoPoster'
import '../../../css/RecentVideoRow.css'
const env = require('../../../../config.env')
const RecentVideoRow = ({ is_private, videoDetails }) => {
    const navigate = useNavigate()
    if (videoDetails)
        return (
            <div className='row pb-1 mb-1 me-3 ms-0 ps-1 recent-v vr-container border rounded'>
                <div className="col-12 col-md-5 mb-2">
                    <VideoPoster is_private={is_private} thumb={videoDetails.thumb_url}
                        v_len={videoDetails.length} video_id={videoDetails.id} />
                </div>
                <div className="col-12 mt-3 col-md-7 v-title-style">
                    <p className='m-0'>
                        <NavLink title={videoDetails.videoTitle}
                            to={`/video/${videoDetails.id}`}
                            state={{ is_private: is_private }}>{videoDetails.videoTitle}</NavLink>
                    </p><p className="p-0 my-0 desc-style text-break text-wrap">{videoDetails.Videodesc}</p>
                    <div className='d-flex mx-0 mb-2'>
                        {videoDetails.profile_img && <img src={env.SERVER_REQUEST_PATH + videoDetails.profile_img}
                            className='me-2 v-user-image' circular="true" />}
                        {!videoDetails.profile_img && <FaUserCircle className='me-2' size={30} color='6e757c' />}

                        <span className='link-pointer text-wrap user-fontcolor'
                            onClick={() => {
                                navigate(`/profile/${videoDetails.username}`, { state: videoDetails.userId })
                            }}
                        >{videoDetails.username}
                        </span>
                    </div>
                </div>
            </div>
        )
}

export default RecentVideoRow
