import React from 'react'
import '../css/Mini-footer.css'
import { FaLinkedinIn, FaInstagram, FaFacebookF, FaTwitter, FaYoutube } from 'react-icons/fa'
const env = require('../../config.env')

const MiniFooter = () => {
    return (
        <div className='mini-footer-container py-3 px-3'>
            <div className='row m-0'>
                <div className='col-12 col-md-4 text-md-start text-center'>© 2024 ResearchTube.Ai</div>

                <div className='col-md-4 col-12 gap-3 d-flex justify-content-md-center justify-content-center'>
                    <a className='social-link' target="_blank"
                        href='https://www.linkedin.com/company/37229472/admin/?anchor=org-view-followers'>
                        <FaLinkedinIn size={20} /></a>
                    <a className='social-link' target="_blank"
                        href='https://www.instagram.com/researchtube.ai/'>
                        <FaInstagram size={20} /></a>
                    <a className='social-link' target="_blank"
                        href='https://www.facebook.com/ResearchTubeAi'>
                        <FaFacebookF size={20} /></a>
                    <a className='social-link' target="_blank"
                        href='https://twitter.com/ResearchTube_Ai'>
                        <FaTwitter size={20} /></a>
                    <a className='social-link' target="_blank"
                        href='https://www.youtube.com/@ResearchTubeAi'>
                        <FaYoutube size={20} /></a>
                </div>
                <div className='col-12 col-md-4 text-end'>
                    <a className='text-secondary ms-2 mt-2 text-decoration-none' target="_blank"
                        href={'/terms'}>Terms of Service & Privacy Policy</a>
                </div>
            </div>
        </div>

    )
}

export default MiniFooter
