import React, { useEffect, useState } from 'react'
import axios from 'axios'
const env = require('../../../../config.env')
const VideoMaterials = (props) => {
    const [materials, setmaterials] = useState()
    useEffect(() => {
        let headers = {}
        if (props.is_private)
            headers = { is_private: true }
        axios.get(`${env.SERVER_REQUEST_PATH}materials/${props.video_id}`, { headers: headers })
            .then((response) => {
                let mats = response.data.data
                if (mats.length > 0)
                    setmaterials(mats)
            }).catch(err => {
                // props.catchErrors(err)
                console.error(`Error: ${err}`)
            })
    }, [props.video_id])
    if (materials)
        return (
            <div>
                <div className='col-md-12'>
                    <div className='border border-2 rounded border-color mb-2'>
                        <p className='fw-bold ps-3 pt-3'>List of Materials</p>

                        <div className='fs-6 p-1 border-top border-2 border-color'>
                            <div className='row m-0 p-0 border-bottom border-1 border-color'>
                                <div className='p-1 border-end border-1 border-color col-md-4'> <p className='fw-bold mt-3'>Material</p></div>
                                <div className='p-1 border-end border-1 border-color col-md-4'> <p className='fw-bold mt-3'>Company</p></div>
                                <div className='p-1 col-md-4'> <p className='fw-bold mt-3'>link</p></div>
                            </div>
                        </div>
                        {materials.map((material) => {
                            return (
                                <div className='row m-0 p-0 border-bottom border-1 border-color'>
                                    <div className='p-1 border-end border-1 border-color col-md-4'>
                                        <p className='mt-3'>{material.material_name}</p></div>
                                    <div className='p-1 border-end border-1 border-color col-md-4'>
                                        <p className='mt-3'>{material.company}</p></div>
                                    <div className='d-flex p-1 col-md-4'>
                                        <a className='text-break mt-3' target="_blank"
                                            href={material.company_link}>
                                            {material.company_link}</a></div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
}

export default VideoMaterials
