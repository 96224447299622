import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import { FaUserCircle } from 'react-icons/fa'
import Stack from 'react-bootstrap/Stack';
import { Button, Modal } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import UserForm from '../Components/Main/EditProfile/UserForm';
import PasswordForm from '../Components/Main/EditProfile/PasswordForm';
import Header from '../Components/Header';
import '../css/Profile.css';
import { useErrorBoundary } from "react-error-boundary";
import axios from 'axios'
import UploadFile from '../../SharedComponents/js/UploadFile';
import { useNavigate } from 'react-router-dom';
import MiniFooter from '../../SharedComponents/js/Mini-Footer';
import { BiPencil } from 'react-icons/bi';
const helper = require('../../utilities/helpers')
const env = require('../../config.env')

const ProfileEdit = () => {
    const [userDetails, setuserDetails] = useState(null);
    const [selectedFile, setSelectedFile] = useState()
    const [show, setShow] = useState(false);
    const [refresh, setrefresh] = useState("")
    const { showBoundary } = useErrorBoundary();
    const navigate = useNavigate()

    const handleSave = () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append("file", selectedFile)
            try {
                axios.post(env.SERVER_REQUEST_PATH + 'uploadProfileImage', formData, {
                    headers: { 'content-type': 'multipart/form-data' }
                }).then((res) => {
                    setrefresh(Math.random())
                });
            } catch (error) {
                console.error('Failed to submit files.');
            }
        }
        setShow(false);
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        axios.get(`${env.SERVER_REQUEST_PATH}profile`)
            .then((response) => {
                setuserDetails({ user: response.data })
            }).catch(err => {
                showBoundary(err)
                console.error(`Error: ${err}`)
            })
    }, [refresh])
    const setUploadedFile = (file) => {
        setSelectedFile(file)
    }
    const view_as_Public = () => {
        axios.get(`${env.SERVER_REQUEST_PATH}getmyId`)
            .then((response) => {
                navigate(`/profile/${response.data.username}`, { state: { id: response.data.id, role: "aspublic" } })
            }).catch(err => {
                showBoundary(err)
                console.error(`Error: ${err}`)
            })
    }
    if (!userDetails) {
        return (<></>)
    }
    let user = userDetails?.user
    if (user)
        return (
            <div className='d-flex flex-column ProfileContainer' >
                <div className='headercontainer'>
                    <Header userdata={userDetails} catchErrors={(err) => {
                        showBoundary(err)
                    }} />
                </div>

                <div className='profile-content row m-0'>
                    <div className='top-m px-md-5 px-2 pb-3 col-12 col-lg-6'>
                        <span style={{ fontSize: '30px', fontWeight: '600' }}>
                            Edit your profile
                        </span>
                        <Container >
                            <div className='position-relative d-flex my-4' style={{ width: "fit-content" }}>
                                {user.profile_img &&
                                    <Image src={env.SERVER_REQUEST_PATH + user.profile_img}
                                        className='profile-img-style' rounded />}
                                {!user.profile_img && <FaUserCircle size={100} color='6e757c' />}
                                <Button onClick={handleShow} variant="primary" className="edit-profile-btn position-absolute">
                                    <BiPencil size={15} />
                                </Button>
                            </div>

                            <UserForm onsubmit={(e) => { setrefresh(Math.random()) }} user={userDetails?.user} />
                        </Container>
                    </div>
                </div>

                <MiniFooter />

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Change Profile Image</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <UploadFile uploadFile={setUploadedFile} fileType={["JPEG", "GIF", "PNG"]} />
                    </Modal.Body>

                    <Modal.Footer>
                        <Button onClick={handleSave} className="style-button" variant="primary">Save</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )

}

export default ProfileEdit
