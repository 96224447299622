import React, { useCallback, useEffect, useRef, useState } from 'react'
import axios from 'axios';
import FormInput from '../../SharedComponents/js/FormInput'
import { Button, Modal } from 'react-bootstrap'
import VideoViewer from '../../SharedComponents/js/VideoViewer'
import { Player } from "react-tuby";
import { useErrorBoundary } from 'react-error-boundary'
import { BsCollectionPlay, BsCollectionPlayFill, BsFillCollectionPlayFill, BsSearch } from 'react-icons/bs'
import { FaCheckSquare } from 'react-icons/fa'
import { AiFillCheckSquare } from 'react-icons/ai'
import { PropagateLoader } from 'react-spinners';

const env = require('../../config.env')

const Transitions = ({ transitions, showModal, closeModal }) => {
    const [isShow, setisShow] = useState(false)
    // const [videos, setvideos] = useState(transitions)
    const { showBoundary } = useErrorBoundary();
    const [checkedState, setcheckedState] = useState()


    useEffect(() => {
        setisShow(showModal)
    }, [showModal])

    const handleClose = () => {
        setisShow(false);
        let selected_trans = checkedState
        setcheckedState()
        closeModal(selected_trans?.trans_name)
    }
    const handleShow = () => setisShow(true);

    const handleOnChange = (e, position, row_index) => {
        const selectedRow = transitions[row_index]
        let item_selected = selectedRow[position]

        let updatedCheckedState = {
            gif_path: item_selected.gif_path,
            trans_name: item_selected.trans_name,
            position: position,
            row_index: row_index
        }
        setcheckedState(updatedCheckedState);
    };
    let counter = 0
    return (
        <div>
            <Modal className='trans search-modal' size="lg" show={isShow} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='search-title-container d-flex justify-content-center'>
                        <span className='search-modal-title'>Choose a transition..</span>
                        {/* <span className='f_size_small text-gray-color ms-1'>Up to 3 videos</span> */}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div
                        className={`${isShow ? 'overflow-y-scroll' : 'overflow-y-hidden'} mx-0 mb-0 mt-3 overflow-x-hidden min-h row row-gap-3 scroll-content`}>

                        {transitions?.map((row, index) => {
                            return (
                                <div className='col-12 row m-0 p-0 row-gap-3' key={index}>
                                    {row.map((card, col_inx) => {
                                        counter++
                                        return (
                                            <div
                                                className={`fig-item col-6 col-md-3 p-3`}
                                                key={index + col_inx}
                                                onClick={(e) => handleOnChange(e, col_inx, index)}
                                            >
                                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                                    <div className={`h-100 justify-content-end fig-container ${checkedState?.row_index == index && checkedState?.position == col_inx ? 'checked' : 'unchecked'}`}>
                                                        <AiFillCheckSquare color='#5644de' size={25} />
                                                    </div>
                                                    <div className={`${checkedState?.row_index == index && checkedState?.position == col_inx ? "video-checked" : ""} w-auto h-auto d-flex justify-content-center p-2 size-vid`}
                                                        onClick={(e) => handleOnChange(e, col_inx, index)}>
                                                        <img
                                                            src={card.gif_path}
                                                        />

                                                    </div>
                                                    <strong className='mt-2 '>{card.trans_name}</strong>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>)
                        })}

                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Transitions
