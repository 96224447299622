import React, { useState } from 'react'
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Slider from 'rc-slider';
import '../css/PaymentTypes.css'
// import Tooltip from 'rc-tooltip'
import 'rc-slider/assets/index.css';
import { FaCheckCircle } from 'react-icons/fa';

const PaymentTypes = ({ is_show, handleClose }) => {

    const [values, setValues] = useState([50]);
    const [totalPrice, set_totalPrice] = useState(100)
    return (
        <div>
            <Modal size="lg" show={is_show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className='text-center px-5 pt-2 pb-1 '>
                            <h4 className='m-0 p-0'> <strong>To continue you need to subscribe to one of our plans</strong></h4>
                            <small className='m-0 p-0 font-size-small small-color-txt'>Choose a plan that’s perfect for you, cancel anytime.</small>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='py-2 ps-2 pe-4 d-flex'>
                        <div className='p-2 col-12 col-md-6 as_y_go'>
                            <h4 className='mx-3 mt-2'> <strong>Pay as you go</strong></h4>
                            <small className='mx-3'>Pay as you go model let’s you only pay for the number of videos need.</small>
                            <span className='m-3 mt-4'>Number of videos</span>
                            <div className='m-4 mt-3'>
                                <div className='px-4'>
                                    <Slider
                                        onChange={(changedValue) => {
                                            set_totalPrice(changedValue[0] * 100)
                                        }}
                                        handleRender={(node, handleProps) => {
                                            return (
                                                // <Tooltip
                                                //     // overlayInnerStyle={{ minHeight: "auto" }}
                                                //     // overlay={handleProps.value}
                                                //     overlay={<span>tooltip</span>}
                                                //     visible={true}
                                                //     placement="bottom"
                                                // >
                                                <OverlayTrigger placement="top" overlay={<Tooltip id="slider_tooltip">
                                                    {handleProps.value}
                                                </Tooltip>}>
                                                    {node}
                                                    {/* // </Tooltip> */}
                                                </OverlayTrigger>
                                            );
                                        }}
                                        range
                                        min={0}
                                        max={50}
                                        defaultValue={[1]}
                                    ></Slider>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <span className='min-label'>0 videos</span>
                                    <span className='max-label'>50 videos</span>
                                </div>
                            </div>
                            <div className='w-100 d-flex align-items-center my-3'>
                                <strong className='col-12 col-md-4 mx-3'>Price: ${totalPrice}</strong>
                                <button className='ms-5 p-2 col-12 col-md-5 reg-button'>Start Now</button>
                            </div>
                        </div>
                        <div className='p-2 ms-3 enter_plan col-12 col-md-6'>
                            <h4 className='theme-color-txt mx-3 mt-2'><strong>Enterprise plan</strong></h4>
                            <small className='mx-3 small-color-txt'>For more customized plan to fit your needs.</small>
                            <div className='enter-specs my-3 ms-3 me-2'>
                                <strong className='mb-3'><FaCheckCircle className='me-1' color='#5644de' /> Enjoy all ResearchTube.ai features</strong>
                                <strong className='mb-3'><FaCheckCircle className='me-1' color='#5644de' />Customize your experience with ResearchTube.ai</strong>
                                <strong className='mb-3'><FaCheckCircle className='me-1' color='#5644de' />Direct call with one of our team</strong>
                            </div>
                            <div className='mt-4 d-flex align-items-end justify-content-center'>
                                <button className='w-50 p-2 primary_btn '>Contact us</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </div>
    )
}

export default PaymentTypes
