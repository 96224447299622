import React from 'react'
import Form from 'react-bootstrap/Form';
import '../css/Switch.css'
const Switch = (props) => {
    const { label, name, defaultChecked, className, onChange } = props

    return (

        <Form.Check
            type="switch"
            className={`switch-style ms-5 me-0 px-0 ${className}`}
            id={name}
            name={name}
            label={label}
            defaultChecked={defaultChecked}
            onChange={onChange}
        />
    )
}

export default Switch
