import React, { useState, useEffect } from "react";
import { Group, Rect } from "react-konva";
import { EditableTextInput } from "./EditableTextInput";
import { Text } from "react-konva";

const RETURN_KEY = 13;
const ESCAPE_KEY = 27;

export function TextEditor({
    text,
    fullText,
    x,
    y,
    editor_x,
    editor_y,
    editor_height,
    width,
    height,
    fontSize,
    editing_fontsize,
    textFont,
    textColor,
    textBackground,
    borderRadius,
    borderWidth,
    borderColor,
    backgroundOpacity,
    selected,
    onTextClick,
    background_y,
    background_height,
    background_width,
    index,
    onClick,
    onTextResize,
    onTextChange,
    updateWholeText,
    is_Editing,
    openEditMode,
    rec_ref,
    text_padding
}) {
    // const [isEditing, setIsEditing] = useState(is_Editing);
    const [isTransforming, setIsTransforming] = useState(false);

    // useEffect(() => {
    //     if (!selected && isEditing) {
    //         setIsEditing(false);
    //     } else if (!selected && isTransforming) {
    //         setIsTransforming(false);
    //     }
    // }, [selected, isEditing, isTransforming]);

    function toggleEdit() {
        // setIsEditing(!isEditing);
        // onTextClick(!isEditing);
        let toggle = !is_Editing
        if (!toggle) {
            updateWholeText()
        }
    }
    function handleEscapeKeys(e) {
        if ((e.keyCode === RETURN_KEY && !e.shiftKey) || e.keyCode === ESCAPE_KEY) {
            toggleEdit(e);
        }
    }

    function handleTextChange(e) {
        onTextChange(e.currentTarget.value);
    }

    function toggleTransforming() {
        setIsTransforming(!isTransforming);
        onTextClick(!isTransforming);
    }

    return (

        <React.Fragment key={index} >
            {!is_Editing && <Rect ref={rec_ref}
                fill={textBackground}
                width={background_width}
                opacity={0.7}
                height={background_height}
                x={x} y={background_y}
                onDblClick={openEditMode}
            />
            }


            {is_Editing && <EditableTextInput
                x={editor_x}
                y={editor_y}
                width={width}
                background_width={background_width}
                height={editor_height + 10}
                value={fullText}
                fontSize={editing_fontsize}
                textFont={textFont}
                textColor={textColor}
                textBackground={textBackground}
                onChange={handleTextChange}
                onKeyDown={handleEscapeKeys}
            />}

            {!is_Editing && <Text
                // ref={textNodeRef}
                id="editableText"
                text={text}
                x={x}
                y={y}
                fill={textColor}
                fillAfterStrokeEnabled={true}
                fontFamily={textFont}
                fontSize={fontSize}
                // height={lineHeight}
                verticalAlign="middle"
                width={width}
                onDblClick={openEditMode}
                padding={text_padding}
                // width={window.innerWidth / 5} // Set width for multiline text
                // onTransform={handleTextTransform}
                // onDragEnd={handleTextPositionChange}
                listening={true} // Enable listening for events
                // onDblClick={handleTextDoubleClick}
                // onClick={(e) => setSelectedId(e.target.id())}
                wrap="word" // Word wrapping for multiline text
            />}
        </React.Fragment>

    );
}
